export const product = [
  {
    label: '배너',
    value: 'BANNER'
  },
  {
    label: '전면',
    value: 'INTERSTITIAL'
  },
  {
    label: '비디오',
    value: 'VIDEO'
  }
]
