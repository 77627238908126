import React from 'react'

import SectionWithTitle from 'components/common/SectionWithTitle'

const XplayzAddressCountListTemplate = ({ children }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <SectionWithTitle title="xPlayz 트랜잭션 수">
          {children}
        </SectionWithTitle>
      </div>
    </div>
  )
}

export default XplayzAddressCountListTemplate
