import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React, { Component, Fragment } from 'react'

import ConfirmModal from 'components/common/ConfirmModal'
import MessageModal from 'components/common/MessageModal'
import SigninForm from 'components/account/signin/SigninForm'

import * as authActions from 'store/modules/auth'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { ekey } from 'libs/enums'
import { jsonValueByKey, tokenExpired } from 'libs/common'
import { stkeys, storage } from 'libs/storage'

class SigninFormContainer extends Component {
  componentDidMount() {
    this.checkUser()
  }

  checkUser = () => {
    const user = storage.get(stkeys.user)
    const accessToken = storage.get(stkeys.accessToken)

    if (user && accessToken) {
      const accessTokenExpired = tokenExpired(accessToken)

      if (!accessTokenExpired) {
        const { history } = this.props
        history.push('/')
      }
    }
  }

  handleSubmit = async (values) => {
    const { id, pw } = values
    const { AuthActions, MeActions, ModalActions } = this.props

    const params = {
      id,
      pw
    }

    try {
      await AuthActions.signin(params)
      const { error } = this.props
      if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        const { user, accessToken, refreshToken } = this.props
        axios.defaults.headers.common[config.headers.adminID] = user.ID
        axios.defaults.headers.common[config.headers.sessionKey] =
          user.SessionKey
        axios.defaults.headers.common[config.headers.accessToken] = accessToken

        storage.set(stkeys.user, user)
        storage.set(stkeys.accessToken, accessToken)
        MeActions.setUser({
          user,
          accessToken,
          refreshToken
        })

        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '로그인되었습니다.'
        })
      } else if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNUP)) {
        ModalActions.openConfirmModal({
          title: 'ZENAAD',
          description: '아이디가 없습니다.\n회원가입 요청을 하시겠습니까?'
        })
      } else {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }
    } catch (err) {
      const { error } = this.props
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleOK = () => {
    const { error, ModalActions, history } = this.props
    ModalActions.closeMessageModal()
    if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
      history.push('/')
    }
  }

  handleConfirm = () => {
    const { ModalActions, history } = this.props
    ModalActions.closeConfirmModal()
    history.push('/signup')
  }

  handleConfirmCancel = () => {
    const { ModalActions } = this.props
    ModalActions.closeConfirmModal()
  }

  render() {
    const { messageModal, confirmModal, pristine, submitting } = this.props
    const { handleSubmit, handleOK, handleConfirm, handleConfirmCancel } = this
    return (
      <Fragment>
        <SigninForm
          pristine={pristine}
          submitting={submitting}
          onSubmit={handleSubmit}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
        <ConfirmModal
          title={confirmModal.title}
          description={confirmModal.description}
          open={confirmModal.opened}
          onConfirm={handleConfirm}
          onCancel={handleConfirmCancel}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.auth.get('error'),
    user: state.auth.get('user'),
    accessToken: state.auth.get('accessToken'),
    refreshToken: state.auth.get('refreshToken'),
    loading: state.auth.get('loading'),
    messageModal: state.modal.get('message').toJS(),
    confirmModal: state.modal.get('confirm').toJS()
  }),
  (dispatch) => ({
    AuthActions: bindActionCreators(authActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(SigninFormContainer))
