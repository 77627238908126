import { List, Map, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { revenueAPI } from 'api'

// action type
const INIT = 'revenuepub/INIT'
const SET_CUSTID = 'revenuepub/SET_CUSTID'
const SET_APPID = 'revenuepub/SET_APPID'

const GET_SUMMARY = 'revenuepub/GET_SUMMARY'
const GET_SUMMARY_LOADING = 'revenuepub/GET_SUMMARY_LOADING'
const GET_SUMMARY_SUCCESS = 'revenuepub/GET_SUMMARY_SUCCESS'
const GET_SUMMARY_FAILURE = 'revenuepub/GET_SUMMARY_FAILURE'

const GET_APP_SUMMARY = 'revenuepub/GET_APP_SUMMARY'
const GET_APP_SUMMARY_LOADING = 'revenuepub/GET_APP_SUMMARY_LOADING'
const GET_APP_SUMMARY_SUCCESS = 'revenuepub/GET_APP_SUMMARY_SUCCESS'
const GET_APP_SUMMARY_FAILURE = 'revenuepub/GET_APP_SUMMARY_FAILURE'
const REMOVE_APP_SUMMARY = 'revenuepub/REMOVE_APP_SUMMARY'

const GET_MEDIA_SUMMARY = 'revenuepub/GET_MEDIA_SUMMARY'
const GET_MEDIA_SUMMARY_LOADING = 'revenuepub/GET_MEDIA_SUMMARY_LOADING'
const GET_MEDIA_SUMMARY_SUCCESS = 'revenuepub/GET_MEDIA_SUMMARY_SUCCESS'
const GET_MEDIA_SUMMARY_FAILURE = 'revenuepub/GET_MEDIA_SUMMARY_FAILURE'
const REMOVE_MEDIA_SUMMARY = 'revenuepub/REMOVE_MEDIA_SUMMARY'

// action creator
export const init = createAction(INIT)
export const setCustID = createAction(SET_CUSTID)
export const setAppID = createAction(SET_APPID)

export const getSummary = (params) => ({
  type: GET_SUMMARY,
  payload: revenueAPI.revenuePubSummary(params)
})

export const getAppSummary = (params) => ({
  type: GET_APP_SUMMARY,
  payload: revenueAPI.revenuePubAppSummary(params)
})

export const removeAppSummary = createAction(REMOVE_APP_SUMMARY)

export const getMediaSummary = (params) => ({
  type: GET_MEDIA_SUMMARY,
  payload: revenueAPI.revenuePubMediaSummary(params)
})

export const removeMediaSummary = createAction(REMOVE_MEDIA_SUMMARY)

// initial state
const initialState = Map({
  error: '',
  total: Map({
    Apps: 0,
    Impression: 0,
    Click: 0,
    Install: 0
  }),
  chart: List(),
  rowCount: 0,
  summary: List(),
  custID: 0,
  summaryWithApp: List(),
  appID: 0,
  summaryWithMedia: List(),
  // pageNo: 1,
  // pageSize: 10,
  // pager: null,
  loading: false
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [SET_CUSTID]: (state, action) => {
      return state.set('custID', action.payload)
    },
    [SET_APPID]: (state, action) => {
      return state.set('appID', action.payload)
    },
    [GET_SUMMARY_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_SUMMARY_SUCCESS]: (state, action) => {
      const {
        error,
        total,
        chart,
        summary,
        rowCount,
        newToken
      } = action.payload.data
      return state
        .set('error', error)
        .set('total', fromJS(total))
        .set('chart', fromJS(chart))
        .set('summary', fromJS(summary))
        .set('rowCount', rowCount)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_SUMMARY_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [GET_APP_SUMMARY_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_APP_SUMMARY_SUCCESS]: (state, action) => {
      const { error, rows: summaryWithApp, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('summaryWithApp', fromJS(summaryWithApp))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_APP_SUMMARY_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [REMOVE_APP_SUMMARY]: (state) => {
      return state
        .set('custID', 0)
        .set('summaryWithApp', List())
        .set('appID', 0)
        .set('summaryWithMedia', List())
    },
    [GET_MEDIA_SUMMARY_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_MEDIA_SUMMARY_SUCCESS]: (state, action) => {
      const { error, rows: summaryWithMedia, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('summaryWithMedia', fromJS(summaryWithMedia))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_MEDIA_SUMMARY_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [REMOVE_MEDIA_SUMMARY]: (state) => {
      return state.set('appID', 0).set('summaryWithMedia', List())
    }
  },
  initialState
)
