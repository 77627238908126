const maxLength = (max) => (value) => {
  return value && value.length > max
    ? `Must be ${max} characters or less`
    : undefined
}

const minValue = (min) => (value) => {
  return value && value < min ? `Must be at least ${min}` : undefined
}

export const required = (value) => {
  return value ? undefined : 'Required'
}

export const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined

export const maxLength15 = maxLength(15)

export const minValue18 = minValue(18)

export const number = (value) =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined

export const email = (value) => {
  return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? undefined
    : 'Invalid email address'
}

export const password = (value) => {
  return value &&
    !/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/.test(value)
    ? 'Invalid password'
    : undefined
}

export const passwordsMatch = (value, allValues) => {
  return value !== allValues.pw ? "Passwords don't match" : undefined
}

export const ethaddr = (value) => {
  return value && /^(0x)[0-9a-fA-F]{40}$/.test(value)
    ? undefined
    : '유효하지 않은 주소입니다.'
}

export const jsonformat = (value) => {
  try {
    const obj = JSON.parse(value)
    return obj && undefined
  } catch {
    return 'JSON 형식으로 입력해주세요.'
  }
}

export const arraylen = (value) => {
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return 'Required'
    }
    return undefined
  }
  return '배열이 아닙니다.'
}
