import React from 'react'

import MaintenanceFormContainer from 'containers/maintenance/MaintenanceFormContainer'
import MaintenanceTemplate from 'components/maintenance/MaintenanceTemplate'
import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const Url = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <MaintenanceTemplate>
        <MaintenanceFormContainer />
      </MaintenanceTemplate>
    </PageTemplateContainer>
  )
}

export default Url
