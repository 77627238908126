import React from 'react'

import LineChart from 'components/common/LineChart'

const options = {
  chartArea: { width: '90%', height: '80%' },
  curveType: 'none',
  // colors: ['#3F38D6', '#CC0000'],
  legend: { position: 'bottom' },
  pointSize: 8,
  hAxis: {
    textStyle: {
      fontSize: `14`,
      color: '#666666'
    }
  },
  vAxis: {
    minValue: 0,
    viewWindowMode: 'explicit',
    viewWindow: { min: 0 },
    textStyle: {
      fontSize: `14`,
      color: '#666666'
    },
    gridlines: {
      color: '#ebebeb'
    }
  }
}

const Chart = ({ data }) => {
  const arr = data.map((item) => {
    return [item.RegistTime, Number(item.DAU), Number(item.NRU)]
  })

  let chartData = null
  if (arr.length > 0) {
    chartData = [['RegistTime', 'DAU', 'NRU'], ...arr]
  } else {
    chartData = [['RegistTime', 'DAU', 'NRU'], ['', 0, 0]]
  }

  return (
    <div className="row gap-20 pos-r mT-20">
      <div className="col-md-12">
        <LineChart chartData={chartData} options={options} />
      </div>
    </div>
  )
}

export default Chart
