import { defaultClient as axios } from 'libs/client'

const kpiSummary = async (params) => {
  const response = await axios.post('api/v1/kpi/kpi-summary', params)
  return response
}

const kpiAppSummary = async (params) => {
  const response = await axios.post('api/v1/kpi/kpi-app-summary', params)
  return response
}

const kpiDailySummary = async (params) => {
  const response = await axios.post('api/v1/kpi/kpi-daily-summary', params)
  return response
}

export default {
  kpiSummary,
  kpiAppSummary,
  kpiDailySummary
}
