import { Map } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { adminAPI } from 'api'

// action type
const INIT = 'url/INITIALIZE'

const VERSION = 'url/VERSION'
const VERSION_LOADING = 'url/VERSION_LOADING'
const VERSION_SUCCESS = 'url/VERSION_SUCCESS'
const VERSION_FAILURE = 'url/VERSION_FAILURE'

const EMAIL = 'url/EMAIL'
const EMAIL_LOADING = 'url/EMAIL_LOADING'
const EMAIL_SUCCESS = 'url/EMAIL_SUCCESS'
const EMAIL_FAILURE = 'url/EMAIL_FAILURE'

// action creator
export const init = createAction(INIT)

export const version = () => ({
  type: VERSION,
  payload: adminAPI.version()
})

export const email = (params) => ({
  type: EMAIL,
  payload: adminAPI.email(params)
})

// initial state
const initialState = Map({
  error: '',
  versionError: '',
  version: '',
  mail: '',
  token: '',
  newToken: '',
  loading: false
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [VERSION_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [VERSION_SUCCESS]: (state, action) => {
      const { error, version: ver, newToken } = action.payload.data
      return state.set('versionError', error).set('version', ver).set('newToken', newToken).set('loading', false)
    },
    [VERSION_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [EMAIL_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [EMAIL_SUCCESS]: (state, action) => {
      const { error, rows, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('mail', rows.Mail)
        .set('token', rows.Token)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [EMAIL_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    }
  },
  initialState
)
