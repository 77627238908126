import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React, { Component, Fragment } from 'react'

import MessageModal from 'components/common/MessageModal'
import UrlForm from 'components/url/UrlForm'

import * as baseActions from 'store/modules/base'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import * as urlActions from 'store/modules/url'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { ekey } from 'libs/enums'
import { jsonValueByKey } from 'libs/common'
import { stkeys, storage } from 'libs/storage'

class PasswordFormContainer extends Component {
  async componentDidMount() {
    const { UrlActions } = this.props
    UrlActions.init()
  }

  handleSubmit = async (values) => {
    const { email } = values
    const { BaseActions, MeActions, ModalActions, UrlActions } = this.props

    const params = {
      email
    }

    try {
      BaseActions.setLoadingActive(true)
      await UrlActions.version()

      const { versionError } = this.props
      if (versionError !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(versionError)
        })
        BaseActions.setLoadingActive(false)
        return
      }

      await UrlActions.email(params)
      BaseActions.setLoadingActive(false)
      const { error } = this.props
      if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        const { newToken } = this.props
        if (newToken) {
          MeActions.setAccessToken(newToken)
          storage.set(stkeys.accessToken, newToken)
          axios.defaults.headers.common[config.headers.accessToken] = newToken
        }

        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '등록되었습니다.'
        })
      } else {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }
    } catch (err) {
      const { error } = this.props
      BaseActions.setLoadingActive(false)
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleCopy = () => {
    const { ModalActions } = this.props

    ModalActions.openMessageModal({
      title: 'ZENAAD',
      description: '복사되었습니다.'
    })
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  render() {
    const {
      messageModal,
      pristine,
      submitting,
      version,
      mail,
      token,
      error
    } = this.props

    const { handleSubmit, handleOK, handleCopy } = this

    let url = ''
    if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
      if (mail !== '' && token !== '') {
        if (process.env.NODE_ENV === 'development') {
          url = `http://localhost:4010/?type=signup&email=${mail}&token=${token}`
        } else {
          // prettier-ignore
          url = `${process.env.WEB_HOST}/${version}/?type=signup&email=${mail}&token=${token}`
        }
      }
    }

    return (
      <Fragment>
        <UrlForm
          pristine={pristine}
          submitting={submitting}
          onSubmit={handleSubmit}
          url={url}
          handleCopy={handleCopy}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.url.get('error'),
    versionError: state.url.get('versionError'),
    version: state.url.get('version'),
    mail: state.url.get('mail'),
    token: state.url.get('token'),
    newToken: state.url.get('newToken'),
    messageModal: state.modal.get('message').toJS()
  }),
  (dispatch) => ({
    BaseActions: bindActionCreators(baseActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch),
    UrlActions: bindActionCreators(urlActions, dispatch)
  })
)(withRouter(PasswordFormContainer))
