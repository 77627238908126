import React from 'react'

import SectionWithTitle from 'components/common/SectionWithTitle'

const XplaUserTemplate = ({ children }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <SectionWithTitle title="xPlayz 사용자 목록">
          {children}
        </SectionWithTitle>
      </div>
    </div>
  )
}

export default XplaUserTemplate
