import React from 'react'

import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'
import XplayzTxCountListContainer from 'containers/xplayz/XplayzTxCountListContainer'
import XplayzTxCountListTemplate from 'components/xplayztx/XplayzTxCountListTemplate'

const XplayzAddressCountList = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <XplayzTxCountListTemplate>
        <XplayzTxCountListContainer />
      </XplayzTxCountListTemplate>
    </PageTemplateContainer>
  )
}

export default XplayzAddressCountList
