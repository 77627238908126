import React from 'react'

import HomeContainer from 'containers/home/HomeContainer'
import HomeTemplate from 'components/home/HomeTemplate'
import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const Home = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <HomeTemplate>
        <HomeContainer />
      </HomeTemplate>
    </PageTemplateContainer>
  )
}

export default Home
