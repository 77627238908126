import { defaultClient as axios } from 'libs/client'

const index = async (params) => {
  const response = await axios.post('api/v1/admin/', params)
  return response
}

const accept = async (params) => {
  const response = await axios.post('api/v1/admin/accept', params)
  return response
}

const block = async (params) => {
  const response = await axios.post('api/v1/admin/block', params)
  return response
}

const email = async (params) => {
  const response = await axios.post('api/v1/admin/email', params)
  return response
}

const version = async () => {
  const response = await axios.post('api/v1/admin/version')
  return response
}

export default {
  index,
  accept,
  block,
  email,
  version
}
