import { List, Map, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { appAPI } from 'api'

// action type
const INIT = 'app/INIT'

const GET_APP_LIST = 'app/GET_APP_LIST'
const GET_APP_LIST_LOADING = 'app/GET_APP_LIST_LOADING'
const GET_APP_LIST_SUCCESS = 'app/GET_APP_LIST_SUCCESS'
const GET_APP_LIST_FAILURE = 'app/GET_APP_LIST_FAILURE'

const SET_FORM = 'app/SET_FORM'
const SET_FORM_API_KEY = 'app/SET_FORM_API_KEY'
const SET_FORM_ACTIVE = 'app/SET_FORM_ACTIVE'

const UPDATE = 'app/UPDATE'
const UPDATE_LOADING = 'app/UPDATE_LOADING'
const UPDATE_SUCCESS = 'app/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'app/UPDATE_FAILURE'

const CHANGE_ACTIVE = 'app/CHANGE_ACTIVE'

// action creator
export const init = createAction(INIT)

export const getAppList = (params) => ({
  type: GET_APP_LIST,
  payload: appAPI.index(params)
})

export const setForm = createAction(SET_FORM)
export const setFormApiKey = createAction(SET_FORM_API_KEY)
export const setFormActive = createAction(SET_FORM_ACTIVE)

export const update = (params) => ({
  type: UPDATE,
  payload: appAPI.update(params)
})

export const changeActive = createAction(CHANGE_ACTIVE)

// initial state
const initialState = Map({
  error: '',
  rowCount: 0,
  appList: List(),
  form: Map({
    appID: 0,
    email: '',
    company: '',
    platform: '',
    name: '',
    packageName: '',
    apiKey: '',
    apiKeyStatus: -1,
    active: 0,
    registTime: ''
  }),
  opened: false,
  newToken: '',
  loading: false
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [GET_APP_LIST_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_APP_LIST_SUCCESS]: (state, action) => {
      const { error, rowCount, rows: appList, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('rowCount', rowCount)
        .set('appList', fromJS(appList))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_APP_LIST_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [SET_FORM]: (state, action) => {
      const {
        appID,
        email,
        company,
        platform,
        name,
        packageName,
        apiKey,
        apiKeyStatus,
        active,
        registTime,
        opened
      } = action.payload

      const form = {
        appID,
        email,
        company,
        platform,
        name,
        packageName,
        apiKey,
        apiKeyStatus,
        active,
        registTime
      }

      return state.set('form', fromJS(form)).set('opened', opened)
    },
    [SET_FORM_API_KEY]: (state, action) => {
      return state
        .setIn(['form', 'apiKey'], action.payload.apiKey)
        .setIn(['form', 'apiKeyStatus'], action.payload.apiKeyStatus)
    },
    [SET_FORM_ACTIVE]: (state, action) => {
      return state.setIn(['form', 'active'], action.payload)
    },
    [UPDATE_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [UPDATE_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [UPDATE_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [CHANGE_ACTIVE]: (state, action) => {
      const { appID, apiKeyStatus, active } = action.payload

      const index = state
        .get('appList')
        .findIndex((item) => item.get('AppID') === appID)
      return state
        .setIn(['appList', index, 'ApiKeyStatus'], apiKeyStatus)
        .setIn(['appList', index, 'Active'], active)
    }
  },
  initialState
)
