import NumberFormat from 'react-number-format'
import React, { Fragment } from 'react'
import ReactExport from 'react-data-export'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const EthReceiptListItem = ({
  adid,
  toAddress,
  point,
  eth,
  receipt,
  registTime
}) => {
  return (
    <Fragment>
      <tr>
        <td className="ov-h whs-nw tov-e">
          <a data-for="adid" data-tip={adid}>
            {adid}
          </a>
          <ReactTooltip id="adid" place="top" type="info" effect="solid" />
        </td>
        <td className="ov-h whs-nw tov-e">
          <a data-for="addr" data-tip={toAddress}>
            {toAddress}
          </a>
          <ReactTooltip id="addr" place="top" type="info" effect="solid" />
        </td>
        <td className="text-right">
          <NumberFormat
            value={Math.floor(point)}
            displayType="text"
            prefix="&#8361; "
            thousandSeparator={true}
          />
        </td>
        <td className="text-right">{eth}</td>
        <td className="ov-h whs-nw tov-e">
          <a data-for="receipt" data-tip={receipt}>
            {receipt}
          </a>
          <ReactTooltip id="receipt" place="top" type="info" effect="solid" />
        </td>
        {/* <td>{sourceIp}</td> */}
        <td>{moment(registTime).format('YYYY-MM-DD hh:mm:ss')}</td>
      </tr>
    </Fragment>
  )
}

const EthReceiptList = ({ pagination, keyword, handleFilter, list }) => {
  return (
    <Fragment>
      <div className="row justify-content-md-end mB-10">
        <div className="col-md-3">
          <input
            type="search"
            name="keyword"
            className="form-control"
            placeholder="ADID,지갑주소,영수증"
            value={keyword}
            onChange={handleFilter}
          />
        </div>
      </div>

      <div className="table-responsive">
        <table
          className="table table-bordered table-fixed"
          cellSpacing="0"
          width="100%"
        >
          <thead className="thead-light">
            <tr>
              <th className="w-25">ADID</th>
              <th className="w-25">지갑주소</th>
              <th className="w-10">출금(원)</th>
              <th className="w-10">출금(Eth)</th>
              <th className="w-15">영수증</th>
              {/* <th className="w-10">소스아이피</th> */}
              <th className="w-15">출금시간</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((item, index) => {
                const {
                  ADID: adid,
                  ToAddress: toAddress,
                  Point: point,
                  Eth: eth,
                  Receipt: receipt,
                  SourceIp: sourceIp,
                  RegistTime: registTime
                } = item.toJS()
                return (
                  <EthReceiptListItem
                    key={index}
                    adid={adid}
                    toAddress={toAddress}
                    point={point}
                    eth={eth}
                    receipt={receipt}
                    sourceIp={sourceIp}
                    registTime={registTime}
                  />
                )
              })}
          </tbody>
        </table>
      </div>

      <div className="d-f jc-sb">
        <div>
          <ExcelFile
            element={
              <button type="button" className="btn btn-info">
                내보내기
              </button>
            }
          >
            <ExcelSheet data={list.toJS()} name="TEST">
              <ExcelColumn label="ADID" value="ADID" />
              <ExcelColumn label="지갑주소" value="ToAddress" />
              <ExcelColumn label="출금(원)" value="Point" />
              <ExcelColumn label="출금(Eth)" value="Eth" />
              <ExcelColumn label="영수증" value="Receipt" />
              {/* <ExcelColumn label="소스 아이피" value="SourceIp" /> */}
              <ExcelColumn label="출금시간" value="RegistTime" />
            </ExcelSheet>
          </ExcelFile>
        </div>
        {pagination}
      </div>
    </Fragment>
  )
}

export default EthReceiptList
