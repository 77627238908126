import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React, { Component, Fragment } from 'react'

import AdminList from 'components/admin/AdminList'
import MessageModal from 'components/common/MessageModal'

import * as adminActions from 'store/modules/admin'
import * as authActions from 'store/modules/auth'
import * as baseActions from 'store/modules/base'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { delay, jsonValueByKey, loadingDelay } from 'libs/common'
import { ekey } from 'libs/enums'
import { stkeys, storage } from 'libs/storage'

class AdminListContainer extends Component {
  state = {
    keyword: ''
  }

  async componentDidMount() {
    const { AdminActions, ModalActions } = this.props

    AdminActions.init()

    const user = storage.get(stkeys.user)
    if (user && user.Group !== 'ADMINISTRATORS') {
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: '권한이 없습니다'
      })
    } else {
      await this.getList()
    }
  }

  getList = async () => {
    const { AdminActions, BaseActions, MeActions, ModalActions } = this.props

    try {
      BaseActions.setLoadingActive(true)
      await AdminActions.getList()
      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      let e = error
      if (!e) {
        const { status } = err.response
        if (status === 401) {
          e = 'NEED_SIGNIN'
        }
      }

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(e)
      })
    }
  }

  handleFilter = (e) => {
    this.setState({
      keyword: e.target.value
    })
  }

  handleAccept = async (targetID) => {
    const { AdminActions, ModalActions, UserActions } = this.props

    try {
      await AdminActions.accept({
        targetID
      })

      const { error, newToken } = this.props

      if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        AdminActions.setAccept(targetID)
      } else {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
        return
      }

      if (newToken) {
        UserActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleBlock = async (targetID) => {
    const { AdminActions, ModalActions, UserActions } = this.props

    try {
      await AdminActions.block({
        targetID
      })

      const { error, newToken } = this.props

      if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        AdminActions.setBlock(targetID)
      } else {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
        return
      }

      if (newToken) {
        UserActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  render() {
    const { keyword } = this.state
    const { users, messageModal } = this.props
    const { handleFilter, handleAccept, handleBlock, handleOK } = this

    const filteredUsers = users.filter(
      (user) => user.get('FirstName').indexOf(keyword) !== -1
    )

    return (
      <Fragment>
        <AdminList
          users={filteredUsers}
          handleFilter={handleFilter}
          handleAccept={handleAccept}
          handleBlock={handleBlock}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.admin.get('error'),
    users: state.admin.get('users'),
    newToken: state.admin.get('newToken'),
    user: state.me.get('user'),
    messageModal: state.modal.get('message').toJS()
  }),
  (dispatch) => ({
    AdminActions: bindActionCreators(adminActions, dispatch),
    AuthActions: bindActionCreators(authActions, dispatch),
    BaseActions: bindActionCreators(baseActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(AdminListContainer))
