import React, { Component } from 'react'

import ModalWrapper from '../ModalWrapper'

class MessageModal extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.open !== nextProps.open
  }

  componentWillUpdate(nextProps) {
    const { open } = nextProps

    if (open) {
      window.document.activeElement.blur()
      window.addEventListener('keydown', this.listenKeyUp)
    } else {
      window.removeEventListener('keydown', this.listenKeyUp)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.listenKeyUp)
  }

  listenKeyUp = (e) => {
    const { onClick } = this.props
    if (e.key === 'Escape' || e.key === 'Enter') {
      onClick()
    }
  }

  render() {
    const { title, description, onClick, open } = this.props

    return (
      <ModalWrapper open={open}>
        <div className="MessageModal">
          <div className="modal-content">
            {title && <h4>{title}</h4>}
            <p>
              {description.split('\n').map((line, index) => {
                return (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                )
              })}
            </p>
            <div className="button-area">
              <button
                type="button"
                className="btn btn-primary"
                onClick={onClick}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      </ModalWrapper>
    )
  }
}

// const MessageModal = ({ title, description, onClick, open }) => (
//   <ModalWrapper open={open}>
//     <div className="MessageModal">
//       <div className="modal-content">
//         {title && <h4>{title}</h4>}
//         <p>
//           {description.split('\n').map((line, index) => {
//             return (
//               <span key={index}>
//                 {line}
//                 <br />
//               </span>
//             )
//           })}
//         </p>
//         <div className="button-area">
//           <button type="button" className="btn btn-primary" onClick={onClick}>
//             확인
//           </button>
//         </div>
//       </div>
//     </div>
//   </ModalWrapper>
// )

MessageModal.defaultProps = {
  title: null
}

export default MessageModal
