import { Map } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { accountAPI } from 'api'

// action type
const INIT = 'me/INIT'
const SET_USER = 'me/SET_USER'
const GET_USER = 'me/GET_USER'
const SET_ACCESS_TOKEN = 'me/SET_ACCESS_TOKEN'

const UPDATE_EMAIL = 'me/UPDATE_EMAIL'
const UPDATE_EMAIL_LOADING = 'me/UPDATE_EMAIL_LOADING'
const UPDATE_EMAIL_SUCCESS = 'me/UPDATE_EMAIL_SUCCESS'
const UPDATE_EMAIL_FAILURE = 'me/UPDATE_EMAIL_FAILURE'
const SET_EMAIL = 'me/SET_EMAIL'

const UPDATE_PASSWORD = 'me/UPDATE_PASSWORD'
const UPDATE_PASSWORD_LOADING = 'me/UPDATE_PASSWORD_LOADING'
const UPDATE_PASSWORD_SUCCESS = 'me/UPDATE_PASSWORD_SUCCESS'
const UPDATE_PASSWORD_FAILURE = 'me/UPDATE_PASSWORD_FAILURE'

// action creator
export const init = createAction(INIT)
export const setUser = createAction(SET_USER)
export const getUser = createAction(GET_USER)
export const setAccessToken = createAction(SET_ACCESS_TOKEN)

export const updateEmail = (params) => ({
  type: UPDATE_EMAIL,
  payload: accountAPI.updateemail(params)
})

export const setEmail = createAction(SET_EMAIL)

export const updatePassword = (params) => ({
  type: UPDATE_PASSWORD,
  payload: accountAPI.updatepw(params)
})

// initial state
const initialState = Map({
  error: '',
  user: null,
  accessToken: '',
  newToken: '',
  loading: false
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [SET_USER]: (state, action) => {
      const { user, accessToken, refreshToken } = action.payload
      return state
        .set('user', Map(user))
        .set('accessToken', accessToken)
        .set('refreshToken', refreshToken)
    },
    [GET_USER]: (state) => {
      return state.get('user')
    },
    [SET_ACCESS_TOKEN]: (state, action) => {
      return state.set('accessToken', action.payload).set('newToken', '')
    },
    [UPDATE_EMAIL_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [UPDATE_EMAIL_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [UPDATE_EMAIL_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [SET_EMAIL]: (state, action) => {
      return state.setIn(['user', 'Email'], action.payload)
    },
    [UPDATE_PASSWORD_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [UPDATE_PASSWORD_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [UPDATE_PASSWORD_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    }
  },
  initialState
)
