import React, { Component, createRef } from 'react'

import QuillTest from 'components/quill/QuillTest'

import { adtier0 } from 'enums/adtier0'
import { defaultClient } from 'libs/client'
import { ekey } from 'libs/enums'
import { noticeAPI } from 'api'

class QuillTestContainer extends Component {
  state = {
    contents: ''
  }

  quillRef = createRef()

  dropzoneRef = createRef()

  handleContents = (contents) => {
    this.setState({ contents })
  }

  handleImage = () => {
    if (this.dropzoneRef && this.dropzoneRef.current) {
      this.dropzoneRef.current.open()
    }
  }

  handleDrop = async (acceptedFiles) => {
    await acceptedFiles.reduce((pacc, file) => {
      return pacc.then(async () => {
        const params = new FormData()
        params.append('uploadFile', file)

        const { data } = await noticeAPI.getUploadQuillImageUrl(params)

        const { error } = data
        if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
          const { uploadUrl, imageUrl } = data

          const { status } = await defaultClient.put(uploadUrl, file, {
            headers: {
              'Content-Type': file.type
            }
          })

          if (status === 200) {
            if (this.quillRef && this.quillRef.current) {
              const quill = this.quillRef.current.getEditor()
              const range = quill.getSelection()
              quill.insertEmbed(range.index, 'image', imageUrl)
              quill.setSelection(range.index + 1)
              quill.focus()
            }
          }
        }
      })
    }, Promise.resolve())
  }

  handleSubmit = async () => {
    const { contents } = this.state
    const params = {
      contents
    }

    await noticeAPI.addQuill(params)
    // const { data } = await noticeAPI.addQuill(params)
    // console.log(data)
  }

  modules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' }
        ],
        ['link', 'image', 'video'],
        ['clean']
      ],
      handlers: { image: this.handleImage }
    }
  }

  formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'color',
    'background',
    'align',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video'
  ]

  render() {
    const { contents } = this.state
    const quillRef = this.quillRef
    const dropzoneRef = this.dropzoneRef
    const modules = this.modules
    const formats = this.formats
    const { handleContents, handleDrop, handleSubmit } = this

    return (
      <QuillTest
        quillRef={quillRef}
        modules={modules}
        formats={formats}
        contents={contents}
        handleContents={handleContents}
        dropzoneRef={dropzoneRef}
        handleDrop={handleDrop}
        handleSubmit={handleSubmit}
      />
    )
  }
}

export default QuillTestContainer
