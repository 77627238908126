import React from 'react'

import FAQFormContainer from 'containers/faq/FAQFormContainer'
import FAQTemplate from 'components/faq/FAQTemplate'
import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const Notice = () => {
  return (
    <PageTemplateContainer sidebar={<SidebarContainer />} topbar={<TopbarContainer />}>
      <FAQTemplate>
        <FAQFormContainer />
      </FAQTemplate>
    </PageTemplateContainer>
  )
}

export default Notice
