import React, { Fragment } from 'react'
import Select from 'react-select'
import cx from 'classnames'

const MultipleSelect = ({
  id,
  name,
  placeholder,
  options,
  helpertext,
  input,
  selectedValue,
  meta: { touched, error }
}) => {
  return (
    <Fragment>
      <Select
        id={id}
        name={name}
        placeholder={placeholder}
        options={options}
        value={selectedValue}
        onChange={(value) => input.onChange(value)}
        styles={{
          menuPortal: (base) => {
            const { zIndex, ...rest } = base // remove zIndex from base by destructuring
            return { ...rest, zIndex: 9999 }
          }
        }}
        menuPortalTarget={document.body}
        isMulti
      />
      {helpertext && (
        <small className="form-text text-muted mT-5 c-blue-900">
          {helpertext}
        </small>
      )}
      {touched &&
        (error &&
          selectedValue.length === 0 && (
            <div className={cx('mT-5', { 'c-red-500': touched && error })}>
              {error}
            </div>
          ))}
    </Fragment>
  )
}

export default MultipleSelect

// export default class SearchableSelect extends Component {
//   render() {
//     const { onChange, value, async, ...rest } = this.props;

//     if (async) {
//       return (
//         <Select.Async
//           simpleValue
//           onChange={val => onChange(input, val)}
//           value={value}
//           {...rest}
//         />
//       );
//     }

//     return (
//       <Select
//         simpleValue
//         onChange={val => onChange(input, val)}
//         value={value}
//         {...rest}
//       />
//     );
//   }
// }
