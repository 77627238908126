import { defaultClient as axios } from 'libs/client'

const index = async (params) => {
  const response = await axios.post('api/v1/customer/', params)
  return response
}

const add = async (params) => {
  const response = await axios.post('api/v1/customer/add', params)
  return response
}

const chargeCash = async (params) => {
  const response = await axios.post('api/v1/customer/charge-cash', params)
  return response
}

export default {
  index,
  add,
  chargeCash
}
