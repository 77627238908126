import React from 'react'
import cx from 'classnames'

const Topbar = ({
  adminID,
  showUserMenu,
  toggleFullScreen,
  toggleUserMenu,
  handleProfile,
  handleSignout
}) => {
  return (
    <div className="header navbar">
      <div className="header-container">
        <ul className="nav-left">
          <li>
            <a
              id="sidebar-toggle"
              className="sidebar-toggle"
              onClick={toggleFullScreen}
            >
              <i className="ti-menu" />
            </a>
          </li>
          {/* <li className="search-box">
            <a className="search-toggle no-pdd-right" href="javascript:void(0);">
              <i className="search-icon ti-search pdd-right-10" />
              <i className="search-icon-close ti-close pdd-right-10" />
            </a>
          </li>
          <li className="search-input">
            <input className="form-control" type="text" placeholder="Search..." />
          </li> */}
        </ul>
        <ul className="nav-right">
          <li className={cx('dropdown', { show: showUserMenu })}>
            <a
              href="javascript:void(0);"
              className="dropdown-toggle no-after peers fxw-nw ai-c lh-1"
              data-toggle="dropdown"
              onClick={toggleUserMenu}
            >
              <div className="peer mR-10">
                <span className="icon-holder">
                  <i className="c-blue-500 ti-user" />
                </span>
              </div>
              <div className="peer">
                <span className="fsz-sm c-grey-900">{adminID}</span>
              </div>
            </a>
            <ul
              className={cx('dropdown-menu', 'fsz-sm', { show: showUserMenu })}
            >
              <li>
                <a
                  className="d-b td-n pY-5 bgcH-grey-100 c-grey-700"
                  onClick={handleProfile}
                >
                  <i className="ti-user mR-10" />
                  <span>Me</span>
                </a>
              </li>
              <li role="separator" className="divider" />
              <li>
                <a
                  className="d-b td-n pY-5 bgcH-grey-100 c-grey-700"
                  onClick={handleSignout}
                >
                  <i className="ti-power-off mR-10" />
                  <span>Signout</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Topbar
