import React from 'react'

import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import SummaryContainer from 'containers/summary/SummaryContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const Summary = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <SummaryContainer />
    </PageTemplateContainer>
  )
}

export default Summary
