import { defaultClient as axios } from 'libs/client'

const index = async (params) => {
  const response = await axios.post('api/v1/promotion/', params)
  return response
}

const getBidCost = async () => {
  const response = await axios.post('api/v1/promotion/get-bid-cost')
  return response
}

const updatePromotion = async (params) => {
  const response = await axios.post('api/v1/promotion/update-promotion', params)
  return response
}

export default {
  index,
  getBidCost,
  updatePromotion
}
