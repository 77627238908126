import NumberFormat from 'react-number-format'
import React from 'react'

import HorizontalPlainText from 'components/common/HorizontalPlainText'

const CustomerView = ({
  view,
  opened,
  updateCash,
  handleCashChange,
  handleModify
}) => {
  const { custID, type, email, company, cash } = view.toJS()
  const openClass = opened ? 'd-b' : 'd-n'

  return (
    <div className={openClass}>
      <HorizontalPlainText label="이메일: " value={email} />
      <HorizontalPlainText label="회사: " value={company} />
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">캐쉬(보유): </label>
        <div className="col-sm-10 form-control-plaintext">
          <NumberFormat
            value={Math.floor(cash)}
            displayType="text"
            thousandSeparator={true}
          />
        </div>
      </div>
      {type === 'ADVERTISER' && (
        <div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">캐쉬(충전할): </label>
            <div className="col-sm-10 form-control-plaintext">
              <input
                type="text"
                name="cash"
                className="form-control"
                value={updateCash}
                onChange={handleCashChange}
              />
            </div>
          </div>
          <div className="text-right mT-20">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleModify(custID)}
            >
              확인
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomerView
