import React, { Fragment } from 'react'

import SectionWithTitle from 'components/common/SectionWithTitle'

const ApiKeyRequestTemplate = ({ children }) => {
  return (
    <Fragment>
      <div className="row mB-20">
        <div className="col-md-12">
          <SectionWithTitle title="API KEY 요청 목록">
            {children}
          </SectionWithTitle>
        </div>
      </div>
    </Fragment>
  )
}

export default ApiKeyRequestTemplate
