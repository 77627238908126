import React from 'react'

import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'
import XplayzAddressCountListContainer from 'containers/xplayz/XplayzAddressCountListContainer'
import XplayzAddressCountListTemplate from 'components/xplayzaddress/XplayzAddressCountListTemplate'

const XplayzAddressCountList = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <XplayzAddressCountListTemplate>
        <XplayzAddressCountListContainer />
      </XplayzAddressCountListTemplate>
    </PageTemplateContainer>
  )
}

export default XplayzAddressCountList
