import React, { Component } from 'react'
import cpp from 'highlight.js/lib/languages/cpp'
import cs from 'highlight.js/lib/languages/cs'
import hljs from 'highlight.js/lib/highlight'
import java from 'highlight.js/lib/languages/java'
import javascript from 'highlight.js/lib/languages/javascript'
import objectivec from 'highlight.js/lib/languages/objectivec'
import xml from 'highlight.js/lib/languages/xml'

import TinyMCETest from 'components/tinymce/TinyMCETest'

import { adtier0 } from 'enums/adtier0'
import { defaultClient } from 'libs/client'
import { ekey } from 'libs/enums'
import { noticeAPI } from 'api'

import 'highlight.js/styles/monokai-sublime.css'

hljs.registerLanguage('xml', xml)
hljs.registerLanguage('javascript', javascript)
hljs.registerLanguage('objectivec', objectivec)
hljs.registerLanguage('java', java)
hljs.registerLanguage('cs', cs)
hljs.registerLanguage('c', cpp)

class TinyMCETestContainer extends Component {
  state = {
    contents: ''
  }

  nodeRef = React.createRef()

  componentDidMount() {
    this.highlight()
  }

  componentDidUpdate() {
    this.highlight()
  }

  handleContents = (contents) => {
    this.setState({ contents })
  }

  handleUploadImage = async (blobInfo, success, failure) => {
    const file = blobInfo.blob()

    const params = new FormData()
    params.append('uploadFile', file)

    const { data } = await noticeAPI.getUploadQuillImageUrl(params)

    const { error } = data
    if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
      const { uploadUrl, imageUrl } = data

      const { status } = await defaultClient.put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type
        }
      })

      if (status === 200) {
        success(imageUrl)
      }
    } else {
      failure()
    }
  }

  handleSubmit = async () => {
    const { contents } = this.state
    const params = {
      contents
    }

    // const { data } = await noticeAPI.addQuill(params)
    await noticeAPI.addQuill(params)
  }

  highlight = () => {
    if (this.nodeRef) {
      const nodes = this.nodeRef.current.querySelectorAll('pre')
      if (nodes) {
        nodes.forEach((node) => {
          hljs.highlightBlock(node)
        })
      }

      const codes = this.nodeRef.current.querySelectorAll('pre > code')
      if (codes) {
        codes.forEach((codeBlock) => {
          const button = document.createElement('button')
          button.className = 'copy-code-button'
          button.type = 'button'
          button.innerText = 'Copy'

          const pre = codeBlock.parentNode
          if (pre.parentNode.classList.contains('highlight')) {
            const highlight = pre.parentNode
            highlight.parentNode.insertBefore(button, highlight)
          } else {
            pre.parentNode.insertBefore(button, pre)
          }
        })
      }
    }
  }

  render() {
    const nodeRef = this.nodeRef
    const { contents } = this.state
    const { handleContents, handleUploadImage, handleSubmit } = this

    return (
      <TinyMCETest
        nodeRef={nodeRef}
        contents={contents}
        handleContents={handleContents}
        handleUploadImage={handleUploadImage}
        handleSubmit={handleSubmit}
      />
    )
  }
}

export default TinyMCETestContainer
