import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Field, reduxForm } from 'redux-form'
import React, { Component } from 'react'

import HorizontalInput from 'components/common/HorizontalInput'

import * as validate from 'libs/validate'

class UrlForm extends Component {
  render() {
    const { pristine, submitting, handleSubmit, url, handleCopy } = this.props

    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="email"
          label="이메일"
          type="text"
          component={HorizontalInput}
          validate={[validate.required, validate.email]}
        />
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">가입 URL</label>
          <div className="col-sm-10">
            {url}{' '}
            {url && (
              <CopyToClipboard text={url}>
                <button type="button" onClick={handleCopy} className="cur-p">
                  Copy
                </button>
              </CopyToClipboard>
            )}
          </div>
        </div>
        <div className="text-right mT-20">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={pristine || submitting}
          >
            저장
          </button>
        </div>
      </form>
    )
  }
}

export default reduxForm({
  form: 'urlForm',
  enableReinitialize: true
})(UrlForm)
