import React from 'react'

import EthBlackListContainer from 'containers/eth/EthBlackListContainer'
import EthBlackTemplate from 'components/ethblack/EthBlackTemplate'
import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const EthBlack = () => {
  return (
    <PageTemplateContainer sidebar={<SidebarContainer />} topbar={<TopbarContainer />}>
      <EthBlackTemplate>
        <EthBlackListContainer />
      </EthBlackTemplate>
    </PageTemplateContainer>
  )
}

export default EthBlack
