import React from 'react'

import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'
import UrlFormContainer from 'containers/url/UrlFormContainer'
import UrlTemplate from 'components/url/UrlTemplate'

const Url = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <UrlTemplate>
        <UrlFormContainer />
      </UrlTemplate>
    </PageTemplateContainer>
  )
}

export default Url
