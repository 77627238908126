import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React, { Component, Fragment } from 'react'

import SignoutModal from 'components/layout/SignoutModal'
import Topbar from 'components/layout/Topbar'

import * as authActions from 'store/modules/auth'
import * as baseActions from 'store/modules/base'
import * as meActions from 'store/modules/me'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { storage } from 'libs/storage'

class TopbarContainer extends Component {
  toggleFullScreen = () => {
    const { fullscreen, BaseActions } = this.props
    BaseActions.toggleFullScreen(fullscreen)
  }

  toggleUserMenu = () => {
    const { showUserMenu, BaseActions } = this.props
    BaseActions.toggleUserMenu(showUserMenu)
  }

  handleProfile = () => {
    const { showUserMenu, BaseActions, history } = this.props
    BaseActions.toggleUserMenu(showUserMenu)
    history.push('/me')
  }

  handleSignout = () => {
    const { BaseActions } = this.props

    BaseActions.openSignoutModal({
      title: 'ZENAAD',
      description: '로그아웃 하시겠습니까?'
    })
  }

  handleSignoutConfirm = () => {
    const { showUserMenu, BaseActions, MeActions, history } = this.props
    BaseActions.closeSignoutModal()
    BaseActions.toggleUserMenu(showUserMenu)
    storage.clear()
    MeActions.init()
    axios.defaults.headers.common[config.headers.adminID] = ''
    axios.defaults.headers.common[config.headers.sessionKey] = ''
    axios.defaults.headers.common[config.headers.accessToken] = ''
    history.push('/signin')
  }

  handleSignoutConfirmCancel = () => {
    const { showUserMenu, BaseActions } = this.props
    BaseActions.closeSignoutModal()
    BaseActions.toggleUserMenu(showUserMenu)
  }

  render() {
    const { adminID, showUserMenu, signoutConfirm } = this.props
    const {
      toggleFullScreen,
      toggleUserMenu,
      handleProfile,
      handleSignout,
      handleSignoutConfirm,
      handleSignoutConfirmCancel
    } = this

    return (
      <Fragment>
        <Topbar
          adminID={adminID}
          showUserMenu={showUserMenu}
          toggleFullScreen={toggleFullScreen}
          toggleUserMenu={toggleUserMenu}
          handleProfile={handleProfile}
          handleSignout={handleSignout}
        />
        <SignoutModal
          title={signoutConfirm.title}
          description={signoutConfirm.description}
          open={signoutConfirm.opened}
          onConfirm={handleSignoutConfirm}
          onCancel={handleSignoutConfirmCancel}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    fullscreen: state.base.get('fullscreen'),
    showUserMenu: state.base.get('showUserMenu'),
    signoutConfirm: state.base.get('signoutConfirm').toJS(),
    adminID: state.me.getIn(['user', 'ID'])
  }),
  (dispatch) => ({
    AuthActions: bindActionCreators(authActions, dispatch),
    BaseActions: bindActionCreators(baseActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch)
  })
)(withRouter(TopbarContainer))
