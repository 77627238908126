import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React, { Component, Fragment } from 'react'

import MessageModal from 'components/common/MessageModal'
import MetaDataEthForm from 'components/metadata/MetaDataEthForm'

import * as meActions from 'store/modules/me'
import * as metaDataActions from 'store/modules/metadata'
import * as modalActions from 'store/modules/modal'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { ekey } from 'libs/enums'
import { jsonValueByKey } from 'libs/common'
import { stkeys, storage } from 'libs/storage'

class MetaDataEthFormContainer extends Component {
  handleSubmit = async (values) => {
    const { user, ModalActions } = this.props
    if (user.get('Group') !== 'ADMINISTRATORS') {
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: '권한이 없습니다.'
      })
      return
    }

    const {
      Address: address,
      PrivKey: privKey,
      WithdrawablePoint: withdrawablePoint
    } = values
    const { MeActions, MetaDataActions } = this.props

    const params = {
      address,
      privKey,
      withdrawablePoint
    }

    try {
      await MetaDataActions.updateEth(params)
      const { error } = this.props
      if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        const { newToken } = this.props

        if (newToken) {
          MeActions.setAccessToken(newToken)
          storage.set(stkeys.accessToken, newToken)
          axios.defaults.headers.common[config.headers.accessToken] = newToken
        }

        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '수정되었습니다.'
        })
      } else {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }
    } catch (err) {
      const { error } = this.props
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  render() {
    const { messageModal, pristine, submitting } = this.props
    const { handleSubmit, handleOK } = this

    return (
      <Fragment>
        <MetaDataEthForm
          pristine={pristine}
          submitting={submitting}
          onSubmit={handleSubmit}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.metadata.get('error'),
    newToken: state.metadata.get('newToken'),
    messageModal: state.modal.get('message').toJS(),
    user: state.me.get('user')
  }),
  (dispatch) => ({
    MeActions: bindActionCreators(meActions, dispatch),
    MetaDataActions: bindActionCreators(metaDataActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(MetaDataEthFormContainer))
