import { List, Map, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { ethAPI } from 'api'

// action type
const INIT = 'ethblack/INIT'

const SET_ADID = 'ethblack/SET_ADID'
const SET_DELETE_BLACK = 'ethblack/SET_DELETE_BLACK'
const SET_NEW_ADID = 'ethblack/SET_NEW_ADID'

const GET_BLACK_LIST = 'ethblack/GET_BLACK_LIST'
const GET_BLACK_LIST_LOADING = 'ethblack/GET_BLACK_LIST_LOADING'
const GET_BLACK_LIST_SUCCESS = 'ethblack/GET_BLACK_LIST_SUCCESS'
const GET_BLACK_LIST_FAILURE = 'ethblack/GET_BLACK_LIST_FAILURE'

const DELETE_BLACK = 'ethblack/DELETE_BLACK'
const DELETE_BLACK_LOADING = 'ethblack/DELETE_BLACK_LOADING'
const DELETE_BLACK_SUCCESS = 'ethblack/DELETE_BLACK_SUCCESS'
const DELETE_BLACK_FAILURE = 'ethblack/DELETE_BLACK_FAILURE'

const ADD_BLACK = 'ethblack/ADD_BLACK'
const ADD_BLACK_LOADING = 'ethblack/ADD_BLACK_LOADING'
const ADD_BLACK_SUCCESS = 'ethblack/ADD_BLACK_SUCCESS'
const ADD_BLACK_FAILURE = 'ethblack/ADD_BLACK_FAILURE'

// action creator
export const init = createAction(INIT)

export const setADID = createAction(SET_ADID)
export const setDeleteBlack = createAction(SET_DELETE_BLACK)
export const setNewADID = createAction(SET_NEW_ADID)

export const getBlackList = (params) => ({
  type: GET_BLACK_LIST,
  payload: ethAPI.ethBlackList(params)
})

export const deleteBlack = (params) => ({
  type: DELETE_BLACK,
  payload: ethAPI.deleteEthBlack(params)
})

export const addBlack = (params) => ({
  type: ADD_BLACK,
  payload: ethAPI.addEthBlack(params)
})

// initial state
const initialState = Map({
  error: '',
  keyword: '',
  rowCount: 0,
  blackList: List(),
  adid: '',
  newadid: '',
  newToken: '',
  loading: false
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [SET_ADID]: (state, { payload: adid }) => {
      return state.set('adid', adid)
    },
    [SET_DELETE_BLACK]: (state, { payload: adid }) => {
      const index = state
        .get('blackList')
        .findIndex((item) => item.get('ADID') === adid)

      const blackList = state.get('blackList').delete(index)

      return state.set('blackList', blackList).set('adid', '')
    },
    [SET_NEW_ADID]: (state, { payload: newadid }) => {
      return state.set('newadid', newadid)
    },
    [GET_BLACK_LIST_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_BLACK_LIST_SUCCESS]: (state, action) => {
      const { error, rowCount, rows: blackList, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('rowCount', rowCount)
        .set('blackList', fromJS(blackList))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_BLACK_LIST_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [DELETE_BLACK_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [DELETE_BLACK_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data

      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [DELETE_BLACK_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [ADD_BLACK_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [ADD_BLACK_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data

      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [ADD_BLACK_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    }
  },
  initialState
)
