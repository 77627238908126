import React from 'react'
import SigninFormContainer from 'containers/account/SigninFormContainer'
import SigninTemplate from 'components/account/signin/SigninTemplate'

const Signin = () => {
  return (
    <SigninTemplate>
      <SigninFormContainer />
    </SigninTemplate>
  )
}

export default Signin
