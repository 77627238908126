import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React, { Component, Fragment } from 'react'

import MessageModal from 'components/common/MessageModal'
import Sidebar from 'components/layout/Sidebar'

import * as baseActions from 'store/modules/base'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'

class SidebarContainer extends Component {
  toggleFullScreen = () => {
    const { fullscreen, BaseActions } = this.props
    BaseActions.toggleFullScreen(fullscreen)
  }

  handleConfirmCancel = () => {
    const { showUserMenu, BaseActions } = this.props
    BaseActions.toggleUserMenu(showUserMenu)
  }

  handlePermission = () => {
    const { ModalActions } = this.props
    ModalActions.openMessageModal({
      title: 'ZENAAD',
      description: '권한이 없습니다.'
    })
  }

  handleOK = () => {
    const { ModalActions } = this.props

    ModalActions.closeMessageModal()
  }

  render() {
    const { user, messageModal } = this.props
    const { toggleFullScreen, handlePermission, handleOK } = this

    return (
      <Fragment>
        <Sidebar
          toggleFullScreen={toggleFullScreen}
          handlePermission={handlePermission}
          user={user}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    fullscreen: state.base.get('fullscreen'),
    user: state.me.get('user'),
    messageModal: state.modal.get('message').toJS()
  }),
  (dispatch) => ({
    BaseActions: bindActionCreators(baseActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(SidebarContainer))
