import React, { Fragment } from 'react'

const MaintenanceForm = ({ type, handleFileChange, handleUpload }) => {
  const href = `${process.env.WEB_HOST}/check.json`

  return (
    <Fragment>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">파일</label>
        <div className="col-sm-10">
          <input
            type={type}
            className="form-control-file"
            onChange={handleFileChange}
          />
          <small className="form-text text-muted mT-5 c-blue-900">
            check.json 으로 등록해주세요.
          </small>
        </div>
      </div>
      <div className="d-f jc-fe mT-20">
        <div className="peers gap-10">
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleUpload}
            >
              등록
            </button>
          </div>
          <div>
            <a className="btn btn-info" href={href}>
              다운로드
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default MaintenanceForm
