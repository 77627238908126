import React from 'react'

import AdminListContainer from 'containers/admin/AdminListContainer'
import AdminTemplate from 'components/admin/AdminTemplate'
import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const Admin = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <AdminTemplate>
        <AdminListContainer />
      </AdminTemplate>
    </PageTemplateContainer>
  )
}

export default Admin
