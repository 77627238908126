import React from 'react'
import cx from 'classnames'

const HorizontalTextArea = ({
  input,
  id,
  label,
  rows,
  meta: { touched, error }
}) => {
  return (
    <div className="form-group row">
      <label className="col-sm-2 col-form-label" htmlFor={id}>
        {label}
      </label>
      <div className="col-sm-10">
        <textarea
          {...input}
          className="form-control"
          placeholder={label}
          rows={rows}
        />
        {touched &&
          (error && (
            <div className={cx('mT-5', { 'c-red-500': touched && error })}>
              {error}
            </div>
          ))}
      </div>
    </div>
  )
}

export default HorizontalTextArea
