import React from 'react'

import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import RefundPubTabContainer from 'containers/refund/RefundPubTabContainer'
import RefundPubTemplate from 'components/refund/RefundPubTemplate'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const RefundPub = () => {
  return (
    <PageTemplateContainer sidebar={<SidebarContainer />} topbar={<TopbarContainer />}>
      <RefundPubTemplate>
        <RefundPubTabContainer />
      </RefundPubTemplate>
    </PageTemplateContainer>
  )
}

export default RefundPub
