import React from 'react'
import cx from 'classnames'

const PageTemplate = ({ fullscreen, sidebar, topbar, children }) => {
  return (
    <div className={cx('app', { 'is-collapsed': fullscreen })}>
      {sidebar}
      <div className="page-container">
        {topbar}
        <main className="main-content bgc-grey-100">
          <div id="mainContent">{children}</div>
        </main>
        <footer className="bdT ta-c p-30 lh-0 fsz-sm c-grey-600">
          {/* <span>
            Copyright © 2018 Made by{' '}
            <a href="http://www.rhaon.co.kr/" title="">
              Rhaon Entertainment
            </a>
            . All rights reserved.
          </span> */}
          <span>ZENAAD</span>
        </footer>
      </div>
    </div>
  )
}

export default PageTemplate
