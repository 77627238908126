import React, { Fragment } from 'react'

import SectionWithTitle from 'components/common/SectionWithTitle'

const SummaryTemplate = ({
  search,
  summaryTotal,
  summaryAdvertiserChart,
  summaryAdvertiser,
  summaryPublisherChart,
  summaryPublisher,
  summaryUserChart,
  summaryUser
}) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          <SectionWithTitle title="비용/수익">
            <div className="row">
              <div className="col-md-12">
                {/* 광고주 비용, 퍼블리셔/유저/플랫폼 수익을 보실 수 있습니다. */}
                <dl className="dl">
                  <dt>퍼블리셔 수익</dt>
                  <dd>
                    퍼블리셔 수익율이 다를 수 있습니다. (각 퍼블리셔 수익율
                    설정에 따라 0~90%)
                    <br />
                    특정 기간의 퍼블리셔 50% 수익율이 맞지 않는것 처럼 보일 수
                    있습니다. (배치 처리 정산 때문에, 누적은 정상)
                  </dd>
                  <dt>우저 수익</dt>
                  <dd>
                    유저 수익은 광고 비용의 10% 수익을 말합니다. (CPM 제외)
                  </dd>
                  <dt>플랫폼 수익</dt>
                  <dd>
                    플랫폼 수익 = ((광고주 비용 - 퍼블리셔 수익) - 유저 수익)
                  </dd>
                </dl>
              </div>
            </div>
            <div className="row mB-20">
              <div className="col-md-12">{search}</div>
            </div>

            {summaryTotal}

            <div className="row mB-20">
              <div className="col-md-12">
                <SectionWithTitle title="광고주 비용">
                  {summaryAdvertiserChart}
                </SectionWithTitle>
              </div>
            </div>
            <div className="row mB-20">
              <div className="col-md-12">
                <SectionWithTitle title="광고주 비용">
                  {summaryAdvertiser}
                </SectionWithTitle>
              </div>
            </div>

            <div className="row mB-20">
              <div className="col-md-12">
                <SectionWithTitle title="퍼블리셔 수익">
                  {summaryPublisherChart}
                </SectionWithTitle>
              </div>
            </div>
            <div className="row mB-20">
              <div className="col-md-12">
                <SectionWithTitle title="퍼블리셔 수익">
                  {summaryPublisher}
                </SectionWithTitle>
              </div>
            </div>

            <div className="row mB-20">
              <div className="col-md-12">
                <SectionWithTitle title="유저 수익">
                  {summaryUserChart}
                </SectionWithTitle>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <SectionWithTitle title="유저 수익">
                  {summaryUser}
                </SectionWithTitle>
              </div>
            </div>
          </SectionWithTitle>
        </div>
      </div>
    </Fragment>
  )
}

export default SummaryTemplate
