import { Map } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

// action type
const SET_MESSAGE = 'modal/SET_MESSAGE'
const OPEN_MESSAGE_MODAL = 'modal/OPEN_MESSAGE_MODAL'
const CLOSE_MESSAGE_MODAL = 'modal/CLOSE_MESSAGE_MODAL'
const SET_CONFIRM = 'modal/SET_CONFIRM'
const OPEN_CONFIRM_MODAL = 'modal/OPEN_CONFIRM_MODAL'
const CLOSE_CONFIRM_MODAL = 'modal/CLOSE_CONFIRM_MODAL'

// action creator
export const setMessage = createAction(SET_MESSAGE)
export const openMessageModal = createAction(OPEN_MESSAGE_MODAL)
export const closeMessageModal = createAction(CLOSE_MESSAGE_MODAL)
export const setConfirm = createAction(SET_CONFIRM)
export const openConfirmModal = createAction(OPEN_CONFIRM_MODAL)
export const closeConfirmModal = createAction(CLOSE_CONFIRM_MODAL)

// initial state
const initialState = Map({
  message: Map({
    title: '',
    description: '',
    opened: false
  }),
  confirm: Map({
    title: '',
    description: '',
    opend: false
  })
})

// reducer
export default handleActions(
  {
    [SET_MESSAGE]: (state, action) => {
      return state
        .setIn(['message', 'title'], action.payload.title)
        .setIn(['message', 'description'], action.payload.description)
    },
    [OPEN_MESSAGE_MODAL]: (state, action) => {
      const { title, description } = action.payload
      return state
        .setIn(['message', 'title'], title)
        .setIn(['message', 'description'], description)
        .setIn(['message', 'opened'], true)
    },
    [CLOSE_MESSAGE_MODAL]: (state) => {
      return state.setIn(['message', 'opened'], false)
    },
    [SET_CONFIRM]: (state, action) => {
      return state
        .setIn(['confirm', 'title'], action.payload.title)
        .setIn(['confirm', 'description'], action.payload.description)
    },
    [OPEN_CONFIRM_MODAL]: (state, action) => {
      const { title, description } = action.payload
      return state
        .setIn(['confirm', 'title'], title)
        .setIn(['confirm', 'description'], description)
        .setIn(['confirm', 'opened'], true)
    },
    [CLOSE_CONFIRM_MODAL]: (state) => {
      return state.setIn(['confirm', 'opened'], false)
    }
  },
  initialState
)
