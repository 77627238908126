import 'react-datepicker/dist/react-datepicker.css'
import React, { Fragment } from 'react'

import RevenuePubListItem from 'components/revenue/RevenuePubListItem'

const RevenuePubList = ({
  pagination,
  summary,
  custID,
  summaryWithApp,
  appID,
  summaryWithMedia,
  getAppSummary,
  getMediaSummary
}) => {
  return (
    <Fragment>
      <div className="table-responsive mT-20">
        <table className="table table-bordered table-fixed">
          <thead className="thead-light">
            <tr>
              <th style={{ width: '30%' }}>이메일</th>
              <th>앱</th>
              <th>광고</th>
              <th>수익</th>
              <th>노출</th>
              <th>클릭</th>
              <th>CTR</th>
              <th>설치</th>
              {/* <th>액션</th> */}
            </tr>
          </thead>
          <tbody>
            {summary &&
              summary.map((item) => {
                const {
                  CustID,
                  LastName,
                  FirstName,
                  Email,
                  inventory,
                  advertiser,
                  revenue,
                  impression,
                  click,
                  CTR,
                  Install
                  // Action
                } = item.toJS()
                return (
                  <RevenuePubListItem
                    key={CustID}
                    custID={CustID}
                    lastName={LastName}
                    firstName={FirstName}
                    email={Email}
                    inventory={inventory}
                    advertiser={advertiser}
                    revenue={revenue}
                    impression={impression}
                    click={click}
                    ctr={CTR}
                    install={Install}
                    // action={Action}
                    pubID={custID}
                    summaryWithApp={summaryWithApp}
                    pubAppID={appID}
                    summaryWithMedia={summaryWithMedia}
                    getAppSummary={getAppSummary}
                    getMediaSummary={getMediaSummary}
                  />
                )
              })}
          </tbody>
        </table>
      </div>
      <div className="d-f jc-sb">
        <div>
          <button type="button" className="btn btn-info">
            내보내기
          </button>
        </div>
        {pagination}
      </div>
    </Fragment>
  )
}

export default RevenuePubList
