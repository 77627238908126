import React from 'react'

import CustomerListContainer from 'containers/customer/CustomerListContainer'
import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const Customer = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <CustomerListContainer />
    </PageTemplateContainer>
  )
}

export default Customer
