import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import React, { Component, Fragment } from 'react'
import debounce from 'lodash/debounce'

import EthReceiptList from 'components/ethreceipt/EthReceiptList'
import MessageModal from 'components/common/MessageModal'

import * as baseActions from 'store/modules/base'
import * as ethReceiptActions from 'store/modules/ethreceipt'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import * as pagerActions from 'store/modules/pager'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { delay, jsonValueByKey, loadingDelay } from 'libs/common'
import { ekey } from 'libs/enums'
import { stkeys, storage } from 'libs/storage'

class EthReceiptListContainer extends Component {
  state = {
    keyword: '',
    pageNo: 1,
    pageSize: 10
  }

  async componentDidMount() {
    const { pageNo } = this.state
    const { EthReceiptActions } = this.props

    EthReceiptActions.init()

    await this.getList(pageNo)
  }

  getList = async (pageNo) => {
    const { keyword, pageSize } = this.state
    const {
      BaseActions,
      EthReceiptActions,
      MeActions,
      ModalActions
    } = this.props

    try {
      BaseActions.setLoadingActive(true)
      await EthReceiptActions.getList({
        keyword,
        pageNo,
        pageSize
      })
      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handlePageChange = async (pageNo) => {
    await this.getList(pageNo)
    this.setState({
      pageNo
    })
  }

  debouncedFilter = debounce(async () => {
    await this.getList(1)
    this.setState({
      pageNo: 1
    })
  }, 500)

  handleFilter = (e) => {
    this.setState(
      {
        keyword: e.target.value
      },
      () => {
        this.debouncedFilter()
      }
    )
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  render() {
    const { keyword, pageNo, pageSize } = this.state
    const { rowCount, list, messageModal } = this.props
    const { handlePageChange, handleFilter, handleOK } = this

    return (
      <Fragment>
        <EthReceiptList
          pagination={
            <Pagination
              activePage={pageNo}
              itemsCountPerPage={pageSize}
              totalItemsCount={rowCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          }
          keyword={keyword}
          handleFilter={handleFilter}
          list={list}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.ethreceipt.get('error'),
    rowCount: state.ethreceipt.get('rowCount'),
    list: state.ethreceipt.get('list'),
    newToken: state.ethreceipt.get('newToken'),
    user: state.me.get('user'),
    messageModal: state.modal.get('message').toJS(),
    confirmModal: state.modal.get('confirm').toJS()
  }),
  (dispatch) => ({
    BaseActions: bindActionCreators(baseActions, dispatch),
    EthReceiptActions: bindActionCreators(ethReceiptActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch),
    PagerActions: bindActionCreators(pagerActions, dispatch)
  })
)(withRouter(EthReceiptListContainer))
