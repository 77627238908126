import React from 'react'

import EmailFormContainer from 'containers/account/EmailFormContainer'
import MeFormContainer from 'containers/account/MeFormContainer'
import MeTemplate from 'components/account/me/MeTemplate'
import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import PasswordFormContainer from 'containers/account/PasswordFormContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const Profile = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <MeTemplate title="기본 정보">
        <MeFormContainer />
      </MeTemplate>

      <MeTemplate title="이메일 변경">
        <EmailFormContainer />
      </MeTemplate>

      <MeTemplate title="비밀번호 변경">
        <PasswordFormContainer />
      </MeTemplate>
    </PageTemplateContainer>
  )
}

export default Profile
