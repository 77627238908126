import { defaultClient as axios } from 'libs/client'

const ethBlackList = async (params) => {
  const response = await axios.post('api/v1/eth/eth-black-list', params)
  return response
}

const addEthBlack = async (params) => {
  const response = await axios.post('api/v1/eth/add-eth-black', params)
  return response
}

const deleteEthBlack = async (params) => {
  const response = await axios.post('api/v1/eth/delete-eth-black', params)
  return response
}

const ethReceiptList = async (params) => {
  const response = await axios.post('api/v1/eth/eth-receipt-list', params)
  return response
}

const ethAskList = async (params) => {
  const response = await axios.post('api/v1/eth/eth-ask-list', params)
  return response
}

const updateEthAsk = async (params) => {
  const response = await axios.post('api/v1/eth/update-eth-ask', params)
  return response
}

const ethTrafficLog = async (params) => {
  const response = await axios.post('api/v1/eth/eth-traffic-log', params)
  return response
}

export default {
  ethBlackList,
  addEthBlack,
  deleteEthBlack,
  ethReceiptList,
  ethAskList,
  updateEthAsk,
  ethTrafficLog
}
