import { defaultClient as axios } from 'libs/client'

const index = async (params) => {
  const response = await axios.post('api/v1/adxevent/', params)
  return response
}

const add = async (params) => {
  const response = await axios.post('api/v1/adxevent/add', params)
  return response
}

const modify = async (params) => {
  const response = await axios.post('api/v1/adxevent/modify', params)
  return response
}

export default {
  index,
  add,
  modify
}
