import { Map } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { mailAPI } from 'api'

// action type
const INIT = 'mail/INIT'

const SEND = 'mail/SEND'
const SEND_LOADING = 'mail/SEND_LOADING'
const SEND_SUCCESS = 'mail/SEND_SUCCESS'
const SEND_FAILURE = 'mail/SEND_FAILURE'

const ANSWER_SEND = 'mail/ANSWER_SEND'
const ANSWER_SEND_LOADING = 'mail/ANSWER_SEND_LOADING'
const ANSWER_SEND_SUCCESS = 'mail/ANSWER_SEND_SUCCESS'
const ANSWER_SEND_FAILURE = 'mail/ANSWER_SEND_FAILURE'

// action creator
export const init = createAction(INIT)

export const send = (params) => ({
  type: SEND,
  payload: mailAPI.send(params)
})

export const answerSend = (params) => ({
  type: ANSWER_SEND,
  payload: mailAPI.answerSend(params)
})

// initial state
const initialState = Map({
  error: '',
  newToken: '',
  loading: false
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [SEND_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [SEND_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [SEND_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [ANSWER_SEND_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [ANSWER_SEND_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [ANSWER_SEND_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    }
  },
  initialState
)
