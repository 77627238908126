import React from 'react'

import SectionWithTitle from 'components/common/SectionWithTitle'

const NoticeTemplate = ({ children }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <SectionWithTitle title="자주 묻는 질문 등록">
          {children}
        </SectionWithTitle>
      </div>
    </div>
  )
}

export default NoticeTemplate
