import React from 'react'

import AppListContainer from 'containers/app/AppListContainer'
import AppTemplate from 'components/app/AppTemplate'
import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const AppList = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <AppTemplate>
        <AppListContainer />
      </AppTemplate>
    </PageTemplateContainer>
  )
}

export default AppList
