import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import React, { Component, Fragment } from 'react'
import debounce from 'lodash/debounce'

import CompleteModal from 'components/refund/CompleteModal'
import ConfirmModal from 'components/common/ConfirmModal'
import MessageModal from 'components/common/MessageModal'
import RefundPubComList from 'components/refund/RefundPubComList'
import RefundPubReqList from 'components/refund/RefundPubReqList'
import RefundPubTab from 'components/refund/RefundPubTab'

import * as baseActions from 'store/modules/base'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import * as refundPubActions from 'store/modules/refundpub'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { delay, jsonValueByKey, loadingDelay } from 'libs/common'
import { ekey } from 'libs/enums'
import { stkeys, storage } from 'libs/storage'

class RefundPubTabContainer extends Component {
  state = {
    key: 'first',
    file: null,
    keyword: '',
    pageNo: 1,
    pageSize: 10
  }

  async componentDidMount() {
    const { ModalActions, RefundPubActions } = this.props

    RefundPubActions.init()

    const user = storage.get(stkeys.user)
    if (user && user.Group !== 'ADMINISTRATORS') {
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: '권한이 없습니다'
      })
    } else {
      const { pageNo } = this.state
      await this.getReqList(pageNo)
    }
  }

  getReqList = async (pageNo) => {
    const { keyword, pageSize } = this.state
    const {
      BaseActions,
      MeActions,
      ModalActions,
      RefundPubActions
    } = this.props
    const user = storage.get(stkeys.user)
    if (user.Group !== 'ADMINISTRATORS') {
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: '권한이 없습니다'
      })
      return
    }

    RefundPubActions.initList()
    try {
      BaseActions.setLoadingActive(true)
      await RefundPubActions.getReqList({
        type: 0,
        keyword,
        pageNo,
        pageSize
      })
      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  getComList = async (pageNo) => {
    const { keyword, pageSize } = this.state
    const {
      BaseActions,
      MeActions,
      ModalActions,
      RefundPubActions
    } = this.props
    const user = storage.get(stkeys.user)
    if (user.Group !== 'ADMINISTRATORS') {
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: '권한이 없습니다'
      })
      return
    }

    RefundPubActions.initList()
    try {
      BaseActions.setLoadingActive(true)
      await RefundPubActions.getComList({
        type: 2,
        keyword,
        pageNo,
        pageSize
      })
      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handlePageChange = async (pageNo) => {
    const { key } = this.state
    if (key === 'first') {
      await this.getReqList(pageNo)
    } else {
      await this.getComList(pageNo)
    }

    this.setState({
      pageNo
    })
  }

  handleTabSelect = async (key) => {
    this.setState(
      {
        key,
        keyword: ''
      },
      async () => {
        if (key === 'first') {
          await this.getReqList(1)
        } else {
          await this.getComList(1)
        }
      }
    )
  }

  debouncedFilter1 = debounce(async () => {
    await this.getReqList(1)
    this.setState({
      pageNo: 1
    })
  }, 500)

  handleFilter1 = (e) => {
    this.setState(
      {
        keyword: e.target.value
      },
      () => {
        this.debouncedFilter1()
      }
    )
  }

  debouncedFilter2 = debounce(async () => {
    await this.getComList(1)
    this.setState({
      pageNo: 1
    })
  }, 500)

  handleFilter2 = (e) => {
    this.setState(
      {
        keyword: e.target.value
      },
      () => {
        this.debouncedFilter2()
      }
    )
  }

  openUpload = (paymentID) => {
    const { RefundPubActions } = this.props
    RefundPubActions.openUpload(paymentID)
  }

  handleFileChange = (e) => {
    this.setState({ file: e.target.files[0] })
  }

  handleUpload = async () => {
    const { paymentID, MeActions, ModalActions, RefundPubActions } = this.props

    if (!this.state.file) {
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: '파일을 등록해주세요.'
      })
      return
    }

    try {
      const data = new FormData()
      data.append('paymentID', paymentID)
      data.append('uploadFile', this.state.file)

      await RefundPubActions.uploadTaxBill(data)

      const { error, newToken } = this.props

      if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        RefundPubActions.closeUpload()
        this.setState({
          file: null
        })
      } else {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleUploadCancel = () => {
    const { RefundPubActions } = this.props
    RefundPubActions.closeUpload()
  }

  handleComplete = async (paymentID) => {
    const { ModalActions, RefundPubActions } = this.props
    RefundPubActions.setPaymentID(paymentID)

    ModalActions.openConfirmModal({
      title: 'ZENAAD',
      description: '입금완료 하시겠습니까?'
    })
  }

  handleConfirm = async () => {
    const { paymentID, MeActions, ModalActions, RefundPubActions } = this.props

    try {
      await RefundPubActions.completePayment({
        paymentID
      })

      const { error, newToken } = this.props

      if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.closeConfirmModal()
      } else {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleConfirmCancel = () => {
    const { ModalActions } = this.props
    ModalActions.closeConfirmModal()
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  render() {
    const { keyword, pageNo, pageSize } = this.state
    const { rowCount, list, open, messageModal, confirmModal } = this.props
    const {
      handlePageChange,
      handleTabSelect,
      handleFilter1,
      handleFilter2,
      openUpload,
      handleFileChange,
      handleUpload,
      handleUploadCancel,
      handleComplete,
      handleConfirm,
      handleConfirmCancel,
      handleOK
    } = this

    return (
      <Fragment>
        <RefundPubTab
          handleTabSelect={handleTabSelect}
          first={
            <RefundPubReqList
              pagination={
                <Pagination
                  activePage={pageNo}
                  itemsCountPerPage={pageSize}
                  totalItemsCount={rowCount}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              }
              list={list}
              openUpload={openUpload}
              handleComplete={handleComplete}
              keyword={keyword}
              handleFilter1={handleFilter1}
            />
          }
          second={
            <RefundPubComList
              pagination={
                <Pagination
                  activePage={pageNo}
                  itemsCountPerPage={pageSize}
                  totalItemsCount={rowCount}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              }
              list={list}
              keyword={keyword}
              handleFilter2={handleFilter2}
            />
          }
        />
        <CompleteModal
          open={open}
          handleFileChange={handleFileChange}
          handleUpload={handleUpload}
          handleUploadCancel={handleUploadCancel}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
        <ConfirmModal
          title={confirmModal.title}
          description={confirmModal.description}
          open={confirmModal.opened}
          onConfirm={handleConfirm}
          onCancel={handleConfirmCancel}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.refundpub.get('error'),
    rowCount: state.refundpub.get('rowCount'),
    list: state.refundpub.get('list'),
    open: state.refundpub.get('open'),
    paymentID: state.refundpub.get('paymentID'),
    newToken: state.refundpub.get('newToken'),
    user: state.me.get('user'),
    messageModal: state.modal.get('message').toJS(),
    confirmModal: state.modal.get('confirm').toJS()
  }),
  (dispatch) => ({
    BaseActions: bindActionCreators(baseActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch),
    RefundPubActions: bindActionCreators(refundPubActions, dispatch)
  })
)(withRouter(RefundPubTabContainer))
