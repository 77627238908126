import React, { Component, Fragment } from 'react'

import AdminListItem from 'components/admin/AdminListItem'

class AdminList extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.users !== nextProps.users
  }

  render() {
    const {
      keyword,
      users,
      handleFilter,
      handleAccept,
      handleBlock
    } = this.props

    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12">
            ADMINISTRATORS 그룹에 포함된 단 하나의 계정만이 수락/차단가능합니다.
            ex). eykim
          </div>
        </div>
        <div className="row jc-fe mB-10">
          <div className="col-md-2">
            <input
              type="search"
              name="keyword"
              className="form-control"
              placeholder="이름"
              value={keyword}
              onChange={handleFilter}
            />
          </div>
        </div>

        <div className="table-responsive table-scroll-y">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th className="w-20">아이디</th>
                <th className="w-20">이름</th>
                <th className="w-20">성</th>
                <th className="w-20">이메일</th>
                <th>계정사용</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((user, index) => {
                  const { ID, FirstName, LastName, Email, Accept } = user.toJS()
                  return (
                    <AdminListItem
                      key={index}
                      id={ID}
                      firstName={FirstName}
                      lastName={LastName}
                      email={Email}
                      accept={Accept}
                      handleAccept={handleAccept}
                      handleBlock={handleBlock}
                    />
                  )
                })}
            </tbody>
          </table>
        </div>
      </Fragment>
    )
  }
}

export default AdminList
