import { List, Map, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { appAPI } from 'api'

// action type
const INIT = 'apikeyrequest/INIT'

const SET_APP_ID = 'apikeyrequest/SET_APP_ID'
const SET_API_KEY_STATUS = 'apikeyrequest/SET_API_KEY_STATUS'
const SET_UPDATE_LIST = 'apikeyrequest/SET_UPDATE_LIST'

const GET_LIST = 'apikeyrequest/GET_LIST'
const GET_LIST_LOADING = 'apikeyrequest/GET_LIST_LOADING'
const GET_LIST_SUCCESS = 'apikeyrequest/GET_LIST_SUCCESS'
const GET_LIST_FAILURE = 'apikeyrequest/GET_LIST_FAILURE'

const UPDATE = 'apikeyrequest/UPDATE'
const UPDATE_LOADING = 'apikeyrequest/UPDATE_LOADING'
const UPDATE_SUCCESS = 'apikeyrequest/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'apikeyrequest/UPDATE_FAILURE'

// action creator
export const init = createAction(INIT)

export const setAppID = createAction(SET_APP_ID)
export const setApiKeyStatus = createAction(SET_API_KEY_STATUS)
export const setUpdateList = createAction(SET_UPDATE_LIST)

export const getList = (params) => ({
  type: GET_LIST,
  payload: appAPI.apiKeyRequestList(params)
})

export const update = (params) => ({
  type: UPDATE,
  payload: appAPI.updateApiKeyRequest(params)
})

// initial state
const initialState = Map({
  error: '',
  list: List(),
  appID: '',
  apiKeyStatus: -1,
  newToken: '',
  loading: false
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [GET_LIST_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [SET_APP_ID]: (state, { payload: appID }) => {
      return state.set('appID', appID)
    },
    [SET_API_KEY_STATUS]: (state, { payload: apiKeyStatus }) => {
      return state.set('apiKeyStatus', apiKeyStatus)
    },
    [SET_UPDATE_LIST]: (state, { payload: appID }) => {
      const index = state
        .get('list')
        .findIndex((item) => item.get('AppID') === appID)

      const list = state.get('list').delete(index)

      return state
        .set('list', list)
        .set('appID', '')
        .set('apiKeyStatus', -1)
    },
    [GET_LIST_SUCCESS]: (state, action) => {
      const { error, rows: list, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('list', fromJS(list))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_LIST_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [UPDATE_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [UPDATE_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [UPDATE_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    }
  },
  initialState
)
