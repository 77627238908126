// import NumberFormat from 'react-number-format'
import React from 'react'
import moment from 'moment'

const KPIDailyListItem = ({ registTime, dau, nru, aru }) => {
  return (
    <tr className="bg-info text-white">
      <td className="pL-50">{moment(registTime).format('YYYY-MM-DD')}</td>
      <td className="text-right">
        {/* <NumberFormat
          value={Math.floor(dau)}
          displayType="text"
          thousandSeparator={true}
        /> */}
        {dau}
      </td>
      <td className="text-right">
        {/* <NumberFormat
          value={Math.floor(nru)}
          displayType="text"
          thousandSeparator={true}
        /> */}
        {nru}
      </td>
      <td className="text-right">
        {/* <NumberFormat
          value={Math.floor(aru)}
          displayType="text"
          thousandSeparator={true}
        /> */}
        {aru}
      </td>
    </tr>
  )
}

export default KPIDailyListItem
