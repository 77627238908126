import { List, Map, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { adminAPI } from 'api'

// action type
const INIT = 'admin/INIT'
const GET_LIST = 'admin/GET_LIST'
const GET_LIST_LOADING = 'admin/GET_LIST_LOADING'
const GET_LIST_SUCCESS = 'admin/GET_LIST_SUCCESS'
const GET_LIST_FAILURE = 'admin/GET_LIST_FAILURE'

const ACCEPT = 'admin/ACCEPT'
const ACCEPT_LOADING = 'admin/ACCEPT_LOADING'
const ACCEPT_SUCCESS = 'admin/ACCEPT_SUCCESS'
const ACCEPT_FAILURE = 'admin/ACCEPT_FAILURE'
const SET_ACCEPT = 'admin/SET_ACCEPT'

const BLOCK = 'admin/BLOCK'
const BLOCK_LOADING = 'admin/BLOCK_LOADING'
const BLOCK_SUCCESS = 'admin/BLOCK_SUCCESS'
const BLOCK_FAILURE = 'admin/BLOCK_FAILURE'
const SET_BLOCK = 'admin/SET_BLOCK'

// action creator
export const init = createAction(INIT)
export const getList = (params) => ({
  type: GET_LIST,
  payload: adminAPI.index(params)
})

export const accept = (params) => ({
  type: ACCEPT,
  payload: adminAPI.accept(params)
})

export const setAccept = createAction(SET_ACCEPT, (id) => id)

export const block = (params) => ({
  type: BLOCK,
  payload: adminAPI.block(params)
})

export const setBlock = createAction(SET_BLOCK, (id) => id)

// initial state
const initialState = Map({
  error: '',
  users: List(),
  keyword: '',
  newToken: '',
  loading: false
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [GET_LIST_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_LIST_SUCCESS]: (state, action) => {
      const { error, rows: users, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('users', fromJS(users))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_LIST_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [ACCEPT_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [ACCEPT_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [ACCEPT_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [SET_ACCEPT]: (state, { payload: id }) => {
      const index = state
        .get('users')
        .findIndex((item) => item.get('ID') === id)
      return state.setIn(['users', index, 'Accept'], 1)
    },
    [BLOCK_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [BLOCK_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [BLOCK_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [SET_BLOCK]: (state, { payload: id }) => {
      const index = state
        .get('users')
        .findIndex((item) => item.get('ID') === id)
      return state.setIn(['users', index, 'Accept'], 0)
    }
  },
  initialState
)
