import React, { Fragment } from 'react'

import 'react-datepicker/dist/react-datepicker.css'

const Item = ({ registTime, users, point }) => {
  return (
    <tr>
      <td>{registTime}</td>
      <td className="w-33 text-right">{users}</td>
      <td className="w-33 text-right">{point}</td>
    </tr>
  )
}

const SummaryUser = ({ pagination, list }) => {
  return (
    <Fragment>
      <div className="table-responsive table-scroll-y">
        <table className="table table-bordered" cellSpacing="0" width="100%">
          <thead className="thead-light">
            <tr>
              <th>날짜</th>
              <th>유저수</th>
              <th>수익</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((item, index) => {
                const { RegistTime, Users, Point } = item.toJS()
                return (
                  <Item
                    key={index}
                    registTime={RegistTime}
                    users={Users}
                    point={Point}
                  />
                )
              })}
          </tbody>
        </table>
      </div>
      {/* <div className="d-f jc-fe mT-20">
        총 수익:{' '}
        <NumberFormat
          value={total}
          displayType="text"
          prefix="&#8361; "
          thousandSeparator={true}
        />
      </div> */}
      <div className="d-f jc-fe mT-20">{pagination}</div>
      {/* <div className="mT-20">
        플랫폼 총 수익:{' '}
        <NumberFormat
          value={platform}
          displayType="text"
          prefix="&#8361; "
          thousandSeparator={true}
        />
      </div> */}
    </Fragment>
  )
}

export default SummaryUser
