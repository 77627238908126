import React from 'react'

import SectionWithTitle from 'components/common/SectionWithTitle'

const RevenuePubTemplate = ({ children }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <SectionWithTitle title="수익율(퍼블리셔)">{children}</SectionWithTitle>
      </div>
    </div>
  )
}

export default RevenuePubTemplate
