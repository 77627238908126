import React, { Fragment } from 'react'

import SectionWithTitle from 'components/common/SectionWithTitle'

const CustomerTemplate = ({ customerList, customerView, customerForm }) => {
  return (
    <Fragment>
      <div className="row mB-20">
        <div className="col-md-12">
          <SectionWithTitle title="광고주/퍼블리셔 계정">
            <div>{customerList}</div>
            <div className="mT-20">{customerView}</div>
          </SectionWithTitle>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <SectionWithTitle title="등록">
            <div className="mT-20">{customerForm}</div>
          </SectionWithTitle>
        </div>
      </div>
    </Fragment>
  )
}

export default CustomerTemplate
