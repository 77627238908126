import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import cx from 'classnames'

import HorizontalInput from 'components/common/HorizontalInput'
import MultipleSelect from 'components/common/MultipleSelect'

import * as adxActions from 'store/modules/adx'
import * as meActions from 'store/modules/me'
import * as validate from 'libs/validate'
import { billingType } from 'json/billingType'
import { country } from 'json/country'
import { platform } from 'json/platform'
import { product } from 'json/product'

const TempForm = ({ text, children }) => {
  return (
    <div className="row">
      <div className="col-9">{children}</div>
      <div className="col-3 col-form-label"><small className="c-blue-900">{text}</small></div>
    </div>
  )
}

const TempInput = ({
  input,
  id,
  type,
  placeholder,
  helpertext,
  meta: { touched, error }
}) => {
  return (
    <div className={cx('col-sm-3')}>
      <input
        {...input}
        id={id}
        type={type}
        className={cx('form-control', { 'bdc-red-500': touched && error })}
        placeholder={placeholder}
      />
      {helpertext && (
        <small className="form-text text-muted mT-5 c-blue-900">
          {helpertext}
        </small>
      )}
      {touched &&
        (error && (
          <div className={cx('mT-5', { 'c-red-500': touched && error })}>
            {error}
          </div>
        ))}
    </div>
  )
}

class AdxListForm extends Component {
  // state = {
  //   selectedCountry: []
  // }

  // handleCountryChange = (options) => {
  //   if (options.length > 1) {
  //     if (options[0].value === 'Global') {
  //       this.setState({
  //         selectedCountry: options.slice(1)
  //       })

  //       return
  //     }
  //   }

  //   const temp = options.filter((op) => op.value === 'Global')
  //   if (temp && temp.length > 0) {
  //     this.setState({
  //       selectedCountry: [temp[0]]
  //     })

  //     return
  //   }

  //   this.setState({
  //     selectedCountry: options
  //   })
  // }

  render() {
    const {
      isModify,
      pristine,
      submitting,
      handleSubmit,
      handleStart,
      handleStop,
      handleCountryChange,
      handlePlatformChange,
      handleProductChange,
      handleBillingTypeChange,
      selectedID,
      adLinkStatus,
      selectedCountry,
      selectedPlatform,
      selectedProduct,
      selectedBillingType
    } = this.props

    return (
      <form onSubmit={handleSubmit}>
        <div className="row gap-20 pos-r mT-20">
          <div className="col-md-12">
            <div className="bgc-white p-10 bd">
              <div className="mT-10">
                <Field name="AID" component="input" type="hidden" />
                <TempForm text="애드익스체인지 이름">
                  <Field
                    name="AdxName"
                    label="AdxName"
                    type="text"
                    col1="2"
                    col2="10"
                    component={HorizontalInput}
                    validate={validate.required}
                  />
                </TempForm>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="bgc-white p-10 bd">
              <h6 className="c-grey-900">Tracking</h6>
              <div className="mT-10">
                <TempForm text="트래킹 URL 도메인">
                  <Field
                    name="TrackDomain"
                    label="TrackDomain"
                    type="text"
                    component={HorizontalInput}
                    validate={[validate.required, validate.jsonformat]}
                  />
                </TempForm>
                <TempForm text="트래킹 파라메터 키이름(adid, medid, bidid, extra 순서 중요)">
                  <Field
                    name="TrackKey"
                    label="TrackKey"
                    type="text"
                    component={HorizontalInput}
                    validate={[validate.required, validate.jsonformat]}
                  />
                </TempForm>
                <TempForm text="트래킹 파라메터 키이름/값형식(adid, medid, bidid, extra 순서 중요)">
                  <Field
                    name="TrackKeyValue"
                    label="TrackKeyValue"
                    type="text"
                    component={HorizontalInput}
                    validate={[validate.required, validate.jsonformat]}
                  />
                </TempForm>
                <TempForm text="트래킹 여유분으로 사용될 키이름">
                  <Field
                    name="TrackExtraKey"
                    label="TrackExtraKey"
                    type="text"
                    component={HorizontalInput}
                    validate={[validate.required, validate.jsonformat]}
                  />
                </TempForm>
                <TempForm text="트래킹 URL 예제">
                  <Field
                    name="TrackExample"
                    label="TrackExample"
                    type="text"
                    component={HorizontalInput}
                    validate={validate.required}
                  />
                </TempForm>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="bgc-white p-10 bd">
              <h6 className="c-grey-900">Postback</h6>
              <div className="mT-10">
                <TempForm text="포스트백 API 이름">
                  <Field
                    name="PostbackAPI"
                    label="PostbackAPI"
                    type="text"
                    component={HorizontalInput}
                    validate={validate.required}
                  />
                </TempForm>
                <TempForm text="포스트백 이벤트 키이름">
                  <Field
                    name="PostbackKeyEvent"
                    label="PostbackKeyEvent"
                    type="text"
                    component={HorizontalInput}
                    validate={[validate.required, validate.jsonformat]}
                  />
                </TempForm>
                <TempForm text="포스트백 애드익스체인지 아이디 키이름">
                  <Field
                    name="PostbackKeyAdxID"
                    label="PostbackKeyAdxID"
                    type="text"
                    component={HorizontalInput}
                    validate={[validate.required, validate.jsonformat]}
                  />
                </TempForm>
                <TempForm text="포스트백 설치 이벤트 값">
                  <Field
                    name="PostbackValueInstall"
                    label="PostbackValueInstall"
                    type="text"
                    component={HorizontalInput}
                    validate={[validate.required, validate.jsonformat]}
                  />
                </TempForm>
                <TempForm text="포스트백 파라메터 키이름(adid, medid, bidid 순서 중요)">
                  <Field
                    name="PostbackKey"
                    label="PostbackKey"
                    type="text"
                    component={HorizontalInput}
                    validate={[validate.required, validate.jsonformat]}
                  />
                </TempForm>
                <TempForm text="포스트백 포멧">
                  <Field
                    name="PostbackFormat"
                    label="PostbackFormat"
                    type="text"
                    component={HorizontalInput}
                    validate={validate.required}
                  />
                </TempForm>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="bgc-white p-10 bd">
              <h6 className="c-grey-900">AdLink</h6>
              <div className="mT-10">
                <div className="row">
                  <div className="col-sm-3">
                    {isModify && adLinkStatus !== '' && (
                      <div>
                        {adLinkStatus === 'WAITING' ? (
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => handleStart(selectedID)}
                          >
                            시작
                          </button>
                        ):(
                          <button
                            type="button"
                            className="btn btn-dark"
                            onClick={() => handleStop(selectedID)}
                          >
                            중지
                          </button>
                        )}
                        {' '}{adLinkStatus === "WAITING" ? "중지" : "시작"}
                      </div>
                    )}
                  </div>
                  <Field
                    name="AdLinkEmail"
                    placeholder="AdLinkEmail"
                    type="text"
                    component={TempInput}
                    helpertext="광고연동 이메일 계정"
                    validate={[validate.required, validate.email]}
                  />
                  <Field
                    name="AdLinkCampName"
                    placeholder="AdLinkCampName"
                    type="text"
                    component={TempInput}
                    helpertext="광고연동 캠페인 이름"
                    validate={validate.required}
                  />
                  <div className="col-sm-3">
                    <Field
                      name="AdLinkCountry"
                      placeholder="AdLinkCountry"
                      component={MultipleSelect}
                      options={country}
                      helpertext="광고연동 국가"
                      selectedValue={selectedCountry}
                      onChange={handleCountryChange}
                      validate={validate.arraylen}
                    />
                  </div>
                </div>
                <div className="row mT-10">
                  <div className="col-sm-3">
                    <Field
                      name="AdLinkPlatform"
                      placeholder="AdLinkPlatform"
                      component={MultipleSelect}
                      options={platform}
                      helpertext="광고연동 플랫폼"
                      selectedValue={selectedPlatform}
                      onChange={handlePlatformChange}
                      validate={validate.arraylen}
                    />
                  </div>
                  <div className="col-sm-3">
                    <Field
                      name="AdLinkProduct"
                      placeholder="AdLinkProduct"
                      component={MultipleSelect}
                      options={product}
                      helpertext="광고연동 상품"
                      selectedValue={selectedProduct}
                      onChange={handleProductChange}
                      validate={validate.arraylen}
                    />
                  </div>
                  <div className="col-sm-3">
                    <Field
                      name="AdLinkBillingType"
                      placeholder="AdLinkBillingType"
                      component={MultipleSelect}
                      options={billingType}
                      helpertext="광고연동 과금 유형"
                      selectedValue={selectedBillingType}
                      onChange={handleBillingTypeChange}
                      validate={validate.arraylen}
                    />
                  </div>
                  <Field
                    name="AdLinkBanner"
                    placeholder="AdLinkBanner"
                    type="text"
                    component={TempInput}
                    helpertext="광고연동 배너 크기"
                    validate={[validate.required, validate.jsonformat]}
                  />
                </div>
                <div className="row mT-10">
                  <Field
                    name="AdLinkInterstitial"
                    placeholder="AdLinkInterstitial"
                    type="text"
                    component={TempInput}
                    helpertext="광고연동 전면 크기"
                    validate={[validate.required, validate.jsonformat]}
                  />
                  <Field
                    name="AdLinkVideo"
                    placeholder="AdLinkVideo"
                    type="text"
                    component={TempInput}
                    helpertext="광고연동 비디오 포멧"
                    validate={[validate.required, validate.jsonformat]}
                  />
                  <Field
                    name="AdLinkExtra1"
                    placeholder="AdLinkExtra1"
                    type="text"
                    component={TempInput}
                    helpertext="광고연동 여유분 필드1"
                    validate={[validate.required, validate.jsonformat]}
                  />
                  <Field
                    name="AdLinkExtra2"
                    placeholder="AdLinkExtra2"
                    type="text"
                    component={TempInput}
                    helpertext="광고연동 여유분 필드2"
                    validate={[validate.required, validate.jsonformat]}
                  />
                </div>
                <div className="row mT-10">
                  <Field
                    name="AdLinkExtra3"
                    placeholder="AdLinkExtra3"
                    type="text"
                    component={TempInput}
                    helpertext="광고연동 여유분 필드3"
                    validate={[validate.required, validate.jsonformat]}
                  />
                  <Field
                    name="AdLinkExtra4"
                    placeholder="AdLinkExtra4"
                    type="text"
                    component={TempInput}
                    helpertext="광고연동 여유분 필드4"
                    validate={[validate.required, validate.jsonformat]}
                  />
                  <Field
                    name="AdLinkExtra5"
                    placeholder="AdLinkExtra5"
                    type="text"
                    component={TempInput}
                    helpertext="광고연동 여유분 필드5"
                    validate={[validate.required, validate.jsonformat]}
                  />
                  <Field
                    name="Comment"
                    placeholder="Comment"
                    type="text"
                    component={TempInput}
                    helpertext="코멘트"
                    validate={validate.required}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-f jc-fe mT-20">
          <div className="peers gap-10">
            {isModify && (
              <div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={pristine || submitting}
                >
                  수정
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    )
  }
}

let AdxListReduxForm = reduxForm({
  form: 'adxListForm',
  enableReinitialize: true
})(AdxListForm)

AdxListReduxForm = connect(
  (state) => ({
    isModify: state.adx.get('isModify'),
    form1: state.adx.get('form'),
    initialValues: state.adx.get('form') && state.adx.get('form').toJS()
  }),
  (dispatch) => ({
    AdxActions: bindActionCreators(adxActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch)
  })
)(AdxListReduxForm)

export default AdxListReduxForm
