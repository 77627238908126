import { Sparklines, SparklinesBars } from 'react-sparklines'
import NumberFormat from 'react-number-format'
import React from 'react'

const Yesterday = ({ data }) => {
  let dau = 0
  let nru = 0

  if (data) {
    if (data.DAU) {
      dau = data.DAU
    }

    if (data.NRU) {
      nru = data.NRU
    }
  }
  return (
    <div className="row gap-20 pos-r mT-20">
      <div className="col-md-6">
        <div className="layers bd bgc-white p-20">
          <div className="layer w-100 mB-10">
            <h6 className="lh-1">Yesterday. DAU</h6>
          </div>
          <div className="layer w-100">
            <div className="peers ai-sb fxw-nw">
              <div className="peer peer-greed">
                <Sparklines
                  data={[0, 5, 6, 10, 9, 12, 4, 9]}
                  svgWidth={45}
                  svgHeight={20}
                >
                  <SparklinesBars
                    style={{
                      stroke: 'white',
                      strokeWidth: '1',
                      fill: '#40c0f5',
                      width: '30px'
                    }}
                  />
                </Sparklines>
              </div>
              <div className="peer">
                <span className="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-green-50 c-green-500">
                  <NumberFormat
                    value={dau}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6">
        <div className="layers bd bgc-white p-20">
          <div className="layer w-100 mB-10">
            <h6 className="lh-1">Yesterday. NRU</h6>
          </div>
          <div className="layer w-100">
            <div className="peers ai-sb fxw-nw">
              <div className="peer peer-greed">
                <Sparklines
                  data={[0, 5, 6, 10, 9, 12, 4, 9]}
                  svgWidth={45}
                  svgHeight={20}
                >
                  <SparklinesBars
                    style={{
                      stroke: 'white',
                      strokeWidth: '1',
                      fill: '#40c0f5',
                      width: '30px'
                    }}
                  />
                </Sparklines>
              </div>
              <div className="peer">
                <span className="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-green-50 c-green-500">
                  <NumberFormat
                    value={nru}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Yesterday
