export const platform = [
  {
    label: 'AOS',
    value: 'AOS'
  },
  {
    label: 'IOS',
    value: 'IOS'
  }
]
