import React from 'react'

import SectionWithTitle from 'components/common/SectionWithTitle'

const EthReceiptTemplate = ({ children }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <SectionWithTitle title="이더리움 영수증">{children}</SectionWithTitle>
      </div>
    </div>
  )
}

export default EthReceiptTemplate
