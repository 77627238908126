import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import React, { Fragment } from 'react'

const RevenuePubSearch = ({
  startDate,
  endDate,
  keyword,
  handleStartDate,
  handleEndDate,
  handleFilter
}) => {
  return (
    <Fragment>
      <div className="row justify-content-md-end mB-10">
        <div className="col-md-2">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            className="form-control"
            placeholderText="시작일"
            selected={startDate}
            name="startDate"
            onChange={handleStartDate}
          />
        </div>
        <div className="col-md-2">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            className="form-control"
            placeholderText="종료일"
            selected={endDate}
            name="endDate"
            onChange={handleEndDate}
          />
        </div>
        <div className="col-md-2">
          <input
            type="search"
            name="keyword"
            className="form-control"
            placeholder="이메일"
            value={keyword}
            onChange={handleFilter}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default RevenuePubSearch
