import { defaultClient as axios } from 'libs/client'

const summaryAdvertiser = async (params) => {
  const response = await axios.post('api/v1/summary/summary-advertiser', params)
  return response
}

const summaryPublisher = async (params) => {
  const response = await axios.post('api/v1/summary/summary-publisher', params)
  return response
}

const summaryUser = async (params) => {
  const response = await axios.post('api/v1/summary/summary-user', params)
  return response
}

export default {
  summaryAdvertiser,
  summaryPublisher,
  summaryUser
}
