import NumberFormat from 'react-number-format'
import React from 'react'

const RevenuePubMediaListItem = ({
  name,
  inventory,
  advertiser,
  revenue,
  impression,
  click,
  ctr,
  install
  // action
}) => {
  return (
    <tr className="bg-info text-white">
      <td className="pL-50">{name}</td>
      <td className="text-right">
        <NumberFormat
          value={inventory}
          displayType="text"
          thousandSeparator={true}
        />
      </td>
      <td className="text-right">
        <NumberFormat
          value={advertiser}
          displayType="text"
          thousandSeparator={true}
        />
      </td>
      <td className="text-right">
        <NumberFormat
          value={revenue}
          displayType="text"
          thousandSeparator={true}
          prefix={'\\ '}
        />
      </td>
      <td className="text-right">
        <NumberFormat
          value={impression}
          displayType="text"
          thousandSeparator={true}
        />
      </td>
      <td className="text-right">
        <NumberFormat
          value={click}
          displayType="text"
          thousandSeparator={true}
        />
      </td>
      <td className="text-right">{ctr} %</td>
      <td className="text-right">
        <NumberFormat
          value={install}
          displayType="text"
          thousandSeparator={true}
        />
      </td>
      {/* <td className="text-right">
        <NumberFormat
          value={action}
          displayType="text"
          thousandSeparator={true}
        />
      </td> */}
    </tr>
  )
}

export default RevenuePubMediaListItem
