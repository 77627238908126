const st = typeof localStorage === 'object' ? localStorage : {}

const stkeys = {
  user: 'user',
  accessToken: 'accessToken',
  refreshToken: 'refreshToken' // no use
}

const storage = {
  set: (key, value) => {
    st[key] = typeof value === 'string' ? value : JSON.stringify(value)
  },
  get: (key) => {
    if (!st[key]) {
      return null
    }

    const value = st[key]

    try {
      const parsed = JSON.parse(value)
      return parsed
    } catch (err) {
      return value
    }
  },
  remove: (key) => {
    delete st[key]
  },
  clear: () => {
    if (st.clear) {
      st.clear()
    }
  }
}

export { stkeys, storage }
