import { List, Map, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { adxEventAPI } from 'api'

// action type
const INIT = 'adxevent/INIT'

const GET_ADX_EVENT_LIST = 'adxevent/GET_ADX_EVENT_LIST'
const GET_ADX_EVENT_LIST_LOADING = 'adxevent/GET_ADX_EVENT_LIST_LOADING'
const GET_ADX_EVENT_LIST_SUCCESS = 'adxevent/GET_ADX_EVENT_LIST_SUCCESS'
const GET_ADX_EVENT_LIST_FAILURE = 'adxevent/GET_ADX_EVENT_LIST_FAILURE'

const ADD_ADX_EVENT = 'adxevent/ADD_ADX_EVENT'
const ADD_ADX_EVENT_LOADING = 'adxevent/ADD_ADX_EVENT_LOADING'
const ADD_ADX_EVENT_SUCCESS = 'adxevent/ADD_ADX_EVENT_SUCCESS'
const ADD_ADX_EVENT_FAILURE = 'adxevent/ADD_ADX_EVENT_FAILURE'

const MODIFY_ADX_EVENT = 'adxevent/MODIFY_ADX_EVENT'
const MODIFY_ADX_EVENT_LOADING = 'adxevent/MODIFY_ADX_EVENT_LOADING'
const MODIFY_ADX_EVENT_SUCCESS = 'adxevent/MODIFY_ADX_EVENT_SUCCESS'
const MODIFY_ADX_EVENT_FAILURE = 'adxevent/MODIFY_ADX_EVENT_FAILURE'

const SET_ADX_EVENT = 'adxevent/SET_ADX_EVENT'
const SET_IS_MODIFY = 'adxevent/SET_IS_MODIFY'

// action creator
export const init = createAction(INIT)

export const getAdxEventList = (params) => ({
  type: GET_ADX_EVENT_LIST,
  payload: adxEventAPI.index(params)
})

export const add = (params) => ({
  type: ADD_ADX_EVENT,
  payload: adxEventAPI.add(params)
})

export const modify = (params) => ({
  type: MODIFY_ADX_EVENT,
  payload: adxEventAPI.modify(params)
})

export const setAdxEvent = createAction(SET_ADX_EVENT)
export const setIsModify = createAction(SET_IS_MODIFY)

// initial state
const initialState = Map({
  error: '',
  rowCount: 0,
  list: List(),
  form: null,
  isModify: false,
  newToken: '',
  loading: false
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [GET_ADX_EVENT_LIST_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_ADX_EVENT_LIST_SUCCESS]: (state, action) => {
      const { error, rowCount, rows: list, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('rowCount', rowCount)
        .set('list', fromJS(list))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_ADX_EVENT_LIST_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [ADD_ADX_EVENT_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [ADD_ADX_EVENT_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [ADD_ADX_EVENT_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [MODIFY_ADX_EVENT_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [MODIFY_ADX_EVENT_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [MODIFY_ADX_EVENT_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [SET_ADX_EVENT]: (state, action) => {
      const form = action.payload
      if (form) {
        return state.set('form', fromJS(form))
      }

      return state.set('form', null)
    },
    [SET_IS_MODIFY]: (state, action) => {
      return state.set('isModify', action.payload)
    }
  },
  initialState
)
