import { defaultClient as axios } from 'libs/client'

const upload = async (params) => {
  const response = await axios.post('api/v1/notice/notice-upload', params)
  return response
}

const getUploadQuillImageUrl = async (params) => {
  const response = await axios.post(
    'api/v1/notice/get-upload-quill-image-url',
    params
  )
  return response
}

const uploadQuillImage = async (params) => {
  const response = await axios.post('api/v1/notice/upload-quill-image', params)
  return response
}

const addQuill = async (params) => {
  const response = await axios.post('api/v1/notice/add-quill', params)
  return response
}

export default {
  upload,
  getUploadQuillImageUrl,
  uploadQuillImage,
  addQuill
}
