import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React, { Component, Fragment } from 'react'
import debounce from 'lodash/debounce'

import ApiKeyRequestList from 'components/apikeyrequest/ApiKeyRequestList'
import ConfirmModal from 'components/common/ConfirmModal'
import MessageModal from 'components/common/MessageModal'

import * as apiKeyRequestActions from 'store/modules/apikeyrequest'
import * as baseActions from 'store/modules/base'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { delay, jsonValueByKey, loadingDelay } from 'libs/common'
import { ekey } from 'libs/enums'
import { stkeys, storage } from 'libs/storage'

class ApiKeyRequestListContainer extends Component {
  state = {
    keyword: ''
  }

  async componentDidMount() {
    const { ApiKeyRequestActions, ModalActions } = this.props

    ApiKeyRequestActions.init()

    const user = storage.get(stkeys.user)
    if (user && user.Group !== 'ADMINISTRATORS') {
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: '권한이 없습니다'
      })
    } else {
      const { pageNo } = this.state
      await this.getList(pageNo)
    }
  }

  getList = async () => {
    const { keyword } = this.state
    const {
      BaseActions,
      ApiKeyRequestActions,
      MeActions,
      ModalActions
    } = this.props

    try {
      BaseActions.setLoadingActive(true)
      await ApiKeyRequestActions.getList({
        keyword
      })
      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      let e = error
      if (!e) {
        const { status } = err.response
        if (status === 401) {
          e = 'NEED_SIGNIN'
        }
      }

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(e)
      })
    }
  }

  debouncedFilter = debounce(async () => {
    await this.getList(1)
    this.setState({
      pageNo: 1
    })
  }, 500)

  handleFilter = (e) => {
    this.setState(
      {
        keyword: e.target.value
      },
      () => {
        this.debouncedFilter()
      }
    )
  }

  handleModify = async (appID, apiKeyStatus) => {
    const { ApiKeyRequestActions, ModalActions } = this.props

    ApiKeyRequestActions.setAppID(appID)
    ApiKeyRequestActions.setApiKeyStatus(apiKeyStatus)

    ModalActions.openConfirmModal({
      title: 'ZENAAD',
      description:
        apiKeyStatus === 1 ? '승인하시겠습니까?' : '반려하시겠습니까?'
    })
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  handleConfirm = async () => {
    const {
      appID,
      apiKeyStatus,
      ApiKeyRequestActions,
      MeActions,
      ModalActions
    } = this.props
    ModalActions.closeConfirmModal()

    try {
      await ApiKeyRequestActions.update({ appID, apiKey: '', apiKeyStatus })

      const { error, newToken } = this.props

      if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ApiKeyRequestActions.setUpdateList(appID)

        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description:
            apiKeyStatus === 1 ? '승인되었습니다.' : '반려되었습니다.'
        })
      } else {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleConfirmCancel = () => {
    const { ApiKeyRequestActions, ModalActions } = this.props
    ApiKeyRequestActions.setAppID('')
    ApiKeyRequestActions.setApiKeyStatus(0)
    ModalActions.closeConfirmModal()
  }

  render() {
    const { keyword } = this.state
    const { list, messageModal, confirmModal } = this.props
    const {
      handleFilter,
      handleModify,
      handleOK,
      handleConfirm,
      handleConfirmCancel
    } = this

    return (
      <Fragment>
        <ApiKeyRequestList
          keyword={keyword}
          list={list}
          handleFilter={handleFilter}
          handleModify={handleModify}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
        <ConfirmModal
          title={confirmModal.title}
          description={confirmModal.description}
          open={confirmModal.opened}
          onConfirm={handleConfirm}
          onCancel={handleConfirmCancel}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.apikeyrequest.get('error'),
    list: state.apikeyrequest.get('list'),
    appID: state.apikeyrequest.get('appID'),
    apiKeyStatus: state.apikeyrequest.get('apiKeyStatus'),
    newToken: state.apikeyrequest.get('newToken'),
    messageModal: state.modal.get('message').toJS(),
    confirmModal: state.modal.get('confirm').toJS()
  }),
  (dispatch) => ({
    BaseActions: bindActionCreators(baseActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ApiKeyRequestActions: bindActionCreators(apiKeyRequestActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(ApiKeyRequestListContainer))
