import React, { Fragment } from 'react'

import 'react-datepicker/dist/react-datepicker.css'

const Item = ({ email, company, cost, rate }) => {
  return (
    <tr>
      <td className="w-25">{email}</td>
      <td className="w-25">{company}</td>
      <td className="w-25 text-right">{cost}</td>
      <td className="w-25 text-right">{rate} %</td>
    </tr>
  )
}

const SummaryPublisher = ({ pagination, list }) => {
  return (
    <Fragment>
      <div className="table-responsive table-scroll-y">
        <table className="table table-bordered" cellSpacing="0" width="100%">
          <thead className="thead-light">
            <tr>
              <th>이메일</th>
              <th>회사</th>
              <th>수익</th>
              <th>비율</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((item) => {
                const { CustID, Email, Company, Cost, Rate } = item.toJS()
                return (
                  <Item
                    key={CustID}
                    email={Email}
                    company={Company}
                    cost={Cost}
                    rate={Rate}
                  />
                )
              })}
          </tbody>
        </table>
      </div>
      {/* <div className="d-f jc-fe mT-20">
        총 수익:{' '}
        <NumberFormat
          value={total}
          displayType="text"
          prefix="&#8361; "
          thousandSeparator={true}
        />
      </div> */}
      <div className="d-f jc-fe mT-20">{pagination}</div>
    </Fragment>
  )
}

export default SummaryPublisher
