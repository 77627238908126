import { List, Map, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'
import { find, forEach } from 'lodash'

import { adxAPI } from 'api'
import { billingType } from 'json/billingType'
import { country } from 'json/country'
import { platform } from 'json/platform'
import { product } from 'json/product'

// action type
const INIT = 'adx/INIT'

const GET_ADX_LIST = 'adx/GET_ADX_LIST'
const GET_ADX_LIST_LOADING = 'adx/GET_ADX_LIST_LOADING'
const GET_ADX_LIST_SUCCESS = 'adx/GET_ADX_LIST_SUCCESS'
const GET_ADX_LIST_FAILURE = 'adx/GET_ADX_LIST_FAILURE'

const SET_ADX = 'adx/SET_ADX'

const GET_DEFAULT = 'adx/GET_DEFAULT'
const GET_DEFAULT_LOADING = 'adx/GET_DEFAULT_LOADING'
const GET_DEFAULT_SUCCESS = 'adx/GET_DEFAULT_SUCCESS'
const GET_DEFAULT_FAILURE = 'adx/GET_DEFAULT_FAILURE'

const GET_BY_AID = 'adx/GET_BY_AID'
const GET_BY_AID_LOADING = 'adx/GET_BY_AID_LOADING'
const GET_BY_AID_SUCCESS = 'adx/GET_BY_AID_SUCCESS'
const GET_BY_AID_FAILURE = 'adx/GET_BY_AID_FAILURE'

const ADD_ADX = 'adx/ADD_ADX'
const ADD_ADX_LOADING = 'adx/ADD_ADX_LOADING'
const ADD_ADX_SUCCESS = 'adx/ADD_ADX_SUCCESS'
const ADD_ADX_FAILURE = 'adx/ADD_ADX_FAILURE'

const MODIFY_ADX = 'adx/MODIFY_ADX'
const MODIFY_ADX_LOADING = 'adx/MODIFY_ADX_LOADING'
const MODIFY_ADX_SUCCESS = 'adx/MODIFY_ADX_SUCCESS'
const MODIFY_ADX_FAILURE = 'adx/MODIFY_ADX_FAILURE'

const SET_IS_MODIFY = 'adx/SET_IS_MODIFY'

const START = 'adx/START'
const START_LOADING = 'adx/START_LOADING'
const START_SUCCESS = 'adx/START_SUCCESS'
const START_FAILURE = 'adx/START_FAILURE'

const STOP = 'adx/STOP'
const STOP_LOADING = 'adx/STOP_LOADING'
const STOP_SUCCESS = 'adx/STOP_SUCCESS'
const STOP_FAILURE = 'adx/STOP_FAILURE'

// action creator
export const init = createAction(INIT)

export const getAdxList = (params) => ({
  type: GET_ADX_LIST,
  payload: adxAPI.index(params)
})

export const setAdx = createAction(SET_ADX)

export const getDefault = () => ({
  type: GET_DEFAULT,
  payload: adxAPI.getDefault()
})

export const getByAID = (params) => ({
  type: GET_BY_AID,
  payload: adxAPI.getByAID(params)
})

export const add = (params) => ({
  type: ADD_ADX,
  payload: adxAPI.add(params)
})

export const modify = (params) => ({
  type: MODIFY_ADX,
  payload: adxAPI.modify(params)
})

export const setIsModify = createAction(SET_IS_MODIFY)

export const start = (params) => ({
  type: START,
  payload: adxAPI.start(params)
})

export const stop = (params) => ({
  type: STOP,
  payload: adxAPI.stop(params)
})

// initial state
const initialState = Map({
  error: '',
  rowCount: 0,
  list: List(),
  form: null,
  newToken: '',
  loading: false
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [GET_ADX_LIST_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_ADX_LIST_SUCCESS]: (state, action) => {
      const { error, rowCount, rows: list, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('rowCount', rowCount)
        .set('list', fromJS(list))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_ADX_LIST_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [SET_ADX]: (state, { payload: form }) => {
      return state.set('form', fromJS(form))
    },
    [GET_DEFAULT_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_DEFAULT_SUCCESS]: (state, action) => {
      const { error, rows, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('form', fromJS(rows))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_DEFAULT_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [GET_BY_AID_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_BY_AID_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      const { rows } = action.payload.data

      const adLinkCountry = JSON.parse(rows.AdLinkCountry).AdLinkCountry
      const adLinkPlatform = JSON.parse(rows.AdLinkPlatform).AdLinkPlatform
      const adLinkProduct = JSON.parse(rows.AdLinkProduct).AdLinkProduct
      // prettier-ignore
      const adLinkBillingType = JSON.parse(rows.AdLinkBillingType).AdLinkBillingType

      const arrCountry = []
      const arrPlatform = []
      const arrProduct = []
      const arrBilling = []

      forEach(adLinkCountry, (item) => {
        const obj = find(country, { value: item })
        arrCountry.push(obj)
      })

      forEach(adLinkPlatform, (item) => {
        const obj = find(platform, { value: item })
        arrPlatform.push(obj)
      })

      forEach(adLinkProduct, (item) => {
        const obj = find(product, { value: item })
        arrProduct.push(obj)
      })

      forEach(adLinkBillingType, (item) => {
        const obj = find(billingType, { value: item })
        arrBilling.push(obj)
      })

      rows.AdLinkCountry = arrCountry
      rows.AdLinkPlatform = arrPlatform
      rows.AdLinkProduct = arrProduct
      rows.AdLinkBillingType = arrBilling

      return state
        .set('error', error)
        .set('form', fromJS(rows))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_BY_AID_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [ADD_ADX_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [ADD_ADX_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [ADD_ADX_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [MODIFY_ADX_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [MODIFY_ADX_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [MODIFY_ADX_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [SET_IS_MODIFY]: (state, action) => {
      return state.set('isModify', action.payload)
    },
    [START_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [START_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [START_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [STOP_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [STOP_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [STOP_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    }
  },
  initialState
)
