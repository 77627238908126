import React from 'react'
import cx from 'classnames'

const HorizontalPlainText = ({ label, value }) => {
  return (
    <div className="form-group row">
      <label className="col-sm-2 col-form-label">{label}</label>
      <div className="col-sm-10">
        <input
          tpye="text"
          className={cx('form-control-plaintext')}
          value={value}
          readOnly
        />
      </div>
    </div>
  )
}

export default HorizontalPlainText
