import React from 'react'
import cx from 'classnames'

const HorizontalInput = ({
  input,
  id,
  type,
  label,
  placeholder,
  plaintext,
  disabled,
  helpertext,
  meta: { touched, error }
}) => {
  const formClass =
    plaintext && disabled ? 'form-control-plaintext' : 'form-control'

  return (
    <div className="form-group row">
      <label className="col-sm-2 col-form-label" htmlFor={id}>
        {label}
      </label>
      <div className="col-sm-10">
        <input
          {...input}
          id={id}
          type={type}
          className={cx(formClass, { 'bdc-red-500': touched && error })}
          placeholder={placeholder || label}
          readOnly={disabled && true}
        />
        {helpertext && (
          <small className="form-text text-muted mT-5 c-blue-900">
            {helpertext}
          </small>
        )}
        {touched &&
          (error && (
            <div className={cx('mT-5', { 'c-red-500': touched && error })}>
              {error}
            </div>
          ))}
      </div>
    </div>
  )
}

export default HorizontalInput
