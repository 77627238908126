// import NumberFormat from 'react-number-format'
import React, { Fragment } from 'react'
import cx from 'classnames'

import KPIDailyListItem from 'components/kpi/KPIDailyListItem'

const KPIAppListItem = ({
  custID,
  appID,
  name,
  dau,
  nru,
  aru,
  pubID,
  pubAppID,
  summaryWithDaily,
  getDailySummary
}) => {
  return (
    <Fragment>
      <tr
        className={cx(
          { 'bg-success': appID === pubAppID },
          { 'text-white': appID === pubAppID }
        )}
      >
        <td className="pL-30">
          {appID === pubAppID ? (
            <span className="icon-holder">
              <i className="c-white-500 ti-minus" />
            </span>
          ) : (
            <span className="icon-holder">
              <i className="c-blue-500 ti-plus" />
            </span>
          )}{' '}
          <a onClick={() => getDailySummary(custID, appID)}>{name}</a>
        </td>
        <td className="text-right">
          {/* <NumberFormat
            value={Math.floor(dau)}
            displayType="text"
            thousandSeparator={true}
          /> */}
          {dau}
        </td>
        <td className="text-right">
          {/* <NumberFormat
            value={Math.floor(nru)}
            displayType="text"
            thousandSeparator={true}
          /> */}
          {nru}
        </td>
        <td className="text-right">
          {/* <NumberFormat
            value={Math.floor(aru)}
            displayType="text"
            thousandSeparator={true}
          /> */}
          {aru}
        </td>
      </tr>
      {custID === pubID &&
        appID === pubAppID &&
        summaryWithDaily &&
        summaryWithDaily.map((item) => {
          const {
            RegistTime,
            DAU: dailyDAU,
            NRU: dailyNRU,
            ARU: dailyARU
          } = item.toJS()
          return RegistTime ? (
            <KPIDailyListItem
              key={RegistTime}
              registTime={RegistTime}
              dau={dailyDAU}
              nru={dailyNRU}
              aru={dailyARU}
            />
          ) : null
        })}
    </Fragment>
  )
}

export default KPIAppListItem
