import { List, Map, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { proAPI } from 'api'

// action type
const INIT = 'promotion/INIT'
const SET_HIDDEN_COST = 'promotion/SET_HIDDEN_COST'

const GET_PROMOTION_LIST = 'promotion/GET_PROMOTION_LIST'
const GET_PROMOTION_LIST_LOADING = 'promotion/GET_PROMOTION_LIST_LOADING'
const GET_PROMOTION_LIST_SUCCESS = 'promotion/GET_PROMOTION_LIST_SUCCESS'
const GET_PROMOTION_LIST_FAILURE = 'promotion/GET_PROMOTION_LIST_FAILURE'

const GET_BID_COST = 'promotion/GET_BID_COST'
const GET_BID_COST_LOADING = 'promotion/GET_BID_COST_LOADING'
const GET_BID_COST_SUCCESS = 'promotion/GET_BID_COST_SUCCESS'
const GET_BID_COST_FAILURE = 'promotion/GET_BID_COST_FAILURE'

// action creator
export const init = createAction(INIT)
export const setHiddenCost = createAction(SET_HIDDEN_COST)

export const getPromotionList = (params) => ({
  type: GET_PROMOTION_LIST,
  payload: proAPI.index(params)
})

export const getBidCost = () => ({
  type: GET_BID_COST,
  payload: proAPI.getBidCost()
})

// initial state
const initialState = Map({
  error: '',
  rowCount: 0,
  list: List(),
  bidCost: List(),
  newToken: '',
  loading: false
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [SET_HIDDEN_COST]: (state, { payload: { proID, hiddenCost } }) => {
      const index = state
        .get('list')
        .findIndex((item) => item.get('ProID') === Number(proID))
      return state.setIn(['list', index, 'HiddenCost'], hiddenCost)
    },
    [GET_PROMOTION_LIST_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_PROMOTION_LIST_SUCCESS]: (state, action) => {
      const { error, rowCount, rows: list, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('rowCount', rowCount)
        .set('list', fromJS(list))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_PROMOTION_LIST_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [GET_BID_COST_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_BID_COST_SUCCESS]: (state, action) => {
      const { error, rows: bidCost, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('bidCost', fromJS(bidCost))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_BID_COST_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    }
  },
  initialState
)
