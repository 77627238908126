import { Map } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

// action type
const TOGGLE_FULLSCREEN = 'base/TOGGLE_FULLSCREEN'
const TOGGLE_USER_MENU = 'base/TOGGLE_USER_MENU'
const SET_LOADING_ACTIVE = 'base/SET_LOADING_ACTIVE'
const OPEN_SIGNOUT_MODAL = 'base/OPEN_SIGNOUT_MODAL'
const CLOSE_SIGNOUT_MODAL = 'base/CLOSE_SIGNOUT_MODAL'

// action creator
export const toggleFullScreen = createAction(TOGGLE_FULLSCREEN)
export const toggleUserMenu = createAction(TOGGLE_USER_MENU)
export const setLoadingActive = createAction(SET_LOADING_ACTIVE)
export const openSignoutModal = createAction(OPEN_SIGNOUT_MODAL)
export const closeSignoutModal = createAction(CLOSE_SIGNOUT_MODAL)

// initial state
const initialState = Map({
  fullscreen: false,
  showUserMenu: false,
  loadingActive: false,
  signoutConfirm: Map({
    title: '',
    description: '',
    opend: false
  })
})

// reducer
export default handleActions(
  {
    [TOGGLE_FULLSCREEN]: (state, action) => {
      return state.set('fullscreen', !action.payload)
    },
    [TOGGLE_USER_MENU]: (state, action) => {
      return state.set('showUserMenu', !action.payload)
    },
    [SET_LOADING_ACTIVE]: (state, action) => {
      return state.set('loadingActive', action.payload)
    },
    [OPEN_SIGNOUT_MODAL]: (state, action) => {
      const { title, description } = action.payload
      return state
        .setIn(['signoutConfirm', 'title'], title)
        .setIn(['signoutConfirm', 'description'], description)
        .setIn(['signoutConfirm', 'opened'], true)
    },
    [CLOSE_SIGNOUT_MODAL]: (state) => {
      return state.setIn(['signoutConfirm', 'opened'], false)
    }
  },
  initialState
)
