import { Field, reduxForm } from 'redux-form'
import React from 'react'

import HorizontalInput from 'components/common/HorizontalInput'
import SingleSelect from 'components/common/SingleSelect'

import * as validate from 'libs/validate'
import { timezone } from 'json/timezone'

let CustomerForm = ({ handleSubmit }) => {
  return (
    <div className="mT-20">
      <div className="row">
        <div className="col-md-12">
          광고주 또는 퍼블리셔 계정을 직접 등록 할 수 있습니다.
        </div>
      </div>
      <form onSubmit={handleSubmit} className="mT-20">
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">유형</label>
          <div className="col-sm-10">
            <Field
              name="type"
              component="select"
              className="form-control"
              validate={validate.required}
            >
              <option value="ADVERTISER">광고주</option>
              <option value="PUBLISHER">퍼블리셔</option>
            </Field>
          </div>
        </div>
        <Field
          name="email"
          id="email"
          label="이메일"
          type="email"
          component={HorizontalInput}
          validate={[validate.required, validate.email]}
        />
        <Field
          name="company"
          id="company"
          label="회사"
          type="text"
          component={HorizontalInput}
          validate={validate.required}
        />
        <Field
          name="pw"
          id="pw"
          label="비밀번호"
          type="password"
          helpertext="비밀번호는 하나 이상의 숫자, 특수문자 !@#$%^*+=- 가 포함되어야 합니다."
          component={HorizontalInput}
          validate={[validate.required, validate.password]}
        />
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">타임존</label>
          <div className="col-sm-10">
            <Field
              name="timezoneName"
              placeholder="타임존"
              component={SingleSelect}
              options={timezone}
              defaultValue={timezone[212]}
              isClearable={false}
            />
          </div>
        </div>
        <div className="text-right mT-20">
          <button type="submit" className="btn btn-danger">
            등록
          </button>
        </div>
      </form>
    </div>
  )
}

CustomerForm = reduxForm({
  form: 'customerForm',
  enableReinitialize: true,
  initialValues: {
    type: 'ADVERTISER',
    timezoneName: { label: 'Asia/Seoul', value: 'Asia/Seoul' }
  }
})(CustomerForm)

export default CustomerForm
