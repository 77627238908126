import { defaultClient as axios } from 'libs/client'

const upload = async (params) => {
  const response = await axios.post('api/v1/faq/faq-upload', params)
  return response
}

export default {
  upload
}
