import { defaultClient as axios } from 'libs/client'

const index = async (params) => {
  const response = await axios.post('api/v1/app/', params)
  return response
}

const update = async (params) => {
  const response = await axios.post('api/v1/app/update', params)
  return response
}

const apiKeyRequestList = async (params) => {
  const response = await axios.post('api/v1/app/api-key-request-list', params)
  return response
}

const updateApiKeyRequest = async (params) => {
  const response = await axios.post('api/v1/app/update-api-key-request', params)
  return response
}

export default {
  index,
  update,
  apiKeyRequestList,
  updateApiKeyRequest
}
