import { List, Map, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { kpiAPI } from 'api'

// action type
const INIT = 'kpi/INIT'
const SET_CUSTID = 'kpi/SET_CUSTID'
const SET_APPID = 'kpi/SET_APPID'

const GET_SUMMARY = 'kpi/GET_SUMMARY'
const GET_SUMMARY_LOADING = 'kpi/GET_SUMMARY_LOADING'
const GET_SUMMARY_SUCCESS = 'kpi/GET_SUMMARY_SUCCESS'
const GET_SUMMARY_FAILURE = 'kpi/GET_SUMMARY_FAILURE'

const GET_APP_SUMMARY = 'kpi/GET_APP_SUMMARY'
const GET_APP_SUMMARY_LOADING = 'kpi/GET_APP_SUMMARY_LOADING'
const GET_APP_SUMMARY_SUCCESS = 'kpi/GET_APP_SUMMARY_SUCCESS'
const GET_APP_SUMMARY_FAILURE = 'kpi/GET_APP_SUMMARY_FAILURE'
const REMOVE_APP_SUMMARY = 'kpi/REMOVE_APP_SUMMARY'

const GET_DAILY_SUMMARY = 'kpi/GET_DAILY_SUMMARY'
const GET_DAILY_SUMMARY_LOADING = 'kpi/GET_DAILY_SUMMARY_LOADING'
const GET_DAILY_SUMMARY_SUCCESS = 'kpi/GET_DAILY_SUMMARY_SUCCESS'
const GET_DAILY_SUMMARY_FAILURE = 'kpi/GET_DAILY_SUMMARY_FAILURE'
const REMOVE_DAILY_SUMMARY = 'kpi/REMOVE_DAILY_SUMMARY'

// action creator
export const init = createAction(INIT)
export const setCustID = createAction(SET_CUSTID)
export const setAppID = createAction(SET_APPID)

export const getSummary = (params) => ({
  type: GET_SUMMARY,
  payload: kpiAPI.kpiSummary(params)
})

export const getAppSummary = (params) => ({
  type: GET_APP_SUMMARY,
  payload: kpiAPI.kpiAppSummary(params)
})

export const removeAppSummary = createAction(REMOVE_APP_SUMMARY)

export const getDailySummary = (params) => ({
  type: GET_DAILY_SUMMARY,
  payload: kpiAPI.kpiDailySummary(params)
})

export const removeDailySummary = createAction(REMOVE_DAILY_SUMMARY)

// initial state
const initialState = Map({
  error: '',
  rowCount: 0,
  summary: List(),
  custID: 0,
  summaryWithApp: List(),
  appID: 0,
  summaryWithDaily: List(),
  loading: false
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [SET_CUSTID]: (state, action) => {
      return state.set('custID', action.payload)
    },
    [SET_APPID]: (state, action) => {
      return state.set('appID', action.payload)
    },
    [GET_SUMMARY_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_SUMMARY_SUCCESS]: (state, action) => {
      const { error, rowCount, rows: summary, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('rowCount', rowCount)
        .set('summary', fromJS(summary))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_SUMMARY_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [GET_APP_SUMMARY_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_APP_SUMMARY_SUCCESS]: (state, action) => {
      const { error, rows: summaryWithApp, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('summaryWithApp', fromJS(summaryWithApp))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_APP_SUMMARY_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [REMOVE_APP_SUMMARY]: (state) => {
      return state
        .set('custID', 0)
        .set('summaryWithApp', List())
        .set('appID', 0)
        .set('summaryWithDaily', List())
    },
    [GET_DAILY_SUMMARY_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_DAILY_SUMMARY_SUCCESS]: (state, action) => {
      const { error, rows: summaryWithMedia, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('summaryWithDaily', fromJS(summaryWithMedia))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_DAILY_SUMMARY_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [REMOVE_DAILY_SUMMARY]: (state) => {
      return state.set('appID', 0).set('summaryWithDaily', List())
    }
  },
  initialState
)
