import React, { Component, Fragment } from 'react'

import AdxListItem from 'components/adx/AdxListItem'

class AdxList extends Component {
  render() {
    const {
      pagination,
      form,
      keyword,
      list,
      selectedID,
      handleFilter,
      handleSetForm
    } = this.props

    return (
      <Fragment>
        <div className="row jc-fe mB-10">
          <div className="col-md-2">
            <input
              type="search"
              name="keyword"
              className="form-control"
              placeholder="AdxName"
              value={keyword}
              onChange={handleFilter}
            />
          </div>
        </div>

        <div className="table-responsive table-scroll-y">
          <table
            className="table table-bordered table-fixed"
            cellSpacing="0"
            width="100%"
          >
            <thead className="thead-light">
              <tr>
                <th>AdxName</th>
                <th>AdLinkStatus</th>
                <th>TrackDomain</th>
                <th>TrackKey</th>
                <th>TrackKeyValue</th>
                <th>PostbackKeyAdxID</th>
                <th>PostbackKey</th>
                <th>AdLinkEmail</th>
                <th>AdLinkCampName</th>
                <th>Commnet</th>
              </tr>
            </thead>
            <tbody>
              {list &&
                list.map((item) => {
                  const {
                    AID,
                    AdxName,
                    AdLinkStatus,
                    TrackDomain,
                    TrackKey,
                    TrackKeyValue,
                    PostbackKeyAdxID,
                    PostbackKey,
                    AdLinkEmail,
                    AdLinkCampName,
                    Comment
                  } = item.toJS()
                  return (
                    <AdxListItem
                      key={AID}
                      AID={AID}
                      AdxName={AdxName}
                      AdLinkStatus={AdLinkStatus}
                      TrackDomain={TrackDomain}
                      TrackKey={TrackKey}
                      TrackKeyValue={TrackKeyValue}
                      PostbackKeyAdxID={PostbackKeyAdxID}
                      PostbackKey={PostbackKey}
                      AdLinkEmail={AdLinkEmail}
                      AdLinkCampName={AdLinkCampName}
                      Comment={Comment}
                      selectedID={selectedID}
                      handleSetForm={handleSetForm}
                    />
                  )
                })}
            </tbody>
          </table>
        </div>
        <div className="d-f jc-fe mT-20">{pagination}</div>
        {form}
      </Fragment>
    )
  }
}

export default AdxList
