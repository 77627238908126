import Dropzone from 'react-dropzone'
import React, { Fragment } from 'react'
import ReactQuill from 'react-quill'
import classNames from 'classnames'

import 'react-quill/dist/quill.snow.css'

const QuillTest = ({
  quillRef,
  modules,
  formats,
  contents,
  handleContents,
  dropzoneRef,
  handleDrop,
  handleSubmit
}) => {
  return (
    <Fragment>
      <ReactQuill
        theme="snow"
        ref={quillRef}
        modules={modules}
        formats={formats}
        value={contents}
        onChange={handleContents}
      />

      <Dropzone
        ref={dropzoneRef}
        accept="image/*"
        multiple={false}
        onDrop={handleDrop}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={classNames('dropzone', {
                'dropzone--isActive': isDragActive
              })}
              style={{ display: 'none' }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop files here...</p>
              ) : (
                <p>
                  Try dropping some files here, or click to select files to
                  upload.
                </p>
              )}
            </div>
          )
        }}
      </Dropzone>
      <div
        className="ql-editor"
        dangerouslySetInnerHTML={{ __html: contents }}
      />
      <div className="d-f jc-fe mT-20">
        <div className="peers gap-10">
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              등록
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default QuillTest
