import React, { Component } from 'react'

import ModalWrapper from '../ModalWrapper'

class ConfirmModal extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.open !== nextProps.open
  }

  componentWillUpdate(nextProps) {
    const { open } = nextProps
    const { listenKeyUp } = this

    if (open) {
      window.document.activeElement.blur()
      window.addEventListener('keydown', listenKeyUp)
    } else {
      window.removeEventListener('keydown', listenKeyUp)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.listenKeyUp)
  }

  listenKeyUp = (e) => {
    const { onConfirm, onCancel } = this.props
    if (e.key === 'Enter') {
      onConfirm()
    }

    if (e.key === 'Escape') {
      onCancel()
    }
  }

  render() {
    const { title, description, onConfirm, onCancel, open } = this.props

    return (
      <ModalWrapper open={open}>
        <div className="ConfirmModal">
          <div className="modal-content">
            {title && <h4>{title}</h4>}
            <p>
              {description.split('\n').map((line, index) => {
                return (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                )
              })}
            </p>
            <div className="button-area">
              <button
                type="button"
                className="btn btn-primary first"
                onClick={onConfirm}
              >
                확인
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onCancel}
              >
                취소
              </button>
            </div>
          </div>
        </div>
      </ModalWrapper>
    )
  }
}

ConfirmModal.defaultProps = {
  title: null
}

export default ConfirmModal
