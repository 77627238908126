import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React, { Component, Fragment } from 'react'
import moment from 'moment'

import Chart from 'components/home/Chart'
import MessageModal from 'components/common/MessageModal'
import Search from 'components/home/Search'
import Total from 'components/home/Total'
import Yesterday from 'components/home/Yesterday'

import * as baseActions from 'store/modules/base'
import * as homeActions from 'store/modules/home'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { delay, jsonValueByKey, loadingDelay } from 'libs/common'
import { ekey } from 'libs/enums'
import { stkeys, storage } from 'libs/storage'

class HomeContainer extends Component {
  state = {
    startDate: moment()
      .subtract(6, 'days')
      .toDate(),
    endDate: moment()
      .add(1, 'days')
      .toDate()
  }

  async componentDidMount() {
    const { HomeActions } = this.props
    HomeActions.init()

    await this.getData()
  }

  handleStartDate = (date) => {
    const { endDate } = this.state

    this.setState(
      {
        startDate: date,
        endDate
      },
      async () => {
        await this.getData()
      }
    )
  }

  handleEndDate = (date) => {
    const { startDate } = this.state

    this.setState(
      {
        startDate,
        endDate: date
      },
      async () => {
        await this.getData()
      }
    )
  }

  getData = async () => {
    const { startDate, endDate } = this.state
    const { BaseActions, HomeActions, ModalActions, MeActions } = this.props

    try {
      BaseActions.setLoadingActive(true)
      await HomeActions.getData({
        startDate,
        endDate
      })
      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      let e = error
      if (!e) {
        const { status } = err.response
        if (status === 401) {
          e = 'NEED_SIGNIN'
        }
      }

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(e)
      })
    }
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  render() {
    const { startDate, endDate } = this.state
    const { homeData, messageModal } = this.props
    const { handleStartDate, handleEndDate, handleOK } = this

    if (!homeData) {
      return null
    }

    const { yesterday, chart, total } = homeData.toJS()

    return (
      <Fragment>
        <Search
          startDate={startDate}
          endDate={endDate}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
        />

        <Yesterday data={yesterday} />

        <Chart data={chart} />

        <Total data={total} />

        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.home.get('error'),
    newToken: state.home.get('newToken'),
    homeData: state.home.get('homeData'),
    messageModal: state.modal.get('message').toJS()
  }),
  (dispatch) => ({
    BaseActions: bindActionCreators(baseActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch),
    HomeActions: bindActionCreators(homeActions, dispatch)
  })
)(withRouter(HomeContainer))
