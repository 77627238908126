import { defaultClient as axios } from 'libs/client'

const revenuePubSummary = async (params) => {
  const response = await axios.post(
    'api/v1/revenue/revenue-pub-summary',
    params
  )
  return response
}

const revenuePubAppSummary = async (params) => {
  const response = await axios.post(
    'api/v1/revenue/revenue-pub-app-summary',
    params
  )
  return response
}

const revenuePubMediaSummary = async (params) => {
  const response = await axios.post(
    'api/v1/revenue/revenue-pub-media-summary',
    params
  )
  return response
}

const revenueReportList = async (params) => {
  const response = await axios.post(
    'api/v1/revenue/revenue-report-list',
    params
  )
  return response
}

const revenueUploadTaxBill = async (params) => {
  const response = await axios.post(
    'api/v1/revenue/revenue-upload-taxbill',
    params
  )
  return response
}

const revenueCompletePayment = async (params) => {
  const response = await axios.post(
    'api/v1/revenue/revenue-complete-payment',
    params
  )
  return response
}

export default {
  revenuePubSummary,
  revenuePubAppSummary,
  revenuePubMediaSummary,
  revenueReportList,
  revenueUploadTaxBill,
  revenueCompletePayment
}
