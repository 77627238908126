// import NumberFormat from 'react-number-format'
import React, { Fragment } from 'react'
import cx from 'classnames'

import KPIAppListItem from 'components/kpi/KPIAppListItem'

const KPIListItem = ({
  custID,
  // lastName,
  // firstName,
  email,
  dau,
  nru,
  aru,
  pubID,
  summaryWithApp,
  pubAppID,
  summaryWithDaily,
  getAppSummary,
  getDailySummary
}) => {
  return (
    <Fragment>
      <tr
        className={cx(
          { 'bg-danger': custID === pubID },
          { 'text-white': custID === pubID }
        )}
      >
        <td className="ov-h whs-nw tov-e">
          {custID === pubID ? (
            <span className="jvectormap-zoomin">
              <i className="c-white-500 ti-minus" />
            </span>
          ) : (
            <span className="icon-holder">
              <i className="c-blue-500 ti-plus" />
            </span>
          )}{' '}
          <a onClick={() => getAppSummary(custID)}>{email}</a>
        </td>
        <td className="text-right">
          {/* <NumberFormat
            value={Math.floor(dau)}
            displayType="text"
            thousandSeparator={true}
          /> */}
          {dau}
        </td>
        <td className="text-right">
          {/* <NumberFormat
            value={Math.floor(nru)}
            displayType="text"
            thousandSeparator={true}
          /> */}
          {nru}
        </td>
        <td className="text-right">
          {/* <NumberFormat
            value={Math.floor(aru)}
            displayType="text"
            thousandSeparator={true}
          /> */}
          {aru}
        </td>
      </tr>
      {custID === pubID &&
        summaryWithApp &&
        summaryWithApp.map((item, index) => {
          const {
            CustID,
            AppID,
            Name,
            DAU: appDAU,
            NRU: appNRU,
            ARU: appARU
          } = item.toJS()
          return (
            <KPIAppListItem
              key={index}
              custID={CustID}
              appID={AppID}
              name={Name}
              dau={appDAU}
              nru={appNRU}
              aru={appARU}
              pubID={pubID}
              pubAppID={pubAppID}
              summaryWithDaily={summaryWithDaily}
              getDailySummary={getDailySummary}
            />
          )
        })}
    </Fragment>
  )
}

export default KPIListItem
