import { css } from '@emotion/core'
import BounceLoader from 'react-spinners/BounceLoader'
import LoadingOverlay from 'react-loading-overlay'
import React from 'react'

import HideScroll from 'components/common/HideScroll'

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`

const FullScreenLoader = ({ active }) => {
  return (
    <LoadingOverlay
      active={active}
      spinner={<BounceLoader color="#36D7B7" css={override} />}
      styles={{
        overlay: (base) => ({
          ...base,
          background: 'rgba(10, 10, 10, 0.5)',
          position: 'fixed',
          zIndex: '9999'
        })
      }}
      text="잠시만 기다려주세요"
    >
      {active && <HideScroll />}
    </LoadingOverlay>
  )
}

export default FullScreenLoader
