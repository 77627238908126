import React from 'react'
import cx from 'classnames'
import moment from 'moment'

import ReactTooltip from 'react-tooltip'

const AdxEventListItem = ({
  AEID,
  AID,
  AdxName,
  AdxID,
  Action,
  Comment,
  UpdateTime,
  RegistTime,
  selectedID,
  handleSetForm
}) => {
  return (
    <tr
      className={cx(
        'cur-p',
        { 'bg-danger': selectedID === AEID },
        { 'text-white': selectedID === AEID }
      )}
      onClick={() =>
        handleSetForm({
          AEID,
          AID,
          AdxName,
          AdxID,
          Action,
          Comment
        })
      }
    >
      <td className="ov-h whs-nw tov-e">
        <a data-for="AdxName" data-tip={AdxName}>
          {AdxName}
        </a>
        <ReactTooltip id="AdxName" place="top" type="info" effect="solid" />
      </td>
      <td className="ov-h whs-nw tov-e">
        <a data-for="AdxID" data-tip={AdxID}>
          {AdxID}
        </a>
        <ReactTooltip id="AdxID" place="top" type="info" effect="solid" />
      </td>
      <td className="ov-h whs-nw tov-e">{Action}</td>
      <td className="ov-h whs-nw tov-e">{Comment}</td>
      <td className="ov-h whs-nw tov-e">
        {moment(UpdateTime).format('YYYY-MM-DD hh:mm:ss')}
      </td>
      <td className="ov-h whs-nw tov-e">
        {moment(RegistTime).format('YYYY-MM-DD hh:mm:ss')}
      </td>
    </tr>
  )
}

export default AdxEventListItem
