import { defaultClient as axios } from 'libs/client'

const index = async (params) => {
  const response = await axios.post('api/v1/adx/', params)
  return response
}

const getByAID = async (params) => {
  const response = await axios.post('api/v1/adx/get-by-aid', params)
  return response
}

const modify = async (params) => {
  const response = await axios.post('api/v1/adx/modify', params)
  return response
}

const start = async (params) => {
  const response = await axios.post('api/v1/adx/start', params)
  return response
}

const stop = async (params) => {
  const response = await axios.post('api/v1/adx/stop', params)
  return response
}

export default {
  index,
  getByAID,
  modify,
  start,
  stop
}
