import { connect } from 'react-redux'
import React, { Component } from 'react'

import MeForm from 'components/account/me/MeForm'

class MeFormContainer extends Component {
  render() {
    const { adminID, firstName, lastName } = this.props

    return (
      <MeForm adminID={adminID} firstName={firstName} lastName={lastName} />
    )
  }
}

export default connect(
  (state) => ({
    adminID: state.me.getIn(['user', 'ID']),
    firstName: state.me.getIn(['user', 'FirstName']),
    lastName: state.me.getIn(['user', 'LastName'])
  }),
  () => ({})
)(MeFormContainer)
