import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import React from 'react'
import cx from 'classnames'

import * as validate from 'libs/validate'
import BasicInput from 'components/common/BasicInput'

const SignupForm = ({
  pristine,
  submitting,
  handleSubmit
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="id"
        id="id"
        type="text"
        label="ID"
        helpertext="영어와 숫자만 입력해주세요."
        component={BasicInput}
        validate={[validate.required, validate.alphaNumeric]}
      />
      <Field
        name="firstName"
        id="firstName"
        type="text"
        label="First Name"
        component={BasicInput}
        validate={validate.required}
      />
      <Field
        name="lastName"
        id="lastName"
        type="text"
        label="Last Name"
        component={BasicInput}
        validate={validate.required}
      />
      <Field
        name="email"
        id="email"
        type="email"
        label="Email Address"
        component={BasicInput}
        validate={[validate.required, validate.email]}
      />
      <Field
        name="pw"
        id="pw"
        type="password"
        label="Password"
        component={BasicInput}
        validate={[validate.required, validate.password]}
        helpertext="비밀번호는 하나 이상의 숫자, 특수문자 !@#$%^*+=- 가 포함되어야 합니다."
      />
      <Field
        name="pwConfirm"
        id="pwConfirm"
        type="password"
        label="Confirm Password"
        component={BasicInput}
        validate={[validate.required, validate.passwordsMatch]}
      />
      <div className="form-group">
        <div className="peers ai-c jc-sb fxw-nw">
          <div className="peer">
            <Link to="/signin">Sign-In</Link>
          </div>
          <div className="peer">
            <button
              type="submit"
              className={cx('btn', 'btn-primary')}
              disabled={pristine || submitting}
            >
              Register
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'signupForm'
})(SignupForm)
