import axios from 'axios'

// axios.defaults.withCredentials = true

const baseURL = (() => {
  // if (process.env.NODE_ENV === 'development') {
  //   return 'http://localhost:4100/'
  // }

  // stage
  // return 'http://13.125.98.250'

  // product
  // return 'http://54.180.156.249'

  return process.env.API_HOST
})()

const defaultClient = axios.create({
  baseURL
  // withCredentials: true
})

export { defaultClient }
