import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import React, { Component, Fragment } from 'react'
import debounce from 'lodash/debounce'

import CustomerForm from 'components/customer/CustomerForm'
import CustomerList from 'components/customer/CustomerList'
import CustomerTemplate from 'components/customer/CustomerTemplate'
import CustomerView from 'components/customer/CustomerView'
import MessageModal from 'components/common/MessageModal'

import * as baseActions from 'store/modules/base'
import * as customerActions from 'store/modules/customer'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { delay, jsonValueByKey, loadingDelay } from 'libs/common'
import { ekey } from 'libs/enums'
import { stkeys, storage } from 'libs/storage'

class CustomerListContainer extends Component {
  state = {
    type: '',
    keyword: '',
    pageNo: 1,
    pageSize: 10
  }

  async componentDidMount() {
    const { CustomerActions, ModalActions } = this.props

    CustomerActions.init()

    const user = storage.get(stkeys.user)
    if (user && user.Group !== 'ADMINISTRATORS') {
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: '권한이 없습니다'
      })
    } else {
      const { pageNo } = this.state
      await this.getCustomerList(pageNo)
    }
  }

  getCustomerList = async (pageNo) => {
    const { type, keyword, pageSize } = this.state
    const { BaseActions, CustomerActions, MeActions, ModalActions } = this.props

    try {
      BaseActions.setLoadingActive(true)
      await CustomerActions.getCustomerList({
        type,
        keyword,
        pageNo,
        pageSize
      })
      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      let e = error
      if (!e) {
        const { status } = err.response
        if (status === 401) {
          e = 'NEED_SIGNIN'
        }
      }

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(e)
      })
    }
  }

  handlePageChange = async (pageNo) => {
    await this.getCustomerList(pageNo)
    this.setState({
      pageNo
    })
  }

  handleChangeType = async (e) => {
    this.setState(
      {
        type: e.target.value
      },
      async () => {
        await this.getCustomerList(1)
      }
    )
  }

  debouncedFilter = debounce(async () => {
    await this.getCustomerList(1)
    this.setState({
      pageNo: 1
    })
  }, 500)

  handleFilter = (e) => {
    this.setState(
      {
        keyword: e.target.value
      },
      () => {
        this.debouncedFilter()
      }
    )
  }

  handleShowForm = (custID, type, email, company, cash) => {
    const { view, CustomerActions } = this.props

    if (view.get('custID') !== custID) {
      CustomerActions.setView({
        custID,
        type,
        email,
        company,
        cash,
        opened: true
      })

      CustomerActions.setCash('')
    } else {
      CustomerActions.setView({
        custID: 0,
        type: '',
        email: '',
        company: '',
        cash: 0,
        opened: false
      })

      CustomerActions.setCash('')
    }
  }

  handleRefresh = async () => {
    const { pageNo } = this.state
    const { CustomerActions, view } = this.props

    await this.getCustomerList(pageNo)
    if (view.get('custID') !== 0) {
      CustomerActions.getCustomer(view.get('custID'))
    }
  }

  handleCashChange = (e) => {
    const { CustomerActions } = this.props

    const val = e.target.value.replace(/[^0-9]/g, '')

    CustomerActions.setCash(val)
  }

  handleModify = async (custID) => {
    const { cash, CustomerActions, MeActions, ModalActions } = this.props

    if (cash < 100000 || cash > 100000000) {
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: '100,000 이상 ~ 100,000,000 이하로 입력해주세요.'
      })
      return
    }

    const params = {
      custID,
      cash
    }

    try {
      await CustomerActions.chargeCash(params)

      const { error } = this.props
      if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '등록되었습니다.'
        })

        const { newToken } = this.props
        if (newToken) {
          MeActions.setAccessToken(newToken)
          storage.set(stkeys.accessToken, newToken)
          axios.defaults.headers.common[config.headers.accessToken] = newToken
        }
      } else {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }
    } catch (err) {
      const { error } = this.props
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleSubmit = async (values, dispatch) => {
    const { type, email, company, pw, timezoneName } = values
    const { CustomerActions, MeActions, ModalActions } = this.props

    const params = {
      type,
      email,
      company,
      pw,
      timezoneName: timezoneName.value
    }

    try {
      await CustomerActions.add(params)
      const { error } = this.props
      if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        dispatch(reset('customerForm'))

        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '등록되었습니다.'
        })

        await this.getCustomerList(1)

        const { newToken } = this.props
        if (newToken) {
          MeActions.setAccessToken(newToken)
          storage.set(stkeys.accessToken, newToken)
          axios.defaults.headers.common[config.headers.accessToken] = newToken
        }
      } else {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }
    } catch (err) {
      const { error } = this.props
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  render() {
    const { type, keyword, pageNo, pageSize } = this.state

    const {
      rowCount,
      customers,
      view,
      opened,
      cash,
      messageModal,
      pristine,
      submitting
    } = this.props

    const {
      handlePageChange,
      handleChangeType,
      handleFilter,
      handleShowForm,
      handleRefresh,
      handleCashChange,
      handleModify,
      handleSubmit,
      handleOK
    } = this

    return (
      <Fragment>
        <CustomerTemplate
          customerList={
            <CustomerList
              pagination={
                <Pagination
                  activePage={pageNo}
                  itemsCountPerPage={pageSize}
                  totalItemsCount={rowCount}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              }
              type={type}
              keyword={keyword}
              list={customers}
              selectedID={view.get('custID')}
              handleChangeType={handleChangeType}
              handleFilter={handleFilter}
              handleShowForm={handleShowForm}
              handleRefresh={handleRefresh}
            />
          }
          customerView={
            <CustomerView
              view={view}
              opened={opened}
              updateCash={cash}
              handleCashChange={handleCashChange}
              handleModify={handleModify}
            />
          }
          customerForm={
            <CustomerForm
              pristine={pristine}
              submitting={submitting}
              onSubmit={handleSubmit}
            />
          }
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.customer.get('error'),
    rowCount: state.customer.get('rowCount'),
    customers: state.customer.get('customers'),
    view: state.customer.get('view'),
    opened: state.customer.get('opened'),
    cash: state.customer.get('cash'),
    newToken: state.customer.get('newToken'),
    user: state.me.get('user'),
    messageModal: state.modal.get('message').toJS()
  }),
  (dispatch) => ({
    BaseActions: bindActionCreators(baseActions, dispatch),
    CustomerActions: bindActionCreators(customerActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(CustomerListContainer))
