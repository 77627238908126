import React from 'react'
import SignupFormContainer from 'containers/account/SignupFormContainer'
import SignupTemplate from 'components/account/signup/SignupTemplate'

const Signup = () => {
  return (
    <SignupTemplate>
      <SignupFormContainer />
    </SignupTemplate>
  )
}

export default Signup
