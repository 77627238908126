import { connect } from 'react-redux'
import React, { Component } from 'react'

import PageTemplate from 'components/layout/PageTemplate'

class PageTemplateContainer extends Component {
  render() {
    const { fullscreen, sidebar, topbar, children } = this.props

    return (
      <PageTemplate fullscreen={fullscreen} sidebar={sidebar} topbar={topbar}>
        {children}
      </PageTemplate>
    )
  }
}

export default connect(
  (state) => ({
    fullscreen: state.base.get('fullscreen')
  }),
  () => ({})
)(PageTemplateContainer)
