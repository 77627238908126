import { connect } from 'react-redux'
import React, { Component } from 'react'

import FullScreenLoader from 'components/common/FullScreenLoader'

class FullScreenLoaderContainer extends Component {
  render() {
    const { loadingActive } = this.props

    return <FullScreenLoader active={loadingActive} />
  }
}

export default connect(
  (state) => ({
    loadingActive: state.base.get('loadingActive')
  }),
  () => ({})
)(FullScreenLoaderContainer)
