import { Helmet } from 'react-helmet'
import { Route, Switch } from 'react-router-dom'
import React, { Fragment } from 'react'

import {
  Admin,
  Adx,
  AdxEvent,
  ApiKeyRequestList,
  AppList,
  Customer,
  EthAsk,
  EthBlack,
  EthReceipt,
  FAQ,
  Home,
  KPI,
  Mail,
  Maintenance,
  Me,
  MetaData,
  Notice,
  Promotion,
  QuillTest,
  RefundPub,
  RevenuePub,
  Signin,
  Signup,
  Summary,
  TinyMCETest,
  Url,
  XplayzAddressCountList,
  XplayzTxCountList,
  XplayzUserList
} from 'pages'

import Core from 'containers/base/Core'

const App = () => {
  return (
    // <PageTemplate sidebar={<Sidebar />} header={<Header />}>
    //   aass
    // </PageTemplate>
    <Fragment>
      <Helmet>
        <title>ZENAAD-ADMIN</title>
      </Helmet>
      <Core />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/admin" component={Admin} />
        <Route path="/signin" component={Signin} />
        <Route path="/signup" component={Signup} />
        <Route path="/me" component={Me} />
        <Route path="/meta-data" component={MetaData} />
        <Route path="/revenue-pub" component={RevenuePub} />
        <Route path="/refund-pub" component={RefundPub} />
        <Route path="/eth-black" component={EthBlack} />
        <Route path="/xplayz-user" component={XplayzUserList} />
        <Route
          path="/xplayz-address-count"
          component={XplayzAddressCountList}
        />
        <Route path="/xplayz-tx-count" component={XplayzTxCountList} />
        <Route path="/eth-receipt" component={EthReceipt} />
        <Route path="/notice" component={Notice} />
        <Route path="/faq" component={FAQ} />
        <Route path="/eth-ask" component={EthAsk} />
        <Route path="/customer" component={Customer} />
        <Route path="/app" component={AppList} />
        <Route path="/api-key-request" component={ApiKeyRequestList} />
        <Route path="/maintenance" component={Maintenance} />
        <Route path="/url" component={Url} />
        <Route path="/mail" component={Mail} />
        <Route path="/promotion" component={Promotion} />
        <Route path="/kpi" component={KPI} />
        <Route path="/adx-tracking-postback" component={Adx} />
        <Route path="/adx-event" component={AdxEvent} />
        <Route path="/summary" component={Summary} />
        <Route path="/quill-test" component={QuillTest} />
        <Route path="/tiny-mce-test" component={TinyMCETest} />
      </Switch>
    </Fragment>
  )
}

export default App
