import { Map, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { homeAPI } from 'api'

// action type
const INIT = 'home/INIT'

const GET_DATA = 'home/GET_DATA'
const GET_DATA_LOADING = 'home/GET_DATA_LOADING'
const GET_DATA_SUCCESS = 'home/GET_DATA_SUCCESS'
const GET_DATA_FAILURE = 'home/GET_DATA_FAILURE'

// action creator
export const init = createAction(INIT)
export const getData = (params) => ({
  type: GET_DATA,
  payload: homeAPI.home(params)
})

// initial state
const initialState = Map({
  loading: false,
  error: '',
  newToken: '',
  homeData: null
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [GET_DATA_LOADING]: (state) => {
      return state.set('loading', true)
    },

    [GET_DATA_SUCCESS]: (state, action) => {
      const { error, newToken, homeData } = action.payload.data
      return state
        .set('loading', false)
        .set('error', error)
        .set('newToken', newToken)
        .set('homeData', fromJS(homeData))
    },
    [GET_DATA_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('loading', false).set('error', error)
    }
  },
  initialState
)
