import { List, Map, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { xplayzAPI } from 'api'

// action type
const INIT = 'xplayzuser/INIT'

const GET_USER_LIST = 'xplayzuser/GET_USER_LIST'
const GET_USER_LIST_LOADING = 'xplayzuser/GET_USER_LIST_LOADING'
const GET_USER_LIST_SUCCESS = 'xplayzuser/GET_USER_LIST_SUCCESS'
const GET_USER_LIST_FAILURE = 'xplayzuser/GET_USER_LIST_FAILURE'

// action creator
export const init = createAction(INIT)

export const getUserList = (params) => ({
  type: GET_USER_LIST,
  payload: xplayzAPI.xplayzUserList(params)
})

// initial state
const initialState = Map({
  error: '',
  keyword: '',
  rowCount: 0,
  userList: List(),
  newToken: '',
  loading: false
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [GET_USER_LIST_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_USER_LIST_SUCCESS]: (state, action) => {
      const { error, rowCount, rows: userList, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('rowCount', rowCount)
        .set('userList', fromJS(userList))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_USER_LIST_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    }
  },
  initialState
)
