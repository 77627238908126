import React from 'react'
import bg from 'assets/images/bg.jpg'
import logo from 'assets/images/logo.png'

const SignupTemplate = ({ children }) => {
  return (
    <div className="peers ai-s fxw-nw h-100vh">
      <div
        className="peer peer-greed h-100 pos-r bgr-n bgpX-c bgpY-c bgsz-cv"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="pos-a centerXY">
          <div
            className="bgc-white bdrs-50p pos-r"
            style={{ width: '120px', height: '120px' }}
          >
            <img className="pos-a centerXY" src={logo} alt="" />
          </div>
        </div>
      </div>
      <div
        className="col-12 col-md-4 peer pX-40 pY-80 h-100 bgc-white scrollable pos-r"
        style={{ minWidth: '320px' }}
      >
        <h4 className="fw-300 c-grey-900 mB-40">Signup</h4>
        {children}
      </div>
    </div>
  )
}

export default SignupTemplate
