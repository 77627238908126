import React from 'react'

import EthReceiptListContainer from 'containers/eth/EthReceiptListContainer'
import EthReceiptTemplate from 'components/ethreceipt/EthReceiptTemplate'
import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const EthReceipt = () => {
  return (
    <PageTemplateContainer sidebar={<SidebarContainer />} topbar={<TopbarContainer />}>
      <EthReceiptTemplate>
        <EthReceiptListContainer />
      </EthReceiptTemplate>
    </PageTemplateContainer>
  )
}

export default EthReceipt
