import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'

import AdxEventListItem from 'components/adx/AdxEventListItem'
import HorizontalInput from 'components/common/HorizontalInput'

import * as adxEventActions from 'store/modules/adxevent'
import * as meActions from 'store/modules/me'
import * as validate from 'libs/validate'

class AdxEventList extends Component {
  state = {
    selectedID: 0
  }

  handleSetForm = (form) => {
    this.setState({
      selectedID: form.AEID
    })

    const { AdxEventActions } = this.props
    AdxEventActions.setIsModify(true)
    AdxEventActions.setAdxEvent(form)
  }

  handleReset = () => {
    this.setState({
      selectedID: 0
    })

    const { AdxEventActions } = this.props
    AdxEventActions.setIsModify(false)
    AdxEventActions.setAdxEvent(null)
  }

  render() {
    const { selectedID } = this.state

    const {
      pagination,
      keyword,
      list,
      handleFilter,
      pristine,
      submitting,
      handleSubmit,
      isModify
    } = this.props

    const { handleSetForm, handleReset } = this

    return (
      <Fragment>
        <div className="row jc-fe mB-10">
          <div className="col-md-2">
            <input
              type="search"
              name="keyword"
              className="form-control"
              placeholder="AdxName"
              value={keyword}
              onChange={handleFilter}
            />
          </div>
        </div>

        <div className="table-responsive table-scroll-y">
          <table
            className="table table-bordered table-fixed"
            cellSpacing="0"
            width="100%"
          >
            <thead className="thead-light">
              <tr>
                <th>AdxName</th>
                <th>AdxID</th>
                <th>Action</th>
                <th>Comment</th>
                <th>UpdateTime</th>
                <th>RegistTime</th>
              </tr>
            </thead>
            <tbody>
              {list &&
                list.map((item) => {
                  const {
                    AEID,
                    AID,
                    AdxName,
                    AdxID,
                    Action,
                    Comment,
                    UpdateTime,
                    RegistTime
                  } = item.toJS()
                  return (
                    <AdxEventListItem
                      key={AEID}
                      AEID={AEID}
                      AID={AID}
                      AdxName={AdxName}
                      AdxID={AdxID}
                      Action={Action}
                      Comment={Comment}
                      UpdateTime={UpdateTime}
                      RegistTime={RegistTime}
                      selectedID={selectedID}
                      handleSetForm={handleSetForm}
                    />
                  )
                })}
            </tbody>
          </table>
        </div>
        <div className="d-f jc-fe mT-20">{pagination}</div>

        {/* <form onSubmit={handleSubmit}> */}
        <form>
          <div className="row gap-20 masonry pos-r mT-20">
            <div className="col-md-12">
              <div className="bgc-white p-10 bd">
                <h6 className="c-grey-900">API</h6>
                <div className="mT-10">
                  <Field name="AEID" component="input" type="hidden" />
                  <Field name="AID" component="input" type="hidden" />
                  <Field
                    name="AdxName"
                    label="AdxName"
                    type="text"
                    plaintext={true}
                    disabled={true}
                    component={HorizontalInput}
                    validate={validate.required}
                  />
                  <Field
                    name="AdxID"
                    label="AdxID"
                    type="text"
                    component={HorizontalInput}
                    validate={validate.required}
                  />
                  <Field
                    name="Action"
                    label="Action"
                    type="text"
                    component={HorizontalInput}
                    validate={validate.required}
                  />
                  <Field
                    name="Comment"
                    label="Comment"
                    type="text"
                    component={HorizontalInput}
                    validate={validate.required}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-f jc-fe mT-20">
            <div className="peers gap-10">
              <div>
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={handleReset}
                >
                  리셋
                </button>
              </div>
              {isModify && (
                <Fragment>
                  <div>
                    <button
                      className="btn btn-success"
                      disabled={pristine || submitting}
                      onClick={handleSubmit((values) =>
                        this.props.onSubmit({
                          ...values,
                          buttonType: 'modify'
                        })
                      )}
                    >
                      수정
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn btn-primary"
                      disabled={pristine || submitting}
                      onClick={handleSubmit((values) =>
                        this.props.onSubmit({
                          ...values,
                          buttonType: 'add'
                        })
                      )}
                    >
                      등록
                    </button>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </form>
      </Fragment>
    )
  }
}

let AdxEventReduxForm = reduxForm({
  form: 'adxEventList',
  enableReinitialize: true
})(AdxEventList)

AdxEventReduxForm = connect(
  (state) => ({
    isModify: state.adxevent.get('isModify'),
    initialValues:
      state.adxevent.get('form') && state.adxevent.get('form').toJS()
  }),
  (dispatch) => ({
    AdxEventActions: bindActionCreators(adxEventActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch)
  })
)(AdxEventReduxForm)

export default AdxEventReduxForm
