import React from 'react'

import SectionWithTitle from 'components/common/SectionWithTitle'

const MailTemplate = ({ children }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <SectionWithTitle title="메일">{children}</SectionWithTitle>
      </div>
    </div>
  )
}

export default MailTemplate
