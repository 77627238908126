import React, { Component } from 'react'

class AdminListItem extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.accept !== nextProps.accept
  }

  render() {
    const {
      id,
      firstName,
      lastName,
      email,
      accept,
      handleAccept,
      handleBlock
    } = this.props

    return (
      <tr>
        <td>{id}</td>
        <td>{firstName}</td>
        <td>{lastName}</td>
        <td>{email}</td>
        <td>
          {accept === 0 ? (
            <button
              type="button"
              className="btn btn-info btn-sm"
              onClick={() => handleAccept(id)}
            >
              수락
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-dark btn-sm"
              onClick={() => handleBlock(id)}
            >
              차단
            </button>
          )}
        </td>
      </tr>
    )
  }
}

export default AdminListItem
