import React, { Component } from 'react'
import cx from 'classnames'

import HideScrollbar from '../HideScroll'

class ModalWrapper extends Component {
  animateId = null

  state = {
    animate: false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.animate()
    }
  }

  componentWillUnmount() {
    clearTimeout(this.animateId)
  }

  animate = () => {
    this.setState({ animate: true })
    this.animateId = setTimeout(() => {
      this.setState({
        animate: false
      })
    }, 150)
  }

  render() {
    const { children, className, open } = this.props
    const { animate } = this.state

    if (!open && !animate) return null

    return (
      <div className="ModalWrapper">
        <HideScrollbar />
        <div className="dimmer" />
        <div className="center">
          <div className="modal-positioner">
            <div
              className={cx('modal-content', className, {
                appear: open,
                disappear: animate && !open
              })}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ModalWrapper
