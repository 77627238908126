import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
// import { reset } from 'redux-form'
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import debounce from 'lodash/debounce'

import AdxEventList from 'components/adx/AdxEventList'
import MessageModal from 'components/common/MessageModal'

import * as adxEventActions from 'store/modules/adxevent'
import * as baseActions from 'store/modules/base'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { delay, jsonValueByKey, loadingDelay } from 'libs/common'
import { ekey } from 'libs/enums'
import { stkeys, storage } from 'libs/storage'

class AdxEventListContainer extends Component {
  state = {
    keyword: '',
    pageNo: 1,
    pageSize: 10
  }

  async componentDidMount() {
    const { AdxEventActions, ModalActions } = this.props

    AdxEventActions.init()

    const user = storage.get(stkeys.user)
    if (user && user.Group !== 'ADMINISTRATORS') {
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: '권한이 없습니다'
      })
    } else {
      const { pageNo } = this.state
      await this.getAdxEventList(pageNo)
    }
  }

  getAdxEventList = async (pageNo) => {
    const { keyword, pageSize } = this.state
    const { AdxEventActions, BaseActions, MeActions, ModalActions } = this.props

    try {
      BaseActions.setLoadingActive(true)
      await AdxEventActions.getAdxEventList({
        keyword,
        pageNo,
        pageSize
      })
      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handlePageChange = async (pageNo) => {
    await this.getAdxEventList(pageNo)
    this.setState({
      pageNo
    })
  }

  debouncedFilter = debounce(async () => {
    await this.getAdxEventList(1)
    this.setState({
      pageNo: 1
    })
  }, 500)

  handleFilter = (e) => {
    this.setState(
      {
        keyword: e.target.value
      },
      () => {
        this.debouncedFilter()
      }
    )
  }

  handleSubmit = async (values) => {
    const { pageNo } = this.state
    const { AdxEventActions, MeActions, ModalActions } = this.props
    const { AEID, AID, AdxName, AdxID, Action, Comment, buttonType } = values

    AdxEventActions.setAdxEvent({
      AEID,
      AID,
      AdxName,
      AdxID,
      Action,
      Comment
    })

    try {
      let desc = ''
      let no = 0
      if (buttonType === 'modify') {
        desc = '수정되었습니다.'
        no = pageNo
        await AdxEventActions.modify(values)
      } else {
        desc = '등록되었습니다.'
        no = pageNo
        await AdxEventActions.add(values)
      }

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      } else {
        // dispatch(reset('adxList'))
        this.handlePageChange(no)
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: desc
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  render() {
    const { keyword, pageNo, pageSize } = this.state
    const { rowCount, list, messageModal, pristine, submitting } = this.props
    const { handlePageChange, handleFilter, handleSubmit, handleOK } = this

    return (
      <Fragment>
        <AdxEventList
          pagination={
            <Pagination
              activePage={pageNo}
              itemsCountPerPage={pageSize}
              totalItemsCount={rowCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          }
          keyword={keyword}
          list={list}
          handleFilter={handleFilter}
          pristine={pristine}
          submitting={submitting}
          onSubmit={handleSubmit}
          handlePageChange={handlePageChange}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.adxevent.get('error'),
    rowCount: state.adxevent.get('rowCount'),
    list: state.adxevent.get('list'),
    newToken: state.adxevent.get('newToken'),
    user: state.me.get('user'),
    messageModal: state.modal.get('message').toJS()
  }),
  (dispatch) => ({
    AdxEventActions: bindActionCreators(adxEventActions, dispatch),
    BaseActions: bindActionCreators(baseActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(AdxEventListContainer))
