import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import React, { Component, Fragment } from 'react'
import moment from 'moment'

import Search from 'components/summary/Search'
import SumamryTotal from 'components/summary/SummaryTotal'
import SummaryAdvertiser from 'components/summary/SummaryAdvertiser'
import SummaryAdvertiserChart from 'components/summary/SummaryAdvertiserChart'
import SummaryPublisher from 'components/summary/SummaryPublisher'
import SummaryPublisherChart from 'components/summary/SummaryPublisherChart'
import SummaryTemplate from 'components/summary/SummaryTemplate'
import SummaryUser from 'components/summary/SummaryUser'
import SummaryUserChart from 'components/summary/SummaryUserChart'

import * as baseActions from 'store/modules/base'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import * as summaryActions from 'store/modules/summary'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { delay, jsonValueByKey, loadingDelay } from 'libs/common'
import { ekey } from 'libs/enums'
import { stkeys, storage } from 'libs/storage'

class SummaryContainer extends Component {
  state = {
    startDate: moment()
      .subtract(6, 'days')
      .toDate(),
    endDate: moment()
      .add(1, 'days')
      .toDate(),

    adverPageNo: 1,
    adverPageSize: 10,

    pubPageNo: 1,
    pubPageSize: 10,

    userPageNo: 1,
    userPageSize: 10
  }

  async componentDidMount() {
    const { SummaryActions } = this.props

    SummaryActions.init()

    await this.summaryAdvertiser()
    await this.summaryPublisher()
    await this.summaryUser()
  }

  initSummary = async () => {
    await this.summaryAdvertiser()
    await this.summaryPublisher()
    await this.summaryUser()
  }

  summaryAdvertiser = async () => {
    const { startDate, endDate, adverPageNo, adverPageSize } = this.state
    const { BaseActions, MeActions, ModalActions, SummaryActions } = this.props

    try {
      BaseActions.setLoadingActive(true)
      await SummaryActions.summaryAdvertiser({
        startDate,
        endDate,
        pageNo: adverPageNo,
        pageSize: adverPageSize
      })
      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { adverError, newToken } = this.props

      if (adverError !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(adverError)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { adverError } = this.props

      BaseActions.setLoadingActive(false)

      let e = adverError
      if (!e) {
        const { status } = err.response
        if (status === 401) {
          e = 'NEED_SIGNIN'
        }
      }

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(e)
      })
    }
  }

  summaryPublisher = async () => {
    const { startDate, endDate, pubPageNo, pubPageSize } = this.state
    const { BaseActions, MeActions, ModalActions, SummaryActions } = this.props

    try {
      BaseActions.setLoadingActive(true)
      await SummaryActions.summaryPublisher({
        startDate,
        endDate,
        pageNo: pubPageNo,
        pageSize: pubPageSize
      })
      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { pubError, newToken } = this.props

      if (pubError !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(pubError)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { pubError } = this.props

      BaseActions.setLoadingActive(false)

      let e = pubError
      if (!e) {
        const { status } = err.response
        if (status === 401) {
          e = 'NEED_SIGNIN'
        }
      }

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(e)
      })
    }
  }

  summaryUser = async () => {
    const { startDate, endDate, userPageNo, userPageSize } = this.state
    const { BaseActions, MeActions, ModalActions, SummaryActions } = this.props

    try {
      BaseActions.setLoadingActive(true)
      await SummaryActions.summaryUser({
        startDate,
        endDate,
        pageNo: userPageNo,
        pageSize: userPageSize
      })
      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { userError, newToken } = this.props

      if (userError !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(userError)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { userError } = this.props

      BaseActions.setLoadingActive(false)

      let e = userError
      if (!e) {
        const { status } = err.response
        if (status === 401) {
          e = 'NEED_SIGNIN'
        }
      }

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(e)
      })
    }
  }

  handleStartDate = (date) => {
    this.setState(
      {
        adverPageNo: 1,
        pubPageNo: 1,
        userPageNo: 1,
        startDate: date
      },
      async () => {
        await this.summaryAdvertiser()
        await this.summaryPublisher()
        await this.summaryUser()
      }
    )
  }

  handleEndDate = (date) => {
    this.setState(
      {
        adverPageNo: 1,
        pubPageNo: 1,
        userPageNo: 1,
        endDate: date
      },
      async () => {
        await this.summaryAdvertiser()
        await this.summaryPublisher()
        await this.summaryUser()
      }
    )
  }

  handleAdverPageChange = async (adverPageNo) => {
    this.setState(
      {
        adverPageNo
      },
      async () => {
        this.summaryAdvertiser()
      }
    )
  }

  handlePubPageChange = async (pubPageNo) => {
    this.setState(
      {
        pubPageNo
      },
      async () => {
        this.summaryPublisher()
      }
    )
  }

  handleUserPageChange = async (userPageNo) => {
    this.setState(
      {
        userPageNo
      },
      async () => {
        this.summaryUser()
      }
    )
  }

  render() {
    const {
      startDate,
      endDate,
      adverPageNo,
      adverPageSize,
      pubPageNo,
      pubPageSize,
      userPageNo,
      userPageSize
    } = this.state
    const {
      adverRowCount,
      adverList,
      adverTotal,
      adverChart,
      pubRowCount,
      pubList,
      pubTotal,
      pubChart,
      userRowCount,
      userList,
      userTotal,
      userChart
    } = this.props
    const {
      handleAdverPageChange,
      handlePubPageChange,
      handleUserPageChange,
      handleStartDate,
      handleEndDate
    } = this

    return (
      <Fragment>
        <SummaryTemplate
          search={
            <Search
              startDate={startDate}
              endDate={endDate}
              handleStartDate={handleStartDate}
              handleEndDate={handleEndDate}
            />
          }
          summaryTotal={
            <SumamryTotal
              adver={adverTotal}
              pub={pubTotal}
              user={userTotal}
              platform={
                Number(adverTotal) - (Number(pubTotal) + Number(userTotal))
              }
            />
          }
          summaryAdvertiserChart={<SummaryAdvertiserChart data={adverChart} />}
          summaryAdvertiser={
            <SummaryAdvertiser
              pagination={
                <Pagination
                  activePage={adverPageNo}
                  itemsCountPerPage={adverPageSize}
                  totalItemsCount={adverRowCount}
                  pageRangeDisplayed={5}
                  onChange={handleAdverPageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              }
              list={adverList}
            />
          }
          summaryPublisherChart={<SummaryPublisherChart data={pubChart} />}
          summaryPublisher={
            <SummaryPublisher
              pagination={
                <Pagination
                  activePage={pubPageNo}
                  itemsCountPerPage={pubPageSize}
                  totalItemsCount={pubRowCount}
                  pageRangeDisplayed={5}
                  onChange={handlePubPageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              }
              list={pubList}
            />
          }
          summaryUserChart={<SummaryUserChart data={userChart} />}
          summaryUser={
            <SummaryUser
              pagination={
                <Pagination
                  activePage={userPageNo}
                  itemsCountPerPage={userPageSize}
                  totalItemsCount={userRowCount}
                  pageRangeDisplayed={5}
                  onChange={handleUserPageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              }
              list={userList}
            />
          }
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    startDate: state.summary.get('startDate'),
    endDate: state.summary.get('startDate'),

    adverError: state.summary.getIn(['adver', 'error']),
    adverRowCount: state.summary.getIn(['adver', 'rowCount']),
    adverList: state.summary.getIn(['adver', 'list']),
    adverTotal: state.summary.getIn(['adver', 'total']),
    adverChart: state.summary.getIn(['adver', 'chart']).toJS(),
    adverNewToken: state.summary.getIn(['adver', 'newToken']),
    adverLoading: state.summary.getIn(['adver', 'loading']),

    pubError: state.summary.getIn(['pub', 'error']),
    pubRowCount: state.summary.getIn(['pub', 'rowCount']),
    pubList: state.summary.getIn(['pub', 'list']),
    pubTotal: state.summary.getIn(['pub', 'total']),
    pubChart: state.summary.getIn(['pub', 'chart']).toJS(),
    pubNewToken: state.summary.getIn(['pub', 'newToken']),
    pubLoading: state.summary.getIn(['pub', 'loading']),

    userError: state.summary.getIn(['user', 'error']),
    userRowCount: state.summary.getIn(['user', 'rowCount']),
    userList: state.summary.getIn(['user', 'list']),
    userTotal: state.summary.getIn(['user', 'total']),
    userChart: state.summary.getIn(['user', 'chart']).toJS(),
    userNewToken: state.summary.getIn(['user', 'newToken']),
    userLoading: state.summary.getIn(['user', 'loading']),

    user: state.me.get('user'),
    messageModal: state.modal.get('message').toJS()
  }),
  (dispatch) => ({
    BaseActions: bindActionCreators(baseActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch),
    SummaryActions: bindActionCreators(summaryActions, dispatch)
  })
)(withRouter(SummaryContainer))
