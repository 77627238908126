import { defaultClient as axios } from 'libs/client'

const index = async (params) => {
  const response = await axios.post('api/v1/metadata/', params)
  return response
}

const updateYieldPub = async (params) => {
  const response = await axios.post('api/v1/metadata/update-yield-pub', params)
  return response
}

const getEth = async () => {
  const response = await axios.post('api/v1/metadata/get-eth')
  return response
}

const updateEth = async (params) => {
  const response = await axios.post('api/v1/metadata/update-eth', params)
  return response
}

export default {
  index,
  updateYieldPub,
  getEth,
  updateEth
}
