import { combineReducers } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'
import admin from './admin'
import adx from './adx'
import adxevent from './adxevent'
import apikeyrequest from './apikeyrequest'
import app from './app'
import auth from './auth'
import base from './base'
import customer from './customer'
import ethask from './ethask'
import ethblack from './ethblack'
import ethreceipt from './ethreceipt'
import faq from './faq'
import home from './home'
import kpi from './kpi'
import mail from './mail'
import maintenance from './maintenance'
import me from './me'
import metadata from './metadata'
import modal from './modal'
import notice from './notice'
import pager from './pager'
import promotion from './promotion'
import refundpub from './refundpub'
import register from './register'
import revenuepub from './revenuepub'
import summary from './summary'
import url from './url'
import xplayzaddress from './xplayzaddress'
import xplayztx from './xplayztx'
import xplayzuser from './xplayzuser'

export default combineReducers({
  form: reduxFormReducer,
  admin,
  adx,
  adxevent,
  apikeyrequest,
  app,
  auth,
  base,
  customer,
  ethask,
  ethblack,
  ethreceipt,
  faq,
  home,
  kpi,
  mail,
  maintenance,
  me,
  metadata,
  modal,
  notice,
  pager,
  promotion,
  refundpub,
  register,
  revenuepub,
  summary,
  url,
  xplayzaddress,
  xplayztx,
  xplayzuser
})
