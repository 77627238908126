import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import React, { Component, Fragment } from 'react'
import debounce from 'lodash/debounce'
import moment from 'moment'

import KPIList from 'components/kpi/KPIList'
import MessageModal from 'components/common/MessageModal'

import * as baseActions from 'store/modules/base'
import * as kpiActions from 'store/modules/kpi'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { delay, jsonValueByKey, loadingDelay } from 'libs/common'
import { ekey } from 'libs/enums'
import { stkeys, storage } from 'libs/storage'

class KPIContainer extends Component {
  state = {
    startDate: moment()
      .subtract(6, 'days')
      .toDate(),
    endDate: moment()
      .add(1, 'days')
      .toDate(),
    keyword: '',
    pageNo: 1,
    pageSize: 10
  }

  async componentDidMount() {
    const { pageNo } = this.state
    const { KPIActions } = this.props

    KPIActions.init()

    await this.getSummary(pageNo)
  }

  getSummary = async (pageNo) => {
    const { startDate, endDate, keyword, pageSize } = this.state
    const { BaseActions, KPIActions, MeActions, ModalActions } = this.props

    try {
      KPIActions.setCustID(0)
      KPIActions.setAppID(0)

      BaseActions.setLoadingActive(true)
      await KPIActions.getSummary({
        startDate,
        endDate,
        keyword,
        pageNo,
        pageSize
      })
      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      let e = error
      if (!e) {
        const { status } = err.response
        if (status === 401) {
          e = 'NEED_SIGNIN'
        }
      }

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(e)
      })
    }
  }

  getAppSummary = async (pubID) => {
    const { startDate, endDate } = this.state
    const { custID, KPIActions, MeActions, ModalActions } = this.props

    KPIActions.setCustID(pubID)

    if (custID === pubID) {
      KPIActions.removeAppSummary()
    } else {
      try {
        await KPIActions.getAppSummary({
          pubID,
          startDate,
          endDate
        })

        const { error, newToken } = this.props

        if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
          ModalActions.openMessageModal({
            title: 'ZENAAD',
            description: jsonValueByKey(error)
          })
        }

        if (newToken) {
          MeActions.setAccessToken(newToken)
          storage.set(stkeys.accessToken, newToken)
          axios.defaults.headers.common[config.headers.accessToken] = newToken
        }
      } catch (err) {
        const { error } = this.props
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }
    }
  }

  getDailySummary = async (pubID, pubAppID) => {
    const { startDate, endDate } = this.state

    const { custID, appID, KPIActions, MeActions, ModalActions } = this.props

    KPIActions.setAppID(pubAppID)

    if (custID === pubID && appID === pubAppID) {
      KPIActions.removeDailySummary()
    } else {
      try {
        await KPIActions.getDailySummary({
          pubID,
          appID: pubAppID,
          startDate,
          endDate
        })

        const { error, newToken } = this.props

        if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
          ModalActions.openMessageModal({
            title: 'ZENAAD',
            description: jsonValueByKey(error)
          })
        }

        if (newToken) {
          MeActions.setAccessToken(newToken)
          storage.set(stkeys.accessToken, newToken)
          axios.defaults.headers.common[config.headers.accessToken] = newToken
        }
      } catch (err) {
        const { error } = this.props
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }
    }
  }

  handlePageChange = async (pageNo) => {
    await this.getSummary(pageNo)
    this.setState({
      pageNo
    })
  }

  handleStartDate = (date) => {
    this.setState(
      {
        startDate: date
      },
      async () => {
        await this.getSummary(1)
      }
    )
  }

  handleEndDate = (date) => {
    this.setState(
      {
        endDate: date
      },
      async () => {
        await this.getSummary(1)
      }
    )
  }

  debouncedFilter = debounce(async () => {
    await this.getSummary(1)
    this.setState({
      pageNo: 1
    })
  }, 500)

  handleFilter = (e) => {
    this.setState(
      {
        keyword: e.target.value
      },
      () => {
        this.debouncedFilter()
      }
    )
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  render() {
    const { startDate, endDate, keyword, pageNo, pageSize } = this.state
    const {
      rowCount,
      summary,
      custID,
      summaryWithApp,
      appID,
      summaryWithDaily,
      messageModal
    } = this.props
    const {
      getAppSummary,
      getDailySummary,
      handlePageChange,
      handleStartDate,
      handleEndDate,
      handleFilter,
      handleOK
    } = this

    return (
      <Fragment>
        <KPIList
          pagination={
            <Pagination
              activePage={pageNo}
              itemsCountPerPage={pageSize}
              totalItemsCount={rowCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          }
          startDate={startDate}
          endDate={endDate}
          keyword={keyword}
          summary={summary}
          custID={custID}
          summaryWithApp={summaryWithApp}
          appID={appID}
          summaryWithDaily={summaryWithDaily}
          getAppSummary={getAppSummary}
          getDailySummary={getDailySummary}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
          handleFilter={handleFilter}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.kpi.get('error'),
    rowCount: state.kpi.get('rowCount'),
    summary: state.kpi.get('summary'),
    custID: state.kpi.get('custID'),
    summaryWithApp: state.kpi.get('summaryWithApp'),
    appID: state.kpi.get('appID'),
    summaryWithDaily: state.kpi.get('summaryWithDaily'),
    newToken: state.kpi.get('newToken'),
    user: state.me.get('user'),
    messageModal: state.modal.get('message').toJS()
  }),
  (dispatch) => ({
    BaseActions: bindActionCreators(baseActions, dispatch),
    KPIActions: bindActionCreators(kpiActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(KPIContainer))
