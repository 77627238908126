import React from 'react'

import ApiKeyRequestListContainer from 'containers/app/ApiKeyRequestListContainer'
import ApiKeyRequestTemplate from 'components/apikeyrequest/ApiKeyRequestTemplate'
import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const ApiKeyRequestList = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <ApiKeyRequestTemplate>
        <ApiKeyRequestListContainer />
      </ApiKeyRequestTemplate>
    </PageTemplateContainer>
  )
}

export default ApiKeyRequestList
