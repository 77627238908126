import React from 'react'

import TinyMCETestTemplate from 'components/tinymce/TinyMCETestTemplate'

import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TinyMCETestContainer from 'containers/tinymce/TinyMCETestContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const TinyMCETest = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <TinyMCETestTemplate>
        <TinyMCETestContainer />
      </TinyMCETestTemplate>
    </PageTemplateContainer>
  )
}

export default TinyMCETest
