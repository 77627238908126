import React from 'react'
import cx from 'classnames'

import ReactTooltip from 'react-tooltip'

const AdxListItem = ({
  AID,
  AdxName,
  AdLinkStatus,
  TrackDomain,
  TrackKey,
  TrackKeyValue,
  PostbackKeyAdxID,
  PostbackKey,
  AdLinkEmail,
  AdLinkCampName,
  Comment,
  selectedID,
  handleSetForm
}) => {
  return (
    <tr
      className={cx(
        'cur-p',
        { 'bg-danger': selectedID === AID },
        { 'text-white': selectedID === AID }
      )}
      onClick={() => handleSetForm(AID)}
    >
      <td className="ov-h whs-nw tov-e">
        <a data-for="AdxName" data-tip={AdxName}>
          {AdxName}
        </a>
        <ReactTooltip id="AdxName" place="top" type="info" effect="solid" />
      </td>
      <td>{AdLinkStatus === 'WAITING' ? '중지' : '시작'}</td>
      <td className="ov-h whs-nw tov-e">
        <a data-for="TrackDomain" data-tip={TrackDomain}>
          {TrackDomain}
        </a>
        <ReactTooltip id="TrackDomain" place="top" type="info" effect="solid" />
      </td>
      <td className="ov-h whs-nw tov-e">
        <a data-for="TrackKey" data-tip={TrackKey}>
          {TrackKey}
        </a>
        <ReactTooltip id="TrackKey" place="top" type="info" effect="solid" />
      </td>
      <td className="ov-h whs-nw tov-e">
        <a data-for="TrackKeyValue" data-tip={TrackKeyValue}>
          {TrackKeyValue}
        </a>
        <ReactTooltip
          id="TrackKeyValue"
          place="top"
          type="info"
          effect="solid"
        />
      </td>
      <td className="ov-h whs-nw tov-e">
        <a data-for="PostbackKeyAdxID" data-tip={PostbackKeyAdxID}>
          {PostbackKeyAdxID}
        </a>
        <ReactTooltip
          id="PostbackKeyAdxID"
          place="top"
          type="info"
          effect="solid"
        />
      </td>
      <td className="ov-h whs-nw tov-e">
        <a data-for="PostbackKey" data-tip={PostbackKey}>
          {PostbackKey}
        </a>
        <ReactTooltip id="PostbackKey" place="top" type="info" effect="solid" />
      </td>
      <td className="ov-h whs-nw tov-e">
        <a data-for="AdLinkEmail" data-tip={AdLinkEmail}>
          {AdLinkEmail}
        </a>
        <ReactTooltip id="AdLinkEmail" place="top" type="info" effect="solid" />
      </td>
      <td className="ov-h whs-nw tov-e">
        <a data-for="AdLinkCampName" data-tip={AdLinkCampName}>
          {AdLinkCampName}
        </a>
        <ReactTooltip
          id="AdLinkCampName"
          place="top"
          type="info"
          effect="solid"
        />
      </td>
      <td className="ov-h whs-nw tov-e">
        <a data-for="Comment" data-tip={Comment}>
          {Comment}
        </a>
        <ReactTooltip id="Comment" place="top" type="info" effect="solid" />
      </td>
    </tr>
  )
}

export default AdxListItem
