import { readAmount } from '@xpla.kitchen/utils'
import React, { Fragment } from 'react'
import moment from 'moment'

const XplayzUserListItem = ({
  adid,
  platform,
  address,
  point,
  claimCount,
  registTime
}) => {
  return (
    <tr>
      <td>{adid}</td>
      <td>{platform}</td>
      <td>{address}</td>
      <td className="text-right">{readAmount(point, { decimals: 18 })}</td>
      <td className="text-right">{claimCount}</td>
      <td>{moment(registTime).format('YYYY-MM-DD hh:mm:ss')}</td>
    </tr>
  )
}

const XplayzUserList = ({ pagination, keyword, list, handleFilter }) => {
  return (
    <Fragment>
      <div className="row justify-content-md-end mB-10">
        <div className="col-md-4">
          <input
            type="search"
            name="keyword"
            className="form-control"
            placeholder="ADID or ADDRESS"
            value={keyword}
            onChange={handleFilter}
          />
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th>ADID</th>
              <th>플랫폼</th>
              <th>Address</th>
              <th>XPLA</th>
              <th>클레임</th>
              <th>지갑 생성 시간</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((item) => {
                const {
                  ADID,
                  Platform,
                  Address,
                  Point,
                  ClaimCount,
                  RegistTime
                } = item.toJS()
                return (
                  <XplayzUserListItem
                    key={ADID}
                    adid={ADID}
                    platform={Platform}
                    address={Address}
                    point={Point}
                    claimCount={ClaimCount}
                    registTime={RegistTime}
                  />
                )
              })}
          </tbody>
        </table>
      </div>
      <div className="d-f jc-fe">{pagination}</div>
    </Fragment>
  )
}

export default XplayzUserList
