import { applyMiddleware, compose, createStore } from 'redux'
import ReduxThunk from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'

import modules from './modules'

const isDev = process.env.NODE_ENV === 'development'

const customizedPromiseMiddleware = promiseMiddleware({
  promiseTypeSuffixes: ['LOADING', 'SUCCESS', 'FAILURE']
})

const devTools = isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
const composeEnhancers = devTools || compose
const middlewares = [ReduxThunk, customizedPromiseMiddleware]

const configure = () => {
  const store = createStore(
    modules,
    composeEnhancers(applyMiddleware(...middlewares))
  )

  return store
}

export default configure
