import React, { Fragment } from 'react'

import MetaDataListItem from 'components/metadata/MetaDataListItem'

const MetaDataList = ({
  pagination,
  keyword,
  list,
  handleFilter,
  handleChange,
  handleModify
}) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          수익율은 0~90% 까지 설정 가능합니다.(유저 수익율 10% 고정)
        </div>
      </div>
      <div className="row justify-content-md-end mB-10">
        <div className="col-md-2">
          <input
            type="search"
            name="keyword"
            className="form-control"
            placeholder="이메일, 회사"
            value={keyword}
            onChange={handleFilter}
          />
        </div>
      </div>

      <div className="table-responsive table-scroll-y">
        <table
          className="table table-bordered table-fixed"
          cellSpacing="0"
          width="100%"
        >
          <thead className="thead-light">
            <tr>
              <th className="w-40">이메일</th>
              <th className="w-40">회사</th>
              <th className="w-20">수익율</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((item) => {
                const { ID, Email, Company, Value } = item.toJS()
                return (
                  <MetaDataListItem
                    key={ID}
                    id={ID}
                    email={Email}
                    company={Company}
                    value={Value}
                    handleChange={handleChange}
                    handleModify={handleModify}
                  />
                )
              })}
          </tbody>
        </table>
      </div>
      <div className="d-f jc-fe mT-10">{pagination}</div>
    </Fragment>
  )
}

export default MetaDataList
