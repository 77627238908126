import { defaultClient as axios } from 'libs/client'

const send = async (params) => {
  const response = await axios.post('api/v1/mail/mail-send', params)
  return response
}

const answerSend = async (params) => {
  const response = await axios.post('api/v1/mail/answer-send', params)
  return response
}

export default {
  send,
  answerSend
}
