import DatePicker from 'react-datepicker'
import NumberFormat from 'react-number-format'
import React, { Fragment } from 'react'

const XplayzTxCountListItem = ({ registDate, txCount }) => {
  return (
    <tr>
      <td>{registDate}</td>
      <td className="text-right">
        <NumberFormat
          value={txCount}
          displayType="text"
          thousandSeparator={true}
        />
      </td>
    </tr>
  )
}

const XplayzTxCountList = ({
  list,
  startDate,
  endDate,
  handleStartDate,
  handleEndDate
}) => {
  return (
    <Fragment>
      <div className="row justify-content-md-end mB-10">
        <div className="col-md-2">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            className="form-control"
            placeholderText="시작일"
            selected={startDate}
            name="startDate"
            onChange={handleStartDate}
          />
        </div>
        <div className="col-md-2">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            className="form-control"
            placeholderText="종료일"
            selected={endDate}
            name="endDate"
            onChange={handleEndDate}
          />
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th>날짜</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((item) => {
                const { RegistDate, TxCount } = item.toJS()
                return (
                  <XplayzTxCountListItem
                    key={RegistDate}
                    registDate={RegistDate}
                    txCount={TxCount}
                  />
                )
              })}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

export default XplayzTxCountList
