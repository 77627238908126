import { Map } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { accountAPI } from 'api'
// import { stkeys, storage } from 'libs/storage'

// action type
const SIGNIN = 'auth/SIGNIN'
const SIGNIN_LOADING = 'auth/SIGNIN_LOADING'
const SIGNIN_SUCCESS = 'auth/SIGNIN_SUCCESS'
const SIGNIN_FAILURE = 'auth/SIGNIN_FAILURE'

const REFRESH_TOKEN = 'auth/REFRESH_TOKEN'
const REFRESH_TOKEN_LOADING = 'auth/REFRESH_TOKEN_LOADING'
const REFRESH_TOKEN_SUCCESS = 'auth/REFRESH_TOKEN_SUCCESS'
const REFRESH_TOKEN_FAILURE = 'auth/REFRESH_TOKEN_FAILURE'

const IS_AUTH = 'auth/IS_AUTH'
const SIGNOUT = 'auth/SIGNOUT'

// action creator
export const signin = (params) => ({
  type: SIGNIN,
  payload: accountAPI.signin(params)
})

export const refresh = () => ({
  type: REFRESH_TOKEN,
  payload: accountAPI.refreshToken()
})

export const isAuth = createAction(IS_AUTH)
export const signout = createAction(SIGNOUT)

// initial state
const initialState = Map({
  error: '',
  user: null,
  accessToken: '',
  refreshToken: '',
  isAuth: false,
  loading: false
})

// reducer
export default handleActions(
  {
    [SIGNIN_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [SIGNIN_SUCCESS]: (state, action) => {
      const {
        error,
        rows: user,
        accessToken,
        refreshToken
      } = action.payload.data

      return state
        .set('error', error)
        .set('user', user)
        .set('accessToken', accessToken)
        .set('refreshToken', refreshToken)
        .set('loading', false)
    },
    [SIGNIN_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [REFRESH_TOKEN_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [REFRESH_TOKEN_SUCCESS]: (state, action) => {
      const { error, accessToken, refreshToken } = action.payload.data

      return state
        .set('error', error)
        .set('accessToken', accessToken)
        .set('refreshToken', refreshToken)
        .set('loading', false)
    },
    [REFRESH_TOKEN_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [IS_AUTH]: (state, action) => {
      return state.set('isAuth', action.payload)
    },
    [SIGNOUT]: () => {}
  },
  initialState
)
