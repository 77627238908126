import { Editor } from '@tinymce/tinymce-react'
import React, { Fragment } from 'react'
import hljs from 'highlight.js'
import javascript from 'highlight.js/lib/languages/javascript'

import 'highlight.js/styles/vs.css'

hljs.registerLanguage('javascript', javascript)

const TinyMCETest = ({
  nodeRef,
  contents,
  handleContents,
  handleUploadImage,
  handleSubmit
}) => {
  return (
    <Fragment>
      <Editor
        apiKey="y8h90xsfw55qmhgdwc7l0uanhxv5tmnqh4mqa021s32hggkk"
        initialValue={contents}
        init={{
          height: 500,
          menubar: true,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount hr codesample'
          ],
          toolbar: `undo redo | formatselect | fontselect fontsizeselect | bold italic hr forecolor backcolor |
             alignleft aligncenter alignright alignjustify |
             bullist numlist outdent indent | link image media table | codesample | removeformat | code | help`,
          codesample_languages: [
            { text: 'HTML/XML', value: 'xml' },
            { text: 'JavaScript', value: 'javascript' },
            { text: 'Objective-C', value: 'objectivec' },
            { text: 'Java', value: 'java' },
            { text: 'C#', value: 'csharp' },
            { text: 'CPP', value: 'cpp' }
          ],
          images_upload_handler: handleUploadImage
        }}
        onEditorChange={handleContents}
      />
      <div ref={nodeRef} dangerouslySetInnerHTML={{ __html: contents }} />
      <div className="d-f jc-fe mT-20">
        <div className="peers gap-10">
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              등록
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default TinyMCETest
