import { List, Map, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { summaryAPI } from 'api'

// action type
const INIT = 'summary/INIT'

const SET_START_DATE = 'summary/SET_START_DATE'
const SET_END_DATE = 'summary/SET_END_DATE'

const SUMMARY_ADVERTISER = 'summary/SUMMARY_ADVERTISER'
const SUMMARY_ADVERTISER_LOADING = 'summary/SUMMARY_ADVERTISER_LOADING'
const SUMMARY_ADVERTISER_SUCCESS = 'summary/SUMMARY_ADVERTISER_SUCCESS'
const SUMMARY_ADVERTISER_FAILURE = 'summary/SUMMARY_ADVERTISER_FAILURE'

const SUMMARY_PUBLISHER = 'summary/SUMMARY_PUBLISHER'
const SUMMARY_PUBLISHER_LOADING = 'summary/SUMMARY_PUBLISHER_LOADING'
const SUMMARY_PUBLISHER_SUCCESS = 'summary/SUMMARY_PUBLISHER_SUCCESS'
const SUMMARY_PUBLISHER_FAILURE = 'summary/SUMMARY_PUBLISHER_FAILURE'

const SUMMARY_USER = 'summary/SUMMARY_USER'
const SUMMARY_USER_LOADING = 'summary/SUMMARY_USER_LOADING'
const SUMMARY_USER_SUCCESS = 'summary/SUMMARY_USER_SUCCESS'
const SUMMARY_USER_FAILURE = 'summary/SUMMARY_USER_FAILURE'

// action creator
export const init = createAction(INIT)
export const setStartDate = createAction(SET_START_DATE)
export const setEndDate = createAction(SET_END_DATE)

export const summaryAdvertiser = (params) => ({
  type: SUMMARY_ADVERTISER,
  payload: summaryAPI.summaryAdvertiser(params)
})

export const summaryPublisher = (params) => ({
  type: SUMMARY_PUBLISHER,
  payload: summaryAPI.summaryPublisher(params)
})

export const summaryUser = (params) => ({
  type: SUMMARY_USER,
  payload: summaryAPI.summaryUser(params)
})

// initial state
const initialState = Map({
  startDate: null,
  endDate: null,

  adver: Map({
    error: '',
    rowCount: 0,
    list: List(),
    total: 0,
    chart: List(),
    newToken: '',
    loading: false
  }),

  pub: Map({
    error: '',
    rowCount: 0,
    list: List(),
    total: 0,
    chart: List(),
    newToken: '',
    loading: false
  }),

  user: Map({
    error: '',
    rowCount: 0,
    list: List(),
    total: 0,
    chart: List(),
    newToken: '',
    loading: false
  })
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [SET_START_DATE]: (state, action) => {
      return state.set('startDate', action.payload)
    },
    [SET_END_DATE]: (state, action) => {
      return state.set('endDate', action.payload)
    },

    [SUMMARY_ADVERTISER_LOADING]: (state) => {
      return state.setIn(['adver', 'loading'], true)
    },
    [SUMMARY_ADVERTISER_SUCCESS]: (state, action) => {
      const {
        error,
        rowCount,
        rows: list,
        total,
        chart,
        newToken
      } = action.payload.data
      return state
        .setIn(['adver', 'error'], error)
        .setIn(['adver', 'rowCount'], rowCount)
        .setIn(['adver', 'list'], fromJS(list))
        .setIn(['adver', 'total'], total)
        .setIn(['adver', 'chart'], fromJS(chart))
        .setIn(['adver', 'newToken'], newToken)
        .setIn(['adver', 'loading'], false)
    },
    [SUMMARY_ADVERTISER_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state
        .setIn(['adver', 'error'], error)
        .set(['adver', 'loading'], false)
    },

    [SUMMARY_PUBLISHER_LOADING]: (state) => {
      return state.setIn(['pub', 'loading'], true)
    },
    [SUMMARY_PUBLISHER_SUCCESS]: (state, action) => {
      const {
        error,
        rowCount,
        rows: list,
        total,
        chart,
        newToken
      } = action.payload.data
      return state
        .setIn(['pub', 'error'], error)
        .setIn(['pub', 'rowCount'], rowCount)
        .setIn(['pub', 'list'], fromJS(list))
        .setIn(['pub', 'total'], total)
        .setIn(['pub', 'chart'], fromJS(chart))
        .setIn(['pub', 'newToken'], newToken)
        .setIn(['pub', 'loading'], false)
    },
    [SUMMARY_PUBLISHER_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.setIn(['pub', 'error'], error).set(['pub', 'loading'], false)
    },

    [SUMMARY_USER_LOADING]: (state) => {
      return state.setIn(['user', 'loading'], true)
    },
    [SUMMARY_USER_SUCCESS]: (state, action) => {
      const {
        error,
        rowCount,
        rows: list,
        total,
        chart,
        newToken
      } = action.payload.data
      return state
        .setIn(['user', 'error'], error)
        .setIn(['user', 'rowCount'], rowCount)
        .setIn(['user', 'list'], fromJS(list))
        .setIn(['user', 'total'], total)
        .setIn(['user', 'chart'], fromJS(chart))
        .setIn(['user', 'newToken'], newToken)
        .setIn(['user', 'loading'], false)
    },
    [SUMMARY_USER_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state
        .setIn(['user', 'error'], error)
        .set(['user', 'loading'], false)
    }
  },
  initialState
)
