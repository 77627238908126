import { Field, reduxForm } from 'redux-form'
import React from 'react'

import HorizontalInput from 'components/common/HorizontalInput'
import HorizontalTextArea from 'components/common/HorizontalTextArea'

import * as validate from 'libs/validate'

const EthAskMailForm = ({ opened, pristine, submitting, handleSubmit }) => {
  const openClass = opened ? 'd-b' : 'd-n'

  return (
    <form className={openClass} onSubmit={handleSubmit}>
      <div className="mT-40 mB-40">
        <hr className="bgc-deep-purple-500" />
      </div>

      <Field
        name="subject"
        label="제목"
        type="text"
        component={HorizontalInput}
        validate={validate.required}
      />

      <Field
        name="content"
        label="내용"
        rows="10"
        component={HorizontalTextArea}
        validate={validate.required}
      />

      <div className="text-right mT-20">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={pristine || submitting}
        >
          전송
        </button>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'ethAskMailForm',
  enableReinitialize: true,
  initialValues: {
    type: ''
  }
})(EthAskMailForm)
