import { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as authActions from 'store/modules/auth'
import * as meActions from 'store/modules/me'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { stkeys, storage } from 'libs/storage'
import { tokenExpired } from 'libs/common'

class UserContainer extends Component {
  componentDidMount() {
    this.checkUser()
  }

  checkUser = async () => {
    const { AuthActions, MeActions, location, history } = this.props

    const user = storage.get(stkeys.user)
    const accessToken = storage.get(stkeys.accessToken)

    if (user && accessToken) {
      const accessTokenExpired = tokenExpired(accessToken)

      if (accessTokenExpired) {
        AuthActions.isAuth(false)

        storage.clear()

        if (location.pathname !== '/signin') {
          history.push('/signin')
        }
      } else {
        MeActions.setUser({
          user,
          accessToken
        })

        axios.defaults.headers.common[config.headers.adminID] = user.ID
        axios.defaults.headers.common[config.headers.sessionKey] =
          user.SessionKey
        axios.defaults.headers.common[config.headers.accessToken] = accessToken

        AuthActions.isAuth(true)
      }
    } else {
      AuthActions.isAuth(false)

      storage.clear()

      if (location.pathname !== '/signin') {
        history.push('/signin')
      }
    }
  }

  render() {
    return null
  }
}

export default connect(
  (state) => ({
    error: state.auth.get('error'),
    accessToken: state.auth.get('accessToken')
  }),
  (dispatch) => ({
    AuthActions: bindActionCreators(authActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch)
  })
)(withRouter(UserContainer))
