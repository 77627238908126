import DatePicker from 'react-datepicker'
import React, { Fragment } from 'react'
import cx from 'classnames'
import moment from 'moment'

import 'react-datepicker/dist/react-datepicker.css'

const EthAskListItem = ({
  id,
  adid,
  email,
  title,
  content,
  response,
  registTime,
  handleShowForm,
  selectedID
}) => {
  let responseText = ''
  switch (response) {
    case 'WAITING':
      responseText = '대기중'
      break
    case 'COMPLETE':
      responseText = '처리됨'
      break
    case 'HOLD':
      responseText = '보류됨'
      break
    default:
      break
  }
  return (
    <tr
      className={cx(
        'cur-p',
        { 'bg-danger': selectedID === id },
        { 'text-white': selectedID === id }
      )}
      onClick={() => handleShowForm(id, adid, email, title, content, response)}
    >
      <td>{adid}</td>
      <td>{email}</td>
      <td>{title}</td>
      <td>{responseText}</td>
      <td>{moment(registTime).format('YYYY-MM-DD hh:mm:ss')}</td>
    </tr>
  )
}

const EthAskList = ({
  pagination,
  form,
  mailForm,
  startDate,
  endDate,
  response,
  keyword,
  list,
  selectedID,
  handleStartDate,
  handleEndDate,
  handleResponse,
  handleFilter,
  handleShowForm
}) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">문의 항목을 선택하면 답변 가능합니다.</div>
      </div>
      <div className="row jc-fe mB-10">
        <div className="col-md-2">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            className="form-control"
            placeholderText="시작일"
            selected={startDate}
            name="startDate"
            onChange={handleStartDate}
          />
        </div>
        <div className="col-md-2">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            className="form-control"
            placeholderText="종료일"
            selected={endDate}
            name="endDate"
            onChange={handleEndDate}
          />
        </div>
        <div className="col-md-1 d-f ai-c jc-fe">
          <div>상태: </div>
        </div>
        <div className="col-md-2">
          <select
            onChange={handleResponse}
            value={response}
            className="form-control"
          >
            <option value="">전체</option>
            <option value="WAITING">대기중</option>
            <option value="COMPLETE">처리됨</option>
            <option value="HOLD">보류됨</option>
          </select>
        </div>
        <div className="col-md-2">
          <input
            type="search"
            name="keyword"
            className="form-control"
            placeholder="ADID, 이메일"
            value={keyword}
            onChange={handleFilter}
          />
        </div>
      </div>

      <div className="table-responsive table-scroll-y">
        <table className="table table-bordered" cellSpacing="0" width="100%">
          <thead className="thead-light">
            <tr>
              <th className="w-30">ADID</th>
              <th className="w-20">이메일</th>
              <th className="w-20">제목</th>
              <th className="w-10">상태</th>
              <th className="w-20">등록시간</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((item) => {
                const {
                  ID,
                  ADID,
                  Email,
                  Title,
                  Content,
                  Response,
                  RegistTime
                } = item.toJS()
                return (
                  <EthAskListItem
                    key={ID}
                    id={ID}
                    adid={ADID}
                    email={Email}
                    title={Title}
                    content={Content}
                    response={Response}
                    registTime={RegistTime}
                    handleShowForm={handleShowForm}
                    selectedID={selectedID}
                  />
                )
              })}
          </tbody>
        </table>
      </div>
      <div className="d-f jc-fe mT-10">{pagination}</div>
      {form}
      {mailForm}
    </Fragment>
  )
}

export default EthAskList
