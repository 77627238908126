import React from 'react'

import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import PromotionListContainer from 'containers/promotion/PromotionListContainer'
import PromotionTemplate from 'components/promotion/PromotionTemplate'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const Notice = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <PromotionTemplate>
        <PromotionListContainer />
      </PromotionTemplate>
    </PageTemplateContainer>
  )
}

export default Notice
