import React from 'react'

const MetaDataListItem = ({
  id,
  email,
  company,
  value,
  handleChange,
  handleModify
}) => {
  return (
    <tr>
      <td className="ov-h whs-nw tov-e">{email}</td>
      <td className="ov-h whs-nw tov-e">{company}</td>
      <td className="text-right">
        <div className="form-inline">
          <input
            type="text"
            name={`value-${id}`}
            className="form-control"
            placeholder="수익율"
            value={value}
            onChange={handleChange}
          />{' '}
          %{' '}
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => handleModify(id, value)}
          >
            수정
          </button>
        </div>
      </td>
    </tr>
  )
}

export default MetaDataListItem
