import React from 'react'

import AdxListContainer from 'containers/adx/AdxListContainer'
import AdxTemplate from 'components/adx/AdxTemplate'
import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const AdxList = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <AdxTemplate>
        <AdxListContainer />
      </AdxTemplate>
    </PageTemplateContainer>
  )
}

export default AdxList
