import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React, { Component, Fragment } from 'react'
import debounce from 'lodash/debounce'

import MessageModal from 'components/common/MessageModal'
import Pagination from 'react-js-pagination'
import TrafficLog from 'components/ethask/TrafficLog'

import * as baseActions from 'store/modules/base'
import * as ethAskActions from 'store/modules/ethask'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { delay, jsonValueByKey, loadingDelay } from 'libs/common'
import { ekey } from 'libs/enums'
import { stkeys, storage } from 'libs/storage'

class EthAskListContainer extends Component {
  state = {
    keyword: '',
    pageNo: 1,
    pageSize: 10
  }

  async componentDidMount() {
    const { pageNo } = this.state
    await this.getTrafficLog(pageNo)
  }

  getTrafficLog = async (pageNo) => {
    const { keyword, pageSize } = this.state
    const { BaseActions, EthAskActions, MeActions, ModalActions } = this.props

    try {
      BaseActions.setLoadingActive(true)
      await EthAskActions.getTrafficLog({
        keyword,
        pageNo,
        pageSize
      })
      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      let e = error
      if (!e) {
        const { status } = err.response
        if (status === 401) {
          e = 'NEED_SIGNIN'
        }
      }

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(e)
      })
    }
  }

  handlePageChange = async (pageNo) => {
    await this.getTrafficLog(pageNo)
    this.setState({
      pageNo
    })
  }

  debouncedFilter = debounce(async () => {
    await this.getTrafficLog(1)
  }, 500)

  handleFilter = (e) => {
    this.setState(
      {
        keyword: e.target.value
      },
      () => {
        this.debouncedFilter()
      }
    )
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  render() {
    const { keyword, pageNo, pageSize } = this.state
    const { rowCount, trafficLog, messageModal } = this.props
    const { handlePageChange, handleFilter, handleOK } = this

    return (
      <Fragment>
        <TrafficLog
          pagination={
            <Pagination
              activePage={pageNo}
              itemsCountPerPage={pageSize}
              totalItemsCount={rowCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          }
          keyword={keyword}
          list={trafficLog}
          handleFilter={handleFilter}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.ethask.get('error'),
    rowCount: state.ethask.get('rowCount2'),
    trafficLog: state.ethask.get('trafficLog'),
    newToken: state.ethask.get('newToken'),
    user: state.me.get('user'),
    messageModal: state.modal.get('message').toJS()
  }),
  (dispatch) => ({
    BaseActions: bindActionCreators(baseActions, dispatch),
    EthAskActions: bindActionCreators(ethAskActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(EthAskListContainer))
