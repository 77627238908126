import React from 'react'

const ApiKeyRequestListItem = ({
  requestID,
  appID,
  platform,
  name,
  handleModify
}) => {
  return (
    <tr>
      <td>{requestID}</td>
      <td>{appID}</td>
      <td>{platform}</td>
      <td>{name}</td>
      <td className="text-center">
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => handleModify(appID, 1)}
          >
            승인
          </button>
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={() => handleModify(appID, -1)}
          >
            반려
          </button>
        </div>
      </td>
    </tr>
  )
}

export default ApiKeyRequestListItem
