import { Map } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { accountAPI } from 'api'
// import { adtier0 } from 'enums/adtier0'
// import { ekey } from 'libs/enums'

// action type
const INITIALIZE = 'register/INITIALIZE'
const SIGNUP = 'register/SIGNUP'
const SIGNUP_LOADING = 'register/SIGNUP_LOADING'
const SIGNUP_SUCCESS = 'register/SIGNUP_SUCCESS'
const SIGNUP_FAILURE = 'register/SIGNUP_FAILURE'

// action creator
export const initialize = createAction(INITIALIZE)

export const signup = (params) => ({
  type: SIGNUP,
  payload: accountAPI.signup(params)
})

// initial state
const initialState = Map({
  error: '',
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  pw: '',
  pwConfirm: '',
  loading: false
})

// reducer
export default handleActions(
  {
    [INITIALIZE]: () => initialState,
    [SIGNUP_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [SIGNUP_SUCCESS]: (state, action) => {
      const { error } = action.payload.data
      return state.set('error', error).set('loading', false)
    },
    [SIGNUP_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    }
  },
  initialState
)
