import React, { Component, Fragment } from 'react'

import FullScreenLoaderContainer from 'containers/base/FullScreenLoaderContainer'
import UserContainer from 'containers/base/UserContainer'

class Core extends Component {
  render() {
    return (
      <Fragment>
        <FullScreenLoaderContainer />
        <UserContainer />
      </Fragment>
    )
  }
}

export default Core
