import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React, { Component, Fragment } from 'react'
import moment from 'moment'

import MessageModal from 'components/common/MessageModal'
import XplayzTxCountList from 'components/xplayztx/XplayzTxCountList'

import * as baseActions from 'store/modules/base'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import * as xplayzTxActions from 'store/modules/xplayztx'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { delay, jsonValueByKey, loadingDelay } from 'libs/common'
import { ekey } from 'libs/enums'
import { stkeys, storage } from 'libs/storage'

class XplayzAddressCountListContainer extends Component {
  state = {
    startDate: moment()
      .subtract(6, 'days')
      .toDate(),
    endDate: moment()
      .add(1, 'days')
      .toDate()
  }

  async componentDidMount() {
    const { XplayzTxActions } = this.props

    XplayzTxActions.init()

    await this.getList()
  }

  getList = async () => {
    const { startDate, endDate } = this.state

    const { BaseActions, XplayzTxActions, MeActions, ModalActions } = this.props

    try {
      BaseActions.setLoadingActive(true)
      await XplayzTxActions.getList({
        startDate,
        endDate
      })

      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      let e = error
      if (!e) {
        const { status } = err.response
        if (status === 401) {
          e = 'NEED_SIGNIN'
        }
      }

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(e)
      })
    }
  }

  handleStartDate = (date) => {
    this.setState(
      {
        startDate: date
      },
      async () => {
        await this.getList()
      }
    )
  }

  handleEndDate = (date) => {
    this.setState(
      {
        endDate: date
      },
      async () => {
        await this.getList()
      }
    )
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  render() {
    const { startDate, endDate } = this.state
    const { list, messageModal } = this.props
    const { handleStartDate, handleEndDate, handleOK } = this

    return (
      <Fragment>
        <XplayzTxCountList
          startDate={startDate}
          endDate={endDate}
          list={list}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.xplayztx.get('error'),
    list: state.xplayztx.get('list'),
    newToken: state.xplayztx.get('newToken'),
    user: state.me.get('user'),
    messageModal: state.modal.get('message').toJS(),
    confirmModal: state.modal.get('confirm').toJS()
  }),
  (dispatch) => ({
    BaseActions: bindActionCreators(baseActions, dispatch),
    XplayzTxActions: bindActionCreators(xplayzTxActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(XplayzAddressCountListContainer))
