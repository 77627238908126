import { defaultClient as axios } from 'libs/client'

const signup = async (params) => {
  const response = await axios.post('api/v1/account/signup', params)
  return response
}

const signin = async (params) => {
  const response = await axios.post('api/v1/account/signin', params)
  return response
}

const updateemail = async (params) => {
  const response = await axios.post('api/v1/account/update-email', params)
  return response
}

const updatepw = async (params) => {
  const response = await axios.post('api/v1/account/update-pw', params)
  return response
}

const refreshToken = async () => {
  const response = await axios.post('api/v1/account/refreshToken')
  return response
}

export default {
  signup,
  signin,
  updateemail,
  updatepw,
  refreshToken
}
