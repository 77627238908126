import { defaultClient as axios } from 'libs/client'

const xplayzUserList = async (params) => {
  const response = await axios.post('api/v1/xplayz/xplayz-user-list', params)
  return response
}

const xplayzAddressCountList = async (params) => {
  const response = await axios.post(
    'api/v1/xplayz/xplayz-address-count-list',
    params
  )
  return response
}

const xplayzTxCountList = async (params) => {
  const response = await axios.post(
    'api/v1/xplayz/xplayz-tx-count-list',
    params
  )
  return response
}

export default {
  xplayzUserList,
  xplayzAddressCountList,
  xplayzTxCountList
}
