import { List, Map, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { custAPI } from 'api'

// action type
const INIT = 'customer/INIT'

const GET_CUSTOMER_LIST = 'customer/GET_CUSTOMER_LIST'
const GET_CUSTOMER_LIST_LOADING = 'customer/GET_CUSTOMER_LIST_LOADING'
const GET_CUSTOMER_LIST_SUCCESS = 'customer/GET_CUSTOMER_LIST_SUCCESS'
const GET_CUSTOMER_LIST_FAILURE = 'customer/GET_CUSTOMER_LIST_FAILURE'

const SET_VIEW = 'customer/SET_VIEW'
const SET_CASH = 'customer/SET_CASH'

const CHARGE_CASH = 'customer/CHARGE_CASH'
const CHARGE_CASH_LOADING = 'customer/CHARGE_CASH_LOADING'
const CHARGE_CASH_SUCCESS = 'customer/CHARGE_CASH_SUCCESS'
const CHARGE_CASH_FAILURE = 'customer/CHARGE_CASH_FAILURE'

const ADD = 'customer/ADD'
const ADD_LOADING = 'customer/ADD_LOADING'
const ADD_SUCCESS = 'customer/ADD_SUCCESS'
const ADD_FAILURE = 'customer/ADD_FAILURE'

const GET_CUSTOMER = 'customer/GET_CUSTOMER'

// action creator
export const init = createAction(INIT)

export const getCustomerList = (params) => ({
  type: GET_CUSTOMER_LIST,
  payload: custAPI.index(params)
})

export const setView = createAction(SET_VIEW)
export const setCash = createAction(SET_CASH)

export const chargeCash = (params) => ({
  type: CHARGE_CASH,
  payload: custAPI.chargeCash(params)
})

export const add = (params) => ({
  type: ADD,
  payload: custAPI.add(params)
})

export const getCustomer = createAction(GET_CUSTOMER)

// initial state
const initialState = Map({
  error: '',
  rowCount: 0,
  customers: List(),
  view: Map({
    custID: 0,
    type: '',
    email: '',
    company: '',
    cash: 0
  }),
  cash: '',
  opened: false,
  newToken: '',
  loading: false
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [GET_CUSTOMER_LIST_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_CUSTOMER_LIST_SUCCESS]: (state, action) => {
      const { error, rowCount, rows: customers, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('rowCount', rowCount)
        .set('customers', fromJS(customers))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_CUSTOMER_LIST_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [SET_VIEW]: (state, action) => {
      const { custID, type, email, company, cash, opened } = action.payload

      const view = {
        custID,
        type,
        email,
        company,
        cash
      }

      return state.set('view', fromJS(view)).set('opened', opened)
    },
    [SET_CASH]: (state, action) => {
      return state.set('cash', action.payload)
    },
    [CHARGE_CASH_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [CHARGE_CASH_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [CHARGE_CASH_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [ADD_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [ADD_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [ADD_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [GET_CUSTOMER]: (state, action) => {
      const custID = action.payload

      const index = state
        .get('customers')
        .findIndex((item) => item.get('CustID') === custID)

      const customer = state.getIn(['customers', index])

      const view = {
        custID: customer.get('CustID'),
        type: customer.get('Type'),
        email: customer.get('Email'),
        company: customer.get('Company'),
        cash: customer.get('Cash')
      }

      return state.set('view', fromJS(view)).set('opened', true)
    }
  },
  initialState
)
