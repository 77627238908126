import React from 'react'
import moment from 'moment'

import HorizontalPlainText from 'components/common/HorizontalPlainText'

const AppForm = ({
  form,
  opened,
  handleApiKey,
  handleActive,
  handleUpdate
}) => {
  const {
    appID,
    email,
    company,
    platform,
    name,
    packageName,
    apiKey,
    apiKeyStatus,
    active,
    registTime
  } = form.toJS()
  const openClass = opened ? 'd-b' : 'd-n'

  return (
    <div className={openClass}>
      <HorizontalPlainText label="이메일: " value={email} />
      <HorizontalPlainText label="회사: " value={company} />
      <HorizontalPlainText label="플랫폼: " value={platform} />
      <HorizontalPlainText label="앱: " value={name} />
      <HorizontalPlainText label="스토어 주소: " value={packageName} />

      {apiKey && (
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">API KEY 사용여부</label>
          <div className="col-sm-10">
            <select
              className="form-control"
              onChange={handleApiKey}
              value={`${apiKey}|${apiKeyStatus}`}
            >
              <option value={`${apiKey}|1`}>예</option>
              <option value={`${apiKey}|0`}>아니오</option>
            </select>
          </div>
        </div>
      )}

      <div className="form-group row">
        <label className="col-sm-2 col-form-label">승인</label>
        <div className="col-sm-10">
          <select
            className="form-control"
            onChange={handleActive}
            value={active}
          >
            <option value="1">예</option>
            <option value="0">아니오</option>
          </select>
        </div>
      </div>
      <HorizontalPlainText
        label="등록시간: "
        value={moment(registTime).format('YYYY-MM-DD hh:mm:ss')}
      />

      <div className="text-right mT-20">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleUpdate(appID)}
        >
          확인
        </button>
      </div>
    </div>
  )
}

export default AppForm
