import React from 'react'

import AdxEventListContainer from 'containers/adx/AdxEventListContainer'

import AdxEventTemplate from 'components/adx/AdxEventTemplate'
import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const AdxEventList = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <AdxEventTemplate>
        <AdxEventListContainer />
      </AdxEventTemplate>
    </PageTemplateContainer>
  )
}

export default AdxEventList
