import { List, Map, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { metadataAPI } from 'api'

// action type
const INIT = 'metadata/INIT'

const GET_LIST = 'metadata/GET_LIST'
const GET_LIST_LOADING = 'metadata/GET_LIST_LOADING'
const GET_LIST_SUCCESS = 'metadata/GET_LIST_SUCCESS'
const GET_LIST_FAILURE = 'metadata/GET_LIST_FAILURE'

const SET_VALUE = 'metadata/SET_VALUE'

const UPDATE_YIELD_PUB = 'metadata/UPDATE_YIELD_PUB'
const UPDATE_YIELD_PUB_LOADING = 'metadata/UPDATE_YIELD_PUB_LOADING'
const UPDATE_YIELD_PUB_SUCCESS = 'metadata/UPDATE_YIELD_PUB_SUCCESS'
const UPDATE_YIELD_PUB_FAILURE = 'metadata/UPDATE_YIELD_PUB_FAILURE'

const GET_ETH = 'metadata/GET_ETH'
const GET_ETH_LOADING = 'metadata/GET_ETH_LOADING'
const GET_ETH_SUCCESS = 'metadata/GET_ETH_SUCCESS'
const GET_ETH_FAILURE = 'metadata/GET_ETH_FAILURE'

const SET_PRIVATE_KEY = 'metadata/SET_PRIVATE_KEY'

const UPDATE_ETH = 'metadata/UPDATE_ETH'
const UPDATE_ETH_LOADING = 'metadata/UPDATE_ETH_LOADING'
const UPDATE_ETH_SUCCESS = 'metadata/UPDATE_ETH_SUCCESS'
const UPDATE_ETH_FAILURE = 'metadata/UPDATE_ETH_FAILURE'

// action creator
export const init = createAction(INIT)

export const getList = (params) => ({
  type: GET_LIST,
  payload: metadataAPI.index(params)
})

export const setValue = createAction(SET_VALUE, (value) => value)

export const updateYieldPub = (params) => ({
  type: UPDATE_YIELD_PUB,
  payload: metadataAPI.updateYieldPub(params)
})

export const getEth = () => ({
  type: GET_ETH,
  payload: metadataAPI.getEth()
})

export const setPrivateKey = createAction(SET_PRIVATE_KEY)

export const updateEth = (params) => ({
  type: UPDATE_ETH,
  payload: metadataAPI.updateEth(params)
})

// initial state
const initialState = Map({
  error: '',
  rowCount: 0,
  list: List(),
  newToken: '',
  loading: false,
  eth: null
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [GET_LIST_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_LIST_SUCCESS]: (state, action) => {
      const { error, rowCount, rows: list, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('rowCount', rowCount)
        .set('list', fromJS(list))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_LIST_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [SET_VALUE]: (state, { payload: { id, value } }) => {
      const index = state
        .get('list')
        .findIndex((item) => item.get('ID') === Number(id))
      return state.setIn(['list', index, 'Value'], value)
    },
    [UPDATE_YIELD_PUB_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [UPDATE_YIELD_PUB_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [UPDATE_YIELD_PUB_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [GET_ETH_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_ETH_SUCCESS]: (state, action) => {
      const { error, rows: eth, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('eth', Map(eth))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [SET_PRIVATE_KEY]: (state) => {
      return state.setIn(['eth', 'PrivKey'], '****')
    },
    [GET_ETH_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [UPDATE_ETH_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [UPDATE_ETH_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [UPDATE_ETH_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    }
  },
  initialState
)
