import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import React, { Component } from 'react'

import HorizontalInput from 'components/common/HorizontalInput'

import * as meActions from 'store/modules/me'
import * as validate from 'libs/validate'

class EmailForm extends Component {
  componentDidMount() {
    const { getUser } = this.props
    getUser()
  }

  render() {
    const { pristine, submitting, handleSubmit } = this.props

    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="Email"
          id="Email"
          type="text"
          label="이메일"
          component={HorizontalInput}
          validate={[validate.required, validate.email]}
        />
        <div className="text-right mT-20">
          <button
            type="submit"
            className="btn btn-danger"
            disabled={pristine || submitting}
          >
            수정
          </button>
        </div>
      </form>
    )
  }
}

let EmailReduxForm = reduxForm({
  form: 'emailForm',
  enableReinitialize: true
})(EmailForm)

EmailReduxForm = connect(
  (state) => ({
    initialValues: state.me.get('user') && state.me.get('user').toJS()
  }),
  (dispatch) => ({
    getUser: () => dispatch(meActions.getUser)
  })
)(EmailReduxForm)

export default EmailReduxForm
