import NumberFormat from 'react-number-format'
import React, { Fragment } from 'react'

import { adtier0 } from 'enums/adtier0'
import { ekey } from 'libs/enums'

import 'react-datepicker/dist/react-datepicker.css'

const PromotionListItem = ({
  proID,
  email,
  company,
  campName,
  proName,
  product,
  billingType,
  status,
  campType,
  impression,
  click,
  ctr,
  install,
  // action,
  avgCost,
  hiddenCost,
  handleChangeHiddenCost,
  handleModifyHiddenCost
}) => {
  let bannerName = ''
  if (ekey(adtier0.PRODUCT, adtier0.PRODUCT.BANNER) === product) {
    bannerName = '배너'
  } else if (ekey(adtier0.PRODUCT, adtier0.PRODUCT.INTERSTITIAL) === product) {
    bannerName = '전면'
  } else {
    bannerName = '비디오'
  }

  let statusName = ''
  // prettier-ignore
  if (ekey(adtier0.PROMOTION_STATUS, adtier0.PROMOTION_STATUS.FIRST) === status || ekey(adtier0.PROMOTION_STATUS, adtier0.PROMOTION_STATUS.WAITING) === status) {
    statusName = '대기'
  // prettier-ignore
  } else if (ekey(adtier0.PROMOTION_STATUS, adtier0.PROMOTION_STATUS.INPROGRESS) === status) {
    statusName = '진행'
  } else {
    statusName = '완료'
  }

  // let campTypeName = ''
  // if (campType === 'AOS') {
  //   campTypeName = 'Android'
  // } else {
  //   campTypeName = 'iOS'
  // }

  return (
    <tr>
      <td>{email}</td>
      <td>{company}</td>
      <td>{campName}</td>
      <td>{proName}</td>
      <td>{campType}</td>
      <td>{bannerName}</td>
      <td>{billingType}</td>
      <td>{statusName}</td>
      <td className="text-right">
        <NumberFormat
          value={Math.floor(impression)}
          displayType="text"
          thousandSeparator={true}
        />
      </td>
      <td className="text-right">
        <NumberFormat
          value={Math.floor(click)}
          displayType="text"
          thousandSeparator={true}
        />
      </td>
      <td className="text-right">{ctr} %</td>
      <td className="text-right">
        <NumberFormat
          value={Math.floor(install)}
          displayType="text"
          thousandSeparator={true}
        />
      </td>
      {/* <td className="text-right">
        <NumberFormat
          value={Math.floor(action)}
          displayType="text"
          thousandSeparator={true}
        />
      </td> */}
      <td className="text-right">
        <NumberFormat
          value={Math.floor(avgCost)}
          displayType="text"
          prefix="&#8361; "
          thousandSeparator={true}
        />
      </td>
      <td>
        <div className="form-inline">
          <input
            type="text"
            name={`hiddenCost-${proID}`}
            className="form-control"
            placeholder="수익율"
            value={hiddenCost}
            onChange={handleChangeHiddenCost}
            style={{ width: '100px' }}
          />
          &nbsp;&nbsp;
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => handleModifyHiddenCost(proID, hiddenCost)}
          >
            수정
          </button>
        </div>
      </td>
    </tr>
  )
}

const PromotionList = ({
  pagination,
  product,
  billingType,
  status,
  productOptions,
  billingTypeOptions,
  statusOptions,
  keyword,
  list,
  bidCost,
  showBid,
  handleChangeProduct,
  handleChangeBillingType,
  handleChangeStatus,
  handleFilter,
  handleShowBid,
  handleChangeHiddenCost,
  handleModifyHiddenCost
}) => {
  return (
    <Fragment>
      <div className="row justify-content-md-end mB-10">
        <div
          className="col-md-2 c-red-900 text-right cur-p"
          onClick={handleShowBid}
        >
          최근 낙찰가
        </div>
      </div>
      <div className={showBid ? 'd-b' : 'd-n'}>
        <div className="row justify-content-md-end mB-10">
          <div className="col-md-2 c-black-900 text-right">AOS</div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered" cellSpacing="0" width="100%">
            <thead className="thead-light">
              <tr>
                <th>광고</th>
                <th>과금</th>
                <th>낙찰가</th>
              </tr>
            </thead>
            <tbody>
              {bidCost &&
                bidCost
                  .filter((item) => item.get('CampType') === 'AOS')
                  .map((item, index) => {
                    const { Product, BillingType, Value } = item.toJS()

                    let productName = ''
                    if (
                      ekey(adtier0.PRODUCT, adtier0.PRODUCT.BANNER) === Product
                    ) {
                      productName = '배너'
                    } else if (
                      ekey(adtier0.PRODUCT, adtier0.PRODUCT.INTERSTITIAL) ===
                      Product
                    ) {
                      productName = '전면'
                    } else {
                      productName = '비디오'
                    }
                    return (
                      <tr key={index}>
                        <td>{productName}</td>
                        <td>{BillingType}</td>
                        <td>
                          <NumberFormat
                            value={Math.floor(Value)}
                            displayType="text"
                            prefix="&#8361; "
                            thousandSeparator={true}
                          />
                        </td>
                      </tr>
                    )
                  })}
            </tbody>
          </table>
        </div>

        <div className="row justify-content-md-end mB-10">
          <div className="col-md-2 c-black-900 text-right">IOS</div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered" cellSpacing="0" width="100%">
            <thead className="thead-light">
              <tr>
                <th>광고</th>
                <th>과금</th>
                <th>낙찰가</th>
              </tr>
            </thead>
            <tbody>
              {bidCost &&
                bidCost
                  .filter((item) => item.get('CampType') === 'IOS')
                  .map((item, index) => {
                    const { Product, BillingType, Value } = item.toJS()

                    let productName = ''
                    if (
                      ekey(adtier0.PRODUCT, adtier0.PRODUCT.BANNER) === Product
                    ) {
                      productName = '배너'
                    } else if (
                      ekey(adtier0.PRODUCT, adtier0.PRODUCT.INTERSTITIAL) ===
                      Product
                    ) {
                      productName = '전면'
                    } else {
                      productName = '비디오'
                    }
                    return (
                      <tr key={index}>
                        <td>{productName}</td>
                        <td>{BillingType}</td>
                        <td>
                          <NumberFormat
                            value={Math.floor(Value)}
                            displayType="text"
                            prefix="&#8361; "
                            thousandSeparator={true}
                          />
                        </td>
                      </tr>
                    )
                  })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row justify-content-md-end mB-10">
        <div className="col-md-2 text-right c-orange-900">
          마스터키: wpskdoemaktmxjzl
        </div>
      </div>
      <div className="row justify-content-md-end mB-10">
        <div className="col-md-1 d-f ai-c jc-fe">
          <div>광고: </div>
        </div>
        <div className="col-md-2">
          <select
            className="form-control"
            onChange={handleChangeProduct}
            value={product}
          >
            <option value="">전체</option>
            {productOptions.map((obj, index) => {
              return (
                <option key={index} value={obj.key}>
                  {obj.text}
                </option>
              )
            })}
          </select>
        </div>
        <div className="col-md-1 d-f ai-c jc-fe">
          <div>과금: </div>
        </div>
        <div className="col-md-2">
          <select
            className="form-control"
            onChange={handleChangeBillingType}
            value={billingType}
          >
            <option value="">전체</option>
            {billingTypeOptions.map((obj, index) => {
              return (
                <option key={index} value={obj.key}>
                  {obj.key}
                </option>
              )
            })}
          </select>
        </div>
        <div className="col-md-1 d-f ai-c jc-fe">
          <div>상태: </div>
        </div>
        <div className="col-md-2">
          <select
            className="form-control"
            onChange={handleChangeStatus}
            value={status}
          >
            <option value="">전체</option>
            {statusOptions.map((obj, index) => {
              return (
                <option key={index} value={obj.key}>
                  {obj.text}
                </option>
              )
            })}
          </select>
        </div>
        <div className="col-md-2">
          <input
            type="search"
            name="keyword"
            className="form-control"
            placeholder="이메일, 회사"
            value={keyword}
            onChange={handleFilter}
          />
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered" cellSpacing="0" width="100%">
          <thead className="thead-light">
            <tr>
              <th>이메일</th>
              <th>회사</th>
              <th>캠페인</th>
              <th>프로모션</th>
              <th>프로모션 OS</th>
              <th>광고</th>
              <th>과금</th>
              <th>상태</th>
              <th>노출수</th>
              <th>클릭수</th>
              <th>전환율</th>
              <th>설치수</th>
              {/* <th>액션수</th> */}
              <th>평균단가</th>
              <th>숨은단가</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((item) => {
                const {
                  ProID,
                  Email,
                  Company,
                  CampName,
                  ProName,
                  Product,
                  BillingType,
                  Status,
                  CampType,
                  Impression,
                  Click,
                  CTR,
                  Install,
                  // Action,
                  AvgCost,
                  HiddenCost
                } = item.toJS()
                return (
                  <PromotionListItem
                    key={ProID}
                    proID={ProID}
                    email={Email}
                    company={Company}
                    campName={CampName}
                    proName={ProName}
                    product={Product}
                    billingType={BillingType}
                    status={Status}
                    campType={CampType}
                    impression={Impression}
                    click={Click}
                    ctr={CTR}
                    install={Install}
                    // action={Action}
                    avgCost={AvgCost}
                    hiddenCost={HiddenCost}
                    handleChangeHiddenCost={handleChangeHiddenCost}
                    handleModifyHiddenCost={handleModifyHiddenCost}
                  />
                )
              })}
          </tbody>
        </table>
      </div>
      <div className="d-f jc-fe mT-20">{pagination}</div>
    </Fragment>
  )
}

export default PromotionList
