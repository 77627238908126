// automatically generated by the FlatBuffers compiler, do not modify

/**
 * @const
 * @namespace
 */
var adtier0 = adtier0 || {};

/**
 * @enum {number}
 */
adtier0.DEVENV = {
  INVALID: -2,
  FIRST: -1,
  DEV: 0,
  STAGE: 1,
  PRODUCT: 2,
  TEST: 3,
  LAST: 4
};

/**
 * @enum {string}
 */
adtier0.DEVENVName = {
  '-2': 'INVALID',
  '-1': 'FIRST',
  0: 'DEV',
  1: 'STAGE',
  2: 'PRODUCT',
  3: 'TEST',
  4: 'LAST'
};

/**
 * @enum {number}
 */
adtier0.PLATFORM = {
  INVALID: -2,
  FIRST: -1,
  IOS: 0,
  AOS: 1,
  LAST: 2
};

/**
 * @enum {string}
 */
adtier0.PLATFORMName = {
  '-2': 'INVALID',
  '-1': 'FIRST',
  0: 'IOS',
  1: 'AOS',
  2: 'LAST'
};

/**
 * @enum {number}
 */
adtier0.CUSTOMER_TYPE = {
  INVALID: -2,
  FIRST: -1,
  ADVERTISER: 0,
  PUBLISHER: 1,
  LAST: 2
};

/**
 * @enum {string}
 */
adtier0.CUSTOMER_TYPEName = {
  '-2': 'INVALID',
  '-1': 'FIRST',
  0: 'ADVERTISER',
  1: 'PUBLISHER',
  2: 'LAST'
};

/**
 * @enum {number}
 */
adtier0.CAMPAIGN_TYPE = {
  INVALID: -2,
  FIRST: -1,
  IOS: 0,
  AOS: 1,
  BOTH: 2,
  WEB: 3,
  LAST: 4
};

/**
 * @enum {string}
 */
adtier0.CAMPAIGN_TYPEName = {
  '-2': 'INVALID',
  '-1': 'FIRST',
  0: 'IOS',
  1: 'AOS',
  2: 'BOTH',
  3: 'WEB',
  4: 'LAST'
};

/**
 * @enum {number}
 */
adtier0.PRODUCT = {
  INVALID: -2,
  FIRST: -1,
  BANNER: 0,
  INTERSTITIAL: 1,
  VIDEO: 2,
  LAST: 3
};

/**
 * @enum {string}
 */
adtier0.PRODUCTName = {
  '-2': 'INVALID',
  '-1': 'FIRST',
  0: 'BANNER',
  1: 'INTERSTITIAL',
  2: 'VIDEO',
  3: 'LAST'
};

/**
 * @enum {number}
 */
adtier0.BILLING_TYPE = {
  INVALID: -2,
  FIRST: -1,
  CPC: 0,
  CPM: 1,
  CPV: 2,
  CPI: 3,
  CPA: 4,
  eCPC: 5,
  nCPI: 6,
  nCPA: 7,
  LAST: 8
};

/**
 * @enum {string}
 */
adtier0.BILLING_TYPEName = {
  '-2': 'INVALID',
  '-1': 'FIRST',
  0: 'CPC',
  1: 'CPM',
  2: 'CPV',
  3: 'CPI',
  4: 'CPA',
  5: 'eCPC',
  6: 'nCPI',
  7: 'nCPA',
  8: 'LAST'
};

/**
 * @enum {number}
 */
adtier0.PROMOTION_STATUS = {
  INVALID: -2,
  FIRST: -1,
  WAITING: 0,
  INPROGRESS: 1,
  COMPLETE: 2,
  LAST: 3
};

/**
 * @enum {string}
 */
adtier0.PROMOTION_STATUSName = {
  '-2': 'INVALID',
  '-1': 'FIRST',
  0: 'WAITING',
  1: 'INPROGRESS',
  2: 'COMPLETE',
  3: 'LAST'
};

/**
 * @enum {number}
 */
adtier0.CRITERIA = {
  INVALID: -2,
  FIRST: -1,
  AMOUNT: 0,
  DAY: 1,
  HOUR: 2,
  LAST: 3
};

/**
 * @enum {string}
 */
adtier0.CRITERIAName = {
  '-2': 'INVALID',
  '-1': 'FIRST',
  0: 'AMOUNT',
  1: 'DAY',
  2: 'HOUR',
  3: 'LAST'
};

/**
 * @enum {number}
 */
adtier0.TARGETING = {
  INVALID: -2,
  FIRST: -1,
  GAME: 0,
  AUDIENCE: 1,
  LAST: 2
};

/**
 * @enum {string}
 */
adtier0.TARGETINGName = {
  '-2': 'INVALID',
  '-1': 'FIRST',
  0: 'GAME',
  1: 'AUDIENCE',
  2: 'LAST'
};

/**
 * @enum {number}
 */
adtier0.GENRE = {
  RPG: 1,
  ACTION: 2,
  CARD: 4,
  ADVANTURE: 8
};

/**
 * @enum {string}
 */
adtier0.GENREName = {
  1: 'RPG',
  2: 'ACTION',
  4: 'CARD',
  8: 'ADVANTURE'
};

/**
 * @enum {number}
 */
adtier0.TEST = {
  TEST: 1
};

/**
 * @enum {string}
 */
adtier0.TESTName = {
  1: 'TEST'
};

/**
 * @enum {number}
 */
adtier0.TIMEFLAG = {
  H0: 1,
  H1: 2,
  H2: 4,
  H3: 8,
  H4: 16,
  H5: 32,
  H6: 64,
  H7: 128,
  H8: 256,
  H9: 512,
  H10: 1024,
  H11: 2048,
  H12: 4096,
  H13: 8192,
  H14: 16384,
  H15: 32768,
  H16: 65536,
  H17: 131072,
  H18: 262144,
  H19: 524288,
  H20: 1048576,
  H21: 2097152,
  H22: 4194304,
  H23: 8388608
};

/**
 * @enum {string}
 */
adtier0.TIMEFLAGName = {
  1: 'H0',
  2: 'H1',
  4: 'H2',
  8: 'H3',
  16: 'H4',
  32: 'H5',
  64: 'H6',
  128: 'H7',
  256: 'H8',
  512: 'H9',
  1024: 'H10',
  2048: 'H11',
  4096: 'H12',
  8192: 'H13',
  16384: 'H14',
  32768: 'H15',
  65536: 'H16',
  131072: 'H17',
  262144: 'H18',
  524288: 'H19',
  1048576: 'H20',
  2097152: 'H21',
  4194304: 'H22',
  8388608: 'H23'
};

/**
 * @enum {number}
 */
adtier0.ACTION_TYPE = {
  INVALID: -2,
  FIRST: -1,
  IMPRESSION: 0,
  WATCH: 1,
  INSTALL: 2,
  CLICK: 3,
  ACTION: 4,
  LAST: 5
};

/**
 * @enum {string}
 */
adtier0.ACTION_TYPEName = {
  '-2': 'INVALID',
  '-1': 'FIRST',
  0: 'IMPRESSION',
  1: 'WATCH',
  2: 'INSTALL',
  3: 'CLICK',
  4: 'ACTION',
  5: 'LAST'
};

/**
 * @enum {number}
 */
adtier0.ADMIN_GROUP = {
  INVALID: -2,
  FIRST: -1,
  ADMINISTRATORS: 0,
  USERS: 1,
  LAST: 2
};

/**
 * @enum {string}
 */
adtier0.ADMIN_GROUPName = {
  '-2': 'INVALID',
  '-1': 'FIRST',
  0: 'ADMINISTRATORS',
  1: 'USERS',
  2: 'LAST'
};

/**
 * @enum {number}
 */
adtier0.ETH_ASKRESPONSE = {
  INVALID: -2,
  FIRST: -1,
  WAITING: 0,
  COMPLETE: 1,
  HOLD: 2,
  LAST: 3
};

/**
 * @enum {string}
 */
adtier0.ETH_ASKRESPONSEName = {
  '-2': 'INVALID',
  '-1': 'FIRST',
  0: 'WAITING',
  1: 'COMPLETE',
  2: 'HOLD',
  3: 'LAST'
};

/**
 * @enum {number}
 */
adtier0.ERROR = {
  INVALID: -2,
  FIRST: -1,
  SUCCESS: 0,
  UNKNOWN: 1,
  LAST_COMMON: 128,
  AD_NOTFOUND: 129,
  AD_DISABLED: 130,
  EXCEEDED_IMPRESSION: 131,
  NEED_ADDR: 132,
  INVALID_ADDR: 133,
  IS_BLOCKED: 134,
  NOTENOUGH_POINT: 135,
  WITHDRAW_TIMEOUT: 136,
  INSUFFICIENT_FUNDS: 137,
  ONLY_ONCEDAY: 138,
  UNKNOWN_MEDID: 139,
  CANNOT_EDITADDR: 140,
  EXCEEDED_ADDR: 141,
  AD_EXPIRED: 142,
  ALREADY_ADREQ: 143,
  TRYAGAIN_LATER: 144,
  LAST_SDK: 512,
  ADSERVER_TEST: 513,
  LAST_ADSERVER: 1024,
  NEED_SIGNUP: 1025,
  ALREADY_SIGNUP: 1026,
  TOKEN_EXPIRED: 1027,
  DUPLICATE_EMAIL: 1028,
  DUPLICATE_PACKAGE: 1029,
  INVALID_SESSIONKEY: 1030,
  INVALID_CONFTOKEN: 1031,
  NEED_SIGNIN: 1032,
  NEED_REFRESHTOKEN: 1033,
  API_FORBIDDEN: 1034,
  API_NOTFOUND: 1035,
  INVALID_PW: 1036,
  UNKNOWN_TIMEZONE: 1037,
  DAYLIMIT_LESSTHAN: 1038,
  PROLIMIT_LESSTHAN: 1039,
  GREATER_THANZERO: 1040,
  EXCEEDED_SCHEDULE: 1041,
  CANNOT_START: 1042,
  CANNOT_STOP: 1043,
  CANNOT_EDIT: 1044,
  EXCEEDED_CAMPBUDGET: 1045,
  EXCEEDED_DAYLIMIT: 1046,
  EXCEEDED_PROLIMIT: 1047,
  ACCEPT_WAITING: 1048,
  DUPLICATE_ID: 1049,
  NO_PERMISSION: 1050,
  INVALID_PERIOD: 1051,
  DUPLICATE_REQUEST: 1052,
  MUSTBE_STOPPED: 1053,
  ONLY_BUSINESSMAIL: 1054,
  NEED_ACCOUNT: 1055,
  NOTENOUGH_REVENUE: 1056,
  INVALID_TRACKURLAOS: 1057,
  INVALID_TRACKURLIOS: 1058,
  INVALID_VCODE: 1059,
  VCODE_EXPIRED: 1060,
  DUPLICATE_ADDRESS: 1061,
  INVALID_ADDRESS: 1062,
  LAST: 1063
};

/**
 * @enum {string}
 */
adtier0.ERRORName = {
  '-2': 'INVALID',
  '-1': 'FIRST',
  0: 'SUCCESS',
  1: 'UNKNOWN',
  128: 'LAST_COMMON',
  129: 'AD_NOTFOUND',
  130: 'AD_DISABLED',
  131: 'EXCEEDED_IMPRESSION',
  132: 'NEED_ADDR',
  133: 'INVALID_ADDR',
  134: 'IS_BLOCKED',
  135: 'NOTENOUGH_POINT',
  136: 'WITHDRAW_TIMEOUT',
  137: 'INSUFFICIENT_FUNDS',
  138: 'ONLY_ONCEDAY',
  139: 'UNKNOWN_MEDID',
  140: 'CANNOT_EDITADDR',
  141: 'EXCEEDED_ADDR',
  142: 'AD_EXPIRED',
  143: 'ALREADY_ADREQ',
  144: 'TRYAGAIN_LATER',
  512: 'LAST_SDK',
  513: 'ADSERVER_TEST',
  1024: 'LAST_ADSERVER',
  1025: 'NEED_SIGNUP',
  1026: 'ALREADY_SIGNUP',
  1027: 'TOKEN_EXPIRED',
  1028: 'DUPLICATE_EMAIL',
  1029: 'DUPLICATE_PACKAGE',
  1030: 'INVALID_SESSIONKEY',
  1031: 'INVALID_CONFTOKEN',
  1032: 'NEED_SIGNIN',
  1033: 'NEED_REFRESHTOKEN',
  1034: 'API_FORBIDDEN',
  1035: 'API_NOTFOUND',
  1036: 'INVALID_PW',
  1037: 'UNKNOWN_TIMEZONE',
  1038: 'DAYLIMIT_LESSTHAN',
  1039: 'PROLIMIT_LESSTHAN',
  1040: 'GREATER_THANZERO',
  1041: 'EXCEEDED_SCHEDULE',
  1042: 'CANNOT_START',
  1043: 'CANNOT_STOP',
  1044: 'CANNOT_EDIT',
  1045: 'EXCEEDED_CAMPBUDGET',
  1046: 'EXCEEDED_DAYLIMIT',
  1047: 'EXCEEDED_PROLIMIT',
  1048: 'ACCEPT_WAITING',
  1049: 'DUPLICATE_ID',
  1050: 'NO_PERMISSION',
  1051: 'INVALID_PERIOD',
  1052: 'DUPLICATE_REQUEST',
  1053: 'MUSTBE_STOPPED',
  1054: 'ONLY_BUSINESSMAIL',
  1055: 'NEED_ACCOUNT',
  1056: 'NOTENOUGH_REVENUE',
  1057: 'INVALID_TRACKURLAOS',
  1058: 'INVALID_TRACKURLIOS',
  1059: 'INVALID_VCODE',
  1060: 'VCODE_EXPIRED',
  1061: 'DUPLICATE_ADDRESS',
  1062: 'INVALID_ADDRESS',
  1063: 'LAST'
};

// Exports for Node.js and RequireJS
this.adtier0 = adtier0;
