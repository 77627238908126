import React from 'react'

import MailFormContainer from 'containers/mail/MailFormContainer'
import MailTemplate from 'components/mail/MailTemplate'
import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const Url = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <MailTemplate>
        <MailFormContainer />
      </MailTemplate>
    </PageTemplateContainer>
  )
}

export default Url
