import React, { Fragment } from 'react'
import moment from 'moment'

const TrafficLogItem = ({
  adid,
  product,
  appName,
  medName,
  num,
  cutLine,
  registTime,
  etc
}) => {
  return (
    <tr>
      <td>{adid}</td>
      <td>{product}</td>
      <td>{appName}</td>
      <td>{medName}</td>
      <td>{num}</td>
      <td>{cutLine}</td>
      <td>{moment(registTime).format('YYYY-MM-DD hh:mm:ss')}</td>
      <td>{etc}</td>
    </tr>
  )
}

const TrafficLog = ({ pagination, keyword, list, handleFilter }) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          판단 기준: 과도한 트래픽(하루 한번) / 동일 좌표(6시간 마다)
        </div>
      </div>
      <div className="row d-f jc-fe mB-10">
        <div className="col-md-2">
          <input
            type="search"
            name="keyword"
            className="form-control"
            placeholder="ADID"
            value={keyword}
            onChange={handleFilter}
          />
        </div>
      </div>

      <div className="table-responsive table-scroll-y">
        <table className="table table-bordered" cellSpacing="0" width="100%">
          <thead className="thead-light">
            <tr>
              <th className="w-30">ADID</th>
              <th>광고</th>
              <th>앱</th>
              <th>미디어</th>
              <th>요청수</th>
              <th>커트라인</th>
              <th>등록시간</th>
              <th>비고</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((item, index) => {
                const {
                  ADID,
                  Product,
                  AppName,
                  MedName,
                  Num,
                  CutLine,
                  RegistTime,
                  Etc
                } = item.toJS()
                return (
                  <TrafficLogItem
                    key={index}
                    adid={ADID}
                    product={Product}
                    appName={AppName}
                    medName={MedName}
                    num={Num}
                    cutLine={CutLine}
                    registTime={RegistTime}
                    etc={Etc}
                  />
                )
              })}
          </tbody>
        </table>
      </div>

      <div className="d-f jc-fe mT-20">{pagination}</div>
    </Fragment>
  )
}

export default TrafficLog
