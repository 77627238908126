import { Link, NavLink } from 'react-router-dom'
import React from 'react'

import logo from 'assets/images/logo.png'

const Sidebar = ({ toggleFullScreen, handlePermission, user }) => {
  return (
    <div className="sidebar">
      <div className="sidebar-inner">
        <div className="sidebar-logo">
          <div className="peers ai-c fxw-nw">
            <div className="peer peer-greed">
              <Link to="/" className="sidebar-link td-n">
                <div className="peers ai-c fxw-nw">
                  <div className="peer">
                    <div className="logo d-f ai-c jc-c">
                      <img src={logo} alt="" />
                    </div>
                  </div>
                  <div className="peer peer-greed">
                    <h5 className="lh-1 mB-0 logo-text">ZENAAD</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="peer">
              <div className="mobile-toggle sidebar-toggle">
                <a className="td-n" onClick={toggleFullScreen}>
                  <i className="ti-arrow-circle-left" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <ul className="sidebar-menu scrollable pos-r">
          <li className="nav-item mT-30 active">
            <NavLink
              to="/"
              className="sidebar-link"
              activeClassName="active"
              exact
            >
              <span className="icon-holder">
                <i className="c-blue-500 ti-layout-list-thumb" />
              </span>
              <span className="title">홈</span>
            </NavLink>
          </li>
          <li className="nav-item active">
            {user && user.get('Group') === 'ADMINISTRATORS' ? (
              <NavLink
                to="/admin"
                className="sidebar-link"
                activeClassName="active"
              >
                <span className="icon-holder">
                  <i className="c-blue-500 ti-user" />
                </span>
                <span className="title">계정 관리</span>
              </NavLink>
            ) : (
              <a onClick={handlePermission}>
                <span className="icon-holder">
                  <i className="c-blue-500 ti-user" />
                </span>
                <span className="title td-lt">계정 관리</span>
              </a>
            )}
          </li>
          <li className="nav-item active">
            {user && user.get('Group') === 'ADMINISTRATORS' ? (
              <NavLink
                to="/meta-data"
                className="sidebar-link"
                activeClassName="active"
              >
                <span className="icon-holder">
                  <i className="c-brown-500 ti-pencil" />
                </span>
                <span className="title">제나애드 설정</span>
              </NavLink>
            ) : (
              <a onClick={handlePermission}>
                <span className="icon-holder">
                  <i className="c-brown-500 ti-pencil" />
                </span>
                <span className="title td-lt">메타데이터</span>
              </a>
            )}
          </li>
          <li className="nav-item active">
            <NavLink
              to="/revenue-pub"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-blue-500 ti-layout-list-thumb" />
              </span>
              <span className="title">수익율(퍼블리셔)</span>
            </NavLink>
          </li>
          <li className="nav-item active">
            {user && user.get('Group') === 'ADMINISTRATORS' ? (
              <NavLink
                to="/refund-pub"
                className="sidebar-link"
                activeClassName="active"
              >
                <span className="icon-holder">
                  <i className="c-deep-orange-500 ti-layout-list-thumb" />
                </span>
                <span className="title">환급요청(퍼블리셔)</span>
              </NavLink>
            ) : (
              <a onClick={handlePermission}>
                <span className="icon-holder">
                  <i className="c-deep-orange-500 ti-layout-list-thumb" />
                </span>
                <span className="title td-lt">환급요청(퍼블리셔)</span>
              </a>
            )}
          </li>
          <li className="nav-item active">
            <NavLink
              to="/summary"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-green-500 ti-layout-list-thumb" />
              </span>
              <span className="title">비용/수익</span>
            </NavLink>
          </li>
          <li className="nav-item active">
            <NavLink
              to="/eth-black"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-brown-500 ti-user" />
              </span>
              <span className="title">부정 ADID</span>
            </NavLink>
          </li>
          <li className="nav-item active">
            <NavLink
              to="/xplayz-user"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-brown-500 ti-user" />
              </span>
              <span className="title">xPlayz 사용자</span>
            </NavLink>
          </li>
          <li className="nav-item active">
            <NavLink
              to="/xplayz-address-count"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-brown-500 ti-user" />
              </span>
              <span className="title">xPlayz 지갑생성 수</span>
            </NavLink>
          </li>
          <li className="nav-item active">
            <NavLink
              to="/xplayz-tx-count"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-brown-500 ti-user" />
              </span>
              <span className="title">xPlayz 트랜잭션 수</span>
            </NavLink>
          </li>
          {/* <li className="nav-item active">
            <NavLink
              to="/eth-receipt"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-red-500 ti-files" />
              </span>
              <span className="title">이더리움 영수증</span>
            </NavLink>
          </li> */}
          {/* <li className="nav-item active">
            <NavLink
              to="/notice"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-green-500 ti-pencil" />
              </span>
              <span className="title">공지사항 등록</span>
            </NavLink>
          </li>
          <li className="nav-item active">
            <NavLink
              to="/faq"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-green-500 ti-pencil" />
              </span>
              <span className="title">자주 묻는 질문 등록</span>
            </NavLink>
          </li> */}
          <li className="nav-item active">
            <NavLink
              to="/eth-ask"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-deep-purple-500 ti-layout-list-thumb" />
              </span>
              <span className="title">암호화폐 문의 내역</span>
            </NavLink>
          </li>
          <li className="nav-item active">
            {user && user.get('Group') === 'ADMINISTRATORS' ? (
              <NavLink
                to="/customer"
                className="sidebar-link"
                activeClassName="active"
              >
                <span className="icon-holder">
                  <i className="c-indigo-500 ti-layout-list-thumb" />
                </span>
                <span className="title">광고주/퍼블리셔 계정</span>
              </NavLink>
            ) : (
              <a onClick={handlePermission}>
                <span className="icon-holder">
                  <i className="c-indigo-500 ti-layout-list-thumb" />
                </span>
                <span className="title td-lt">광고주/퍼블리셔 계정</span>
              </a>
            )}
          </li>
          <li className="nav-item active">
            <NavLink
              to="/app"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-purple-500 ti-layout-list-thumb" />
              </span>
              <span className="title">앱 관리(퍼블리셔)</span>
            </NavLink>
          </li>
          <li className="nav-item active">
            <NavLink
              to="/api-key-request"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-purple-500 ti-layout-list-thumb" />
              </span>
              <span className="title">앱 API KEY</span>
            </NavLink>
          </li>
          {/* <li className="nav-item active">
            <NavLink
              to="/maintenance"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-red-500 ti-check-box" />
              </span>
              <span className="title">점검</span>
            </NavLink>
          </li> */}
          <li className="nav-item active">
            <NavLink
              to="/mail"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-purple-500 ti-email" />
              </span>
              <span className="title">메일</span>
            </NavLink>
          </li>
          <li className="nav-item active">
            <NavLink
              to="/url"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-green-500 ti-new-window" />
              </span>
              <span className="title">광고주/퍼블리셔 가입 URL</span>
            </NavLink>
          </li>
          <li className="nav-item active">
            <NavLink
              to="/promotion"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-purple-500 ti-layout-list-thumb" />
              </span>
              <span className="title">프로모션(광고주)</span>
            </NavLink>
          </li>
          <li className="nav-item active">
            <NavLink
              to="/kpi"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-indigo-500 ti-bar-chart" />
              </span>
              <span className="title">KPI(퍼블리셔)</span>
            </NavLink>
          </li>
          <li className="nav-item active">
            {user && user.get('Group') === 'ADMINISTRATORS' ? (
              <NavLink
                to="/adx-tracking-postback"
                className="sidebar-link"
                activeClassName="active"
              >
                <span className="icon-holder">
                  <i className="c-purple-500 ti-layout-list-thumb" />
                </span>
                <span className="title">ADX(Tracking Postback)</span>
              </NavLink>
            ) : (
              <a onClick={handlePermission}>
                <span className="icon-holder">
                  <i className="c-purple-500 ti-layout-list-thumb" />
                </span>
                <span className="title td-lt">ADX(Tracking Postback)</span>
              </a>
            )}
          </li>
          <li className="nav-item active">
            {user && user.get('Group') === 'ADMINISTRATORS' ? (
              <NavLink
                to="/adx-event"
                className="sidebar-link"
                activeClassName="active"
              >
                <span className="icon-holder">
                  <i className="c-purple-500 ti-layout-list-thumb" />
                </span>
                <span className="title">ADX(Event)</span>
              </NavLink>
            ) : (
              <a onClick={handlePermission}>
                <span className="icon-holder">
                  <i className="c-purple-500 ti-layout-list-thumb" />
                </span>
                <span className="title td-lt">ADX(Event)</span>
              </a>
            )}
          </li>
          {/* <li>
            <NavLink
              to="/quill-test"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-purple-500 ti-layout-list-thumb" />
              </span>
              <span className="title">Quill Test</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/tiny-mce-test"
              className="sidebar-link"
              activeClassName="active"
            >
              <span className="icon-holder">
                <i className="c-purple-500 ti-layout-list-thumb" />
              </span>
              <span className="title">TinyMCE Test</span>
            </NavLink>
          </li> */}
        </ul>
      </div>
    </div>
  )
}
export default Sidebar
