import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import React, { Component, Fragment } from 'react'
import debounce from 'lodash/debounce'

import MessageModal from 'components/common/MessageModal'
import MetaDataList from 'components/metadata/MetaDataList'

import * as baseActions from 'store/modules/base'
import * as meActions from 'store/modules/me'
import * as metaDataActions from 'store/modules/metadata'
import * as modalActions from 'store/modules/modal'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { delay, jsonValueByKey, loadingDelay } from 'libs/common'
import { ekey } from 'libs/enums'
import { stkeys, storage } from 'libs/storage'

class MetaDataListContainer extends Component {
  state = {
    keyword: '',
    pageNo: 1,
    pageSize: 10
  }

  async componentDidMount() {
    const { MetaDataActions, ModalActions } = this.props

    MetaDataActions.init()

    const user = storage.get(stkeys.user)
    if (user && user.Group !== 'ADMINISTRATORS') {
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: '권한이 없습니다'
      })
    } else {
      const { pageNo } = this.state
      await this.getList(pageNo)
    }
  }

  getList = async (pageNo) => {
    const { keyword, pageSize } = this.state
    const { BaseActions, MetaDataActions, MeActions, ModalActions } = this.props

    try {
      BaseActions.setLoadingActive(true)
      await MetaDataActions.getList({
        keyword,
        pageNo,
        pageSize
      })
      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      let e = error
      if (!e) {
        const { status } = err.response
        if (status === 401) {
          e = 'NEED_SIGNIN'
        }
      }

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(e)
      })
    }
  }

  handlePageChange = async (pageNo) => {
    await this.getList(pageNo)
    this.setState({
      pageNo
    })
  }

  debouncedFilter = debounce(async () => {
    await this.getList(1)
    this.setState({
      pageNo: 1
    })
  }, 500)

  handleFilter = (e) => {
    this.setState(
      {
        keyword: e.target.value
      },
      () => {
        this.debouncedFilter()
      }
    )
  }

  handleChange = (e) => {
    const { MetaDataActions } = this.props
    const id = e.target.name.split('-')[1]
    const val = e.target.value.replace(/[^0-9]/g, '')

    MetaDataActions.setValue({ id, value: val })
  }

  handleModify = async (id, value) => {
    const { MeActions, MetaDataActions, ModalActions } = this.props

    try {
      if (value < 0 || value > 90) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '0 ~ 90 사이의 값만 입력해주세요.'
        })
        return
      }

      await MetaDataActions.updateYieldPub({ id, value })

      const { error, newToken } = this.props

      if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '수정되었습니다.'
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  render() {
    const { keyword, pageNo, pageSize } = this.state
    const { rowCount, list, messageModal } = this.props
    const {
      handlePageChange,
      handleFilter,
      handleChange,
      handleModify,
      handleOK
    } = this

    return (
      <Fragment>
        <MetaDataList
          pagination={
            <Pagination
              activePage={pageNo}
              itemsCountPerPage={pageSize}
              totalItemsCount={rowCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          }
          keyword={keyword}
          list={list}
          handleFilter={handleFilter}
          handleChange={handleChange}
          handleModify={handleModify}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.metadata.get('error'),
    rowCount: state.metadata.get('rowCount'),
    list: state.metadata.get('list'),
    newToken: state.metadata.get('newToken'),
    messageModal: state.modal.get('message').toJS()
  }),
  (dispatch) => ({
    BaseActions: bindActionCreators(baseActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    MetaDataActions: bindActionCreators(metaDataActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(MetaDataListContainer))
