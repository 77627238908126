import React, { Fragment } from 'react'

import SectionWithTitle from 'components/common/SectionWithTitle'

const MetaDataTemplate = ({ form, children }) => {
  return (
    <Fragment>
      <div className="row mB-20">
        <div className="col-md-12">
          <SectionWithTitle title="퍼블리셔 수익률">
            {children}
          </SectionWithTitle>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <SectionWithTitle title="이더리움(제나애드 지갑)">
            {form}
          </SectionWithTitle>
        </div>
      </div>
    </Fragment>
  )
}

export default MetaDataTemplate
