import React from 'react'

import SectionWithTitle from 'components/common/SectionWithTitle'

const MaintenanceTemplate = ({ children }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <SectionWithTitle title="점검">{children}</SectionWithTitle>
      </div>
    </div>
  )
}

export default MaintenanceTemplate
