import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import React, { Component, Fragment } from 'react'
import debounce from 'lodash/debounce'

import AppForm from 'components/app/AppForm'
import AppList from 'components/app/AppList'
import MessageModal from 'components/common/MessageModal'

import * as appActions from 'store/modules/app'
import * as baseActions from 'store/modules/base'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { delay, jsonValueByKey, loadingDelay } from 'libs/common'
import { ekey } from 'libs/enums'
import { stkeys, storage } from 'libs/storage'

class AppListContainer extends Component {
  state = {
    keyword: '',
    pageNo: 1,
    pageSize: 10
  }

  async componentDidMount() {
    const { pageNo } = this.state
    const { AppActions } = this.props

    AppActions.init()

    await this.getAppList(pageNo)
  }

  getAppList = async (pageNo) => {
    const { keyword, pageSize } = this.state
    const { AppActions, BaseActions, MeActions, ModalActions } = this.props

    try {
      BaseActions.setLoadingActive(true)
      await AppActions.getAppList({
        keyword,
        pageNo,
        pageSize
      })
      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handlePageChange = async (pageNo) => {
    const { AppActions } = this.props
    AppActions.setForm({
      email: '',
      company: '',
      platform: '',
      name: '',
      packageName: '',
      active: 0,
      registTime: '',
      opened: false
    })

    await this.getAppList(pageNo)
    this.setState({
      pageNo
    })
  }

  debouncedFilter = debounce(async () => {
    await this.getAppList(1)
    this.setState({
      pageNo: 1
    })
  }, 500)

  handleFilter = (e) => {
    const { AppActions } = this.props
    AppActions.setForm({
      email: '',
      company: '',
      platform: '',
      name: '',
      packageName: '',
      active: 0,
      registTime: '',
      opened: false
    })

    this.setState(
      {
        keyword: e.target.value
      },
      () => {
        this.debouncedFilter()
      }
    )
  }

  handleShowForm = (
    appID,
    email,
    company,
    platform,
    name,
    packageName,
    apiKey,
    apiKeyStatus,
    active,
    registTime
  ) => {
    const { form, AppActions } = this.props

    if (form.get('appID') !== appID) {
      AppActions.setForm({
        appID,
        email,
        company,
        platform,
        name,
        packageName,
        apiKey,
        apiKeyStatus,
        active,
        registTime,
        opened: true
      })
    } else {
      AppActions.setForm({
        email: '',
        company: '',
        platform: '',
        name: '',
        packageName: '',
        active: 0,
        registTime: '',
        opened: false
      })
    }
  }

  handleApiKey = (e) => {
    const { AppActions } = this.props
    const [apiKey, apiKeyStatus] = e.target.value.split('|')

    AppActions.setFormApiKey({ apiKey, apiKeyStatus })
  }

  handleActive = (e) => {
    const { AppActions } = this.props
    AppActions.setFormActive(e.target.value)
  }

  handleUpdate = async (appID) => {
    const { form, AppActions, MeActions, ModalActions } = this.props
    // const apiKey = form.get('apiKey')
    const apiKeyStatus = form.get('apiKeyStatus')
    const active = form.get('active')

    try {
      await AppActions.update({
        appID,
        apiKeyStatus,
        active
      })

      const { error, newToken } = this.props

      if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        AppActions.changeActive({
          appID,
          apiKeyStatus,
          active
        })
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '수정되었습니다.'
        })
      } else {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  render() {
    const { keyword, pageNo, pageSize } = this.state
    const { rowCount, appList, form, opened, messageModal } = this.props
    const {
      handlePageChange,
      handleFilter,
      handleShowForm,
      handleApiKey,
      handleActive,
      handleUpdate,
      handleOK
    } = this

    return (
      <Fragment>
        <AppList
          pagination={
            <Pagination
              activePage={pageNo}
              itemsCountPerPage={pageSize}
              totalItemsCount={rowCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          }
          form={
            <AppForm
              form={form}
              opened={opened}
              handleApiKey={handleApiKey}
              handleActive={handleActive}
              handleUpdate={handleUpdate}
            />
          }
          keyword={keyword}
          list={appList}
          selectedID={form.get('appID')}
          handleFilter={handleFilter}
          handleShowForm={handleShowForm}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.app.get('error'),
    rowCount: state.app.get('rowCount'),
    appList: state.app.get('appList'),
    form: state.app.get('form'),
    opened: state.app.get('opened'),
    newToken: state.app.get('newToken'),
    user: state.me.get('user'),
    messageModal: state.modal.get('message').toJS()
  }),
  (dispatch) => ({
    AppActions: bindActionCreators(appActions, dispatch),
    BaseActions: bindActionCreators(baseActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(AppListContainer))
