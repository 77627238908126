import { List, Map, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { ethAPI } from 'api'

// action type
const INIT = 'ethask/INIT'

const GET_ASK_LIST = 'ethask/GET_ASK_LIST'
const GET_ASK_LIST_LOADING = 'ethask/GET_ASK_LIST_LOADING'
const GET_ASK_LIST_SUCCESS = 'ethask/GET_ASK_LIST_SUCCESS'
const GET_ASK_LIST_FAILURE = 'ethask/GET_ASK_LIST_FAILURE'

const SET_FORM = 'ethask/SET_FORM'
const SET_FORM_RESPONSE = 'ethask/SET_FORM_RESPONSE'

const UPDATE_ETH_ASK = 'ethask/UPDATE_ETH_ASK'
const UPDATE_ETH_ASK_LOADING = 'ethask/UPDATE_ETH_ASK_LOADING'
const UPDATE_ETH_ASK_SUCCESS = 'ethask/UPDATE_ETH_ASK_SUCCESS'
const UPDATE_ETH_ASK_FAILURE = 'ethask/UPDATE_ETH_ASK_FAILURE'

const CHANGE_RESPONSE = 'ethask/CHANGE_RESPONSE'

const GET_TRAFFIC_LOG = 'ethask/GET_TRAFFIC_LOG'
const GET_TRAFFIC_LOG_LOADING = 'ethask/GET_TRAFFIC_LOG_LOADING'
const GET_TRAFFIC_LOG_SUCCESS = 'ethask/GET_TRAFFIC_LOG_SUCCESS'
const GET_TRAFFIC_LOG_FAILURE = 'ethask/GET_TRAFFIC_LOG_FAILURE'

const SET_RESPONSE_CONTENT = 'ethask/SET_RESPONSE_CONTENT'

// action creator
export const init = createAction(INIT)

export const getAskList = (params) => ({
  type: GET_ASK_LIST,
  payload: ethAPI.ethAskList(params)
})

export const setForm = createAction(SET_FORM)
export const setFormResponse = createAction(SET_FORM_RESPONSE)

export const updateEthAsk = (params) => ({
  type: UPDATE_ETH_ASK,
  payload: ethAPI.updateEthAsk(params)
})

export const changeResponse = createAction(CHANGE_RESPONSE)

export const getTrafficLog = (params) => ({
  type: GET_TRAFFIC_LOG,
  payload: ethAPI.ethTrafficLog(params)
})

export const setResponseContent = createAction(SET_RESPONSE_CONTENT)

// initial state
const initialState = Map({
  error: '',
  rowCount: 0,
  askList: List(),
  newToken: '',
  form: Map({
    id: 0,
    adid: '',
    email: '',
    title: '',
    content: '',
    response: ''
  }),
  opened: false,
  rowCount2: 0,
  trafficLog: List(),
  loading: false
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [GET_ASK_LIST_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_ASK_LIST_SUCCESS]: (state, action) => {
      const { error, rowCount, rows: askList, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('rowCount', rowCount)
        .set('askList', fromJS(askList))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_ASK_LIST_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [SET_FORM]: (state, action) => {
      const {
        id,
        adid,
        email,
        title,
        content,
        response,
        opened
      } = action.payload

      const form = {
        id,
        adid,
        email,
        title,
        content,
        response
      }

      return state.set('form', fromJS(form)).set('opened', opened)
    },
    [SET_FORM_RESPONSE]: (state, action) => {
      return state.setIn(['form', 'response'], action.payload)
    },
    [UPDATE_ETH_ASK_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [UPDATE_ETH_ASK_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [UPDATE_ETH_ASK_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [CHANGE_RESPONSE]: (state, action) => {
      const { id, response } = action.payload

      const index = state
        .get('askList')
        .findIndex((item) => item.get('ID') === id)
      return state.setIn(['askList', index, 'Response'], response)
    },
    [GET_TRAFFIC_LOG_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_TRAFFIC_LOG_SUCCESS]: (state, action) => {
      const {
        error,
        rowCount,
        rows: trafficLog,
        newToken
      } = action.payload.data
      return state
        .set('error', error)
        .set('rowCount2', rowCount)
        .set('trafficLog', fromJS(trafficLog))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_TRAFFIC_LOG_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [SET_RESPONSE_CONTENT]: (state, action) => {
      return state.setIn(['form', 'responseContent'], action.payload)
    }
  },
  initialState
)
