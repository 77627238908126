import React from 'react'

import SectionWithTitle from 'components/common/SectionWithTitle'

const MeTemplate = ({ title, children }) => {
  return (
    <div className="row gap-20 masonry pos-r">
      <div className="col-md-6">
        <SectionWithTitle title={title}>{children}</SectionWithTitle>
      </div>
      <div className="col-md-6" />
    </div>
  )
}

export default MeTemplate
