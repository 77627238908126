import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { withRouter } from 'react-router-dom'
import React, { Component, Fragment } from 'react'

import MailForm from 'components/mail/MailForm'
import MessageModal from 'components/common/MessageModal'

import * as baseActions from 'store/modules/base'
import * as mailActions from 'store/modules/mail'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { ekey } from 'libs/enums'
import { jsonValueByKey } from 'libs/common'
import { stkeys, storage } from 'libs/storage'

class MailFormContainer extends Component {
  state = {
    disabled: true
  }

  handleTypeChange = (e) => {
    if (e.target.value === 'INPUT') {
      this.setState({
        disabled: false
      })
    } else {
      this.setState({
        disabled: true
      })
    }
  }

  handleSubmit = async (values, dispatch) => {
    const { type, to, subject, content } = values
    const { BaseActions, MailActions, MeActions, ModalActions } = this.props

    let mails = ''
    if (type === 'INPUT') {
      const lastChar = to.charAt(to.length - 1)
      if (lastChar === ';') {
        mails = to.slice(0, -1)
      } else {
        mails = to
      }

      const temp = mails.split(';')
      if (temp.length > 10) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '최대 수신자는 10명입니다.'
        })

        return
      }
    } else {
      mails = type
    }

    const params = {
      type,
      to: mails,
      subject,
      content
    }

    try {
      BaseActions.setLoadingActive(true)
      await MailActions.send(params)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        dispatch(reset('mailForm'))
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '전송되었습니다.'
        })
      } else {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  render() {
    const { disabled } = this.state
    const { messageModal, pristine, submitting } = this.props
    const { handleTypeChange, handleSubmit, handleOK } = this

    return (
      <Fragment>
        <MailForm
          disabled={disabled}
          pristine={pristine}
          submitting={submitting}
          handleTypeChange={handleTypeChange}
          onSubmit={handleSubmit}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.mail.get('error'),
    newToken: state.customer.get('newToken'),
    user: state.me.get('user'),
    messageModal: state.modal.get('message').toJS()
  }),
  (dispatch) => ({
    BaseActions: bindActionCreators(baseActions, dispatch),
    MailActions: bindActionCreators(mailActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(MailFormContainer))
