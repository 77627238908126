import { findKey, get } from 'lodash/object'

const ekey = (obj, arg) => {
  return findKey(obj, (v) => {
    return v === arg
  })
}

const evalue = (obj, arg) => {
  return get(obj, arg)
}

export { ekey, evalue }
