import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
// import { reset } from 'redux-form'
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import debounce from 'lodash/debounce'

import AdxList from 'components/adx/AdxList'
import AdxListForm from 'components/adx/AdxListForm'
import MessageModal from 'components/common/MessageModal'

import * as adxActions from 'store/modules/adx'
import * as baseActions from 'store/modules/base'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { delay, jsonValueByKey, loadingDelay } from 'libs/common'
import { ekey } from 'libs/enums'
import { stkeys, storage } from 'libs/storage'

class AdxListContainer extends Component {
  state = {
    keyword: '',
    pageNo: 1,
    pageSize: 10,
    isModify: false,
    selectedID: 0,
    adLinkStatus: '',
    selectedCountry: [],
    selectedPlatform: [],
    selectedProduct: [],
    selectedBillingType: []
  }

  async componentDidMount() {
    const { AdxActions, ModalActions } = this.props

    AdxActions.init()

    const user = storage.get(stkeys.user)
    if (user && user.Group !== 'ADMINISTRATORS') {
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: '권한이 없습니다'
      })
    } else {
      const { pageNo } = this.state
      await this.getAdxList(pageNo)
    }
  }

  getAdxList = async (pageNo) => {
    const { keyword, pageSize } = this.state
    const { AdxActions, BaseActions, MeActions, ModalActions } = this.props

    try {
      BaseActions.setLoadingActive(true)
      await AdxActions.getAdxList({
        keyword,
        pageNo,
        pageSize
      })
      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handlePageChange = async (pageNo) => {
    await this.getAdxList(pageNo)
    this.setState({
      pageNo
    })
  }

  debouncedFilter = debounce(async () => {
    await this.getAdxList(1)
    this.setState({
      pageNo: 1
    })
  }, 500)

  handleFilter = (e) => {
    this.setState(
      {
        keyword: e.target.value
      },
      () => {
        this.debouncedFilter()
      }
    )
  }

  handleSetForm = async (AID) => {
    this.setState({
      isModify: true,
      selectedID: AID
    })

    const { AdxActions, ModalActions, MeActions } = this.props
    AdxActions.setIsModify(true)

    try {
      await AdxActions.getByAID({ AID })

      const { error, newToken, form } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      } else {
        const json = form.toJS()

        this.setState({
          adLinkStatus: json.AdLinkStatus,
          selectedCountry: json.AdLinkCountry,
          selectedPlatform: json.AdLinkPlatform,
          selectedProduct: json.AdLinkProduct,
          selectedBillingType: json.AdLinkBillingType
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleCountryChange = (options) => {
    if (options.length > 1) {
      if (options[0].value === 'Global') {
        this.setState({
          selectedCountry: options.slice(1)
        })

        return
      }
    }

    const temp = options.filter((op) => op.value === 'Global')
    if (temp && temp.length > 0) {
      this.setState({
        selectedCountry: [temp[0]]
      })

      return
    }

    this.setState({
      selectedCountry: options
    })
  }

  handlePlatformChange = (options) => {
    this.setState({
      selectedPlatform: options
    })
  }

  handleProductChange = (options) => {
    this.setState({
      selectedProduct: options
    })
  }

  handleBillingTypeChange = (options) => {
    this.setState({
      selectedBillingType: options
    })
  }

  handleSubmit = async (values) => {
    const { pageNo } = this.state
    const { AdxActions, MeActions, ModalActions } = this.props

    AdxActions.setAdx(values)

    try {
      await AdxActions.modify(values)

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      } else {
        // dispatch(reset('adxList'))
        this.handlePageChange(pageNo)
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '수정되었습니다.'
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleStart = async (AID) => {
    const { pageNo } = this.state
    const { AdxActions, MeActions, ModalActions } = this.props

    try {
      await AdxActions.start({ AID })

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      } else {
        // dispatch(reset('adxList'))
        this.setState({
          adLinkStatus: 'INPROGRESS'
        })
        this.handlePageChange(pageNo)
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '시작되었습니다.'
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleStop = async (AID) => {
    const { pageNo } = this.state
    const { AdxActions, MeActions, ModalActions } = this.props

    try {
      await AdxActions.stop({ AID })

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      } else {
        // dispatch(reset('adxList'))
        this.setState({
          adLinkStatus: 'WAITING'
        })
        this.handlePageChange(pageNo)
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '중지되었습니다.'
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  render() {
    const {
      keyword,
      pageNo,
      pageSize,
      isModify,
      selectedID,
      adLinkStatus,
      selectedCountry,
      selectedPlatform,
      selectedProduct,
      selectedBillingType
    } = this.state
    const { rowCount, list, messageModal, pristine, submitting } = this.props
    const {
      handlePageChange,
      handleFilter,
      handleSetForm,
      handleSubmit,
      handleStart,
      handleStop,
      handleCountryChange,
      handlePlatformChange,
      handleProductChange,
      handleBillingTypeChange,
      handleOK
    } = this

    return (
      <Fragment>
        <AdxList
          pagination={
            <Pagination
              activePage={pageNo}
              itemsCountPerPage={pageSize}
              totalItemsCount={rowCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          }
          form={
            <AdxListForm
              isModify={isModify}
              pristine={pristine}
              submitting={submitting}
              onSubmit={handleSubmit}
              handleStart={handleStart}
              handleStop={handleStop}
              handleCountryChange={handleCountryChange}
              handlePlatformChange={handlePlatformChange}
              handleProductChange={handleProductChange}
              handleBillingTypeChange={handleBillingTypeChange}
              selectedID={selectedID}
              adLinkStatus={adLinkStatus}
              selectedCountry={selectedCountry}
              selectedPlatform={selectedPlatform}
              selectedProduct={selectedProduct}
              selectedBillingType={selectedBillingType}
            />
          }
          keyword={keyword}
          list={list}
          selectedID={selectedID}
          handleFilter={handleFilter}
          handleSetForm={handleSetForm}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.adx.get('error'),
    rowCount: state.adx.get('rowCount'),
    list: state.adx.get('list'),
    form: state.adx.get('form'),
    newToken: state.adx.get('newToken'),
    user: state.me.get('user'),
    messageModal: state.modal.get('message').toJS()
  }),
  (dispatch) => ({
    AdxActions: bindActionCreators(adxActions, dispatch),
    BaseActions: bindActionCreators(baseActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(AdxListContainer))
