import React, { Fragment } from 'react'
import moment from 'moment'

const EthBlackListItem = ({
  adid,
  platform,
  eth,
  updateTime,
  handleDeleteBlack
}) => {
  return (
    <tr>
      <td>{adid}</td>
      <td>{platform}</td>
      <td className="text-right">{eth}</td>
      <td>{moment(updateTime).format('YYYY-MM-DD hh:mm:ss')}</td>
      <td>
        <button
          type="button"
          className="btn btn-info btn-sm"
          onClick={() => handleDeleteBlack(adid)}
        >
          확인
        </button>
      </td>
    </tr>
  )
}

const EthBlackList = ({
  pagination,
  keyword,
  newadid,
  list,
  handleFilter,
  handleDeleteBlack,
  changeNewADID,
  handleAddBlack
}) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          {
            '매크로라고 판단 되면 자동 등록되며 자세한 내용은 "암호화폐 문의 내역", "부정 로그"에서 확인 가능합니다.'
          }
        </div>
      </div>
      <div className="row justify-content-md-end mB-10">
        <div className="col-md-2">
          <input
            type="search"
            name="keyword"
            className="form-control"
            placeholder="ADID"
            value={keyword}
            onChange={handleFilter}
          />
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th className="w-30">ADID</th>
              <th className="w-15">플랫폼</th>
              <th className="w-20">XPLA</th>
              <th className="w-20">등록시간</th>
              <th>해제</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((item) => {
                const { ADID, Platform, Eth, UpdateTime } = item.toJS()
                return (
                  <EthBlackListItem
                    key={ADID}
                    adid={ADID}
                    platform={Platform}
                    eth={Eth}
                    updateTime={UpdateTime}
                    handleDeleteBlack={handleDeleteBlack}
                  />
                )
              })}
          </tbody>
        </table>
      </div>
      <div className="d-f jc-fe">{pagination}</div>
      <div className="row jc-fs">
        <div className="col-md-4">
          <input
            type="text"
            name="adid"
            className="form-control"
            placeholder="ADID"
            value={newadid}
            onChange={changeNewADID}
          />
        </div>
        <div className="col-md-2">
          <button
            type="button"
            className="btn btn-primary btn-block"
            onClick={handleAddBlack}
          >
            등록
          </button>
        </div>
      </div>
    </Fragment>
  )
}

export default EthBlackList
