export const billingType = [
  {
    label: 'CPC',
    value: 'CPC'
  },
  {
    label: 'CPM',
    value: 'CPM'
  },
  {
    label: 'CPV',
    value: 'CPV'
  },
  // {
  //   label: 'CPI',
  //   value: 'CPI'
  // },
  // {
  //   label: 'CPA',
  //   value: 'CPA'
  // },
  {
    label: 'eCPC',
    value: 'eCPC'
  },
  {
    label: 'nCPI',
    value: 'nCPI'
  },
  {
    label: 'nCPA',
    value: 'nCPA'
  }
]
