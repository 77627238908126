import React from 'react'

import NoticeFormContainer from 'containers/notice/NoticeFormContainer'
import NoticeTemplate from 'components/notice/NoticeTemplate'
import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const Notice = () => {
  return (
    <PageTemplateContainer sidebar={<SidebarContainer />} topbar={<TopbarContainer />}>
      <NoticeTemplate>
        <NoticeFormContainer />
      </NoticeTemplate>
    </PageTemplateContainer>
  )
}

export default Notice
