import { Map } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { faqAPI } from 'api'

// action type
const SET_TYPE = 'notice/SET_TYPE'
const SET_FILE = 'notice/SET_FILE'

const UPLOAD = 'faq/UPLOAD'
const UPLOAD_LOADING = 'faq/UPLOAD_LOADING'
const UPLOAD_SUCCESS = 'faq/UPLOAD_SUCCESS'
const UPLOAD_FAILURE = 'faq/UPLOAD_FAILURE'

// action creator
export const setType = createAction(SET_TYPE)
export const setFile = createAction(SET_FILE)
export const upload = (params) => ({
  type: UPLOAD,
  payload: faqAPI.upload(params)
})

// initial state
const initialState = Map({
  error: '',
  type: 'file',
  file: null,
  newToken: '',
  loading: false
})

// reducer
export default handleActions(
  {
    [SET_TYPE]: (state, action) => {
      return state.set('type', action.payload)
    },
    [SET_FILE]: (state, action) => {
      return state.set('file', action.payload)
    },
    [UPLOAD_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [UPLOAD_SUCCESS]: (state, action) => {
      const { error, newToken } = action.payload.data

      return state
        .set('error', error)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [UPLOAD_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    }
  },
  initialState
)
