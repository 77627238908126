import React, { Fragment } from 'react'
import cx from 'classnames'
import moment from 'moment'

import NumberFormat from 'react-number-format'
// import Input from 'components/common/Input'
// import Button from 'components/common/Button'

const CustomerListItem = ({
  custID,
  type,
  email,
  company,
  cash,
  timezoneName,
  signinTime,
  registTime,
  selectedID,
  handleShowForm
}) => {
  const typeName = type === 'ADVERTISER' ? ' 광고주' : '퍼블리셔'

  return (
    <tr
      className={cx(
        'cur-p',
        { 'bg-danger': selectedID === custID },
        { 'text-white': selectedID === custID }
      )}
      onClick={() => handleShowForm(custID, type, email, company, cash)}
    >
      <td>{typeName}</td>
      <td>{email}</td>
      <td>{company}</td>
      <td className="text-right">
        <NumberFormat
          value={Math.floor(cash)}
          displayType="text"
          prefix="&#8361; "
          thousandSeparator={true}
        />
      </td>
      <td>{timezoneName}</td>
      <td>{moment(signinTime).format('YYYY-MM-DD hh:mm:ss')}</td>
      <td>{moment(registTime).format('YYYY-MM-DD hh:mm:ss')}</td>
    </tr>
  )
}

const CustomerList = ({
  pagination,
  type,
  keyword,
  list,
  selectedID,
  handleChangeType,
  handleFilter,
  handleShowForm,
  handleRefresh
}) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          광고주를 선택하면 캐쉬 충전 가능합니다.(충전 후 새로고침 버튼을 누르면
          적용된 내용 확인 가능)
        </div>
      </div>
      <div className="row jc-fe mB-10">
        <div className="col-md-1">
          <button
            type="button"
            className="btn btn-info"
            onClick={handleRefresh}
          >
            새로고침
          </button>
        </div>
        <div className="col-md-2">
          <select
            className="form-control"
            value={type}
            onChange={handleChangeType}
          >
            <option value="">전체</option>
            <option value="ADVERTISER">광고주</option>
            <option value="PUBLISHER">퍼블리셔</option>
          </select>
        </div>
        <div className="col-md-2">
          <input
            type="search"
            name="keyword"
            className="form-control"
            placeholder="이메일, 회사"
            value={keyword}
            onChange={handleFilter}
          />
        </div>
      </div>

      <div className="table-responsive table-scroll-y">
        <table className="table table-bordered" cellSpacing="0" width="100%">
          <thead className="thead-light">
            <tr>
              <th>유형</th>
              <th>이메일</th>
              <th>회사</th>
              <th>캐쉬(광고주)</th>
              <th>타임존</th>
              <th>싸인인 시간</th>
              <th>등록 시간</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((item) => {
                const {
                  CustID,
                  Type,
                  Email,
                  Company,
                  Cash,
                  TimezoneName,
                  SigninTime,
                  RegistTime
                } = item.toJS()
                return (
                  <CustomerListItem
                    key={CustID}
                    custID={CustID}
                    type={Type}
                    email={Email}
                    company={Company}
                    cash={Cash}
                    timezoneName={TimezoneName}
                    signinTime={SigninTime}
                    registTime={RegistTime}
                    selectedID={selectedID}
                    handleShowForm={handleShowForm}
                  />
                )
              })}
          </tbody>
        </table>
      </div>
      <div className="d-f jc-fe mT-20">{pagination}</div>
    </Fragment>
  )
}

export default CustomerList
