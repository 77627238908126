import React, { Fragment } from 'react'
import Select from 'react-select'
import cx from 'classnames'

const SingleSelect = ({
  id,
  name,
  placeholder,
  options,
  defaultValue,
  helpertext,
  input,
  isClearable,
  meta: { touched, error }
}) => {
  return (
    <Fragment>
      <Select
        id={id}
        name={name}
        placeholder={placeholder}
        options={options}
        defaultValue={defaultValue}
        onChange={(value) => input.onChange(value)}
        styles={{
          menuPortal: (base) => {
            const { zIndex, ...rest } = base // remove zIndex from base by destructuring
            return { ...rest, zIndex: 9999 }
          }
        }}
        menuPortalTarget={document.body}
        isClearable={isClearable}
      />
      {helpertext && (
        <small className="form-text text-muted mT-5 c-blue-900">
          {helpertext}
        </small>
      )}
      {touched &&
        error && (
          <div className={cx('mT-5', { 'c-red-500': touched && error })}>
            {error}
          </div>
        )}
    </Fragment>
  )
}

export default SingleSelect
