import NumberFormat from 'react-number-format'
import React, { Fragment } from 'react'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'

const RefundPubComListItem = ({
  // firstName,
  email,
  requestTime,
  num,
  bankBook,
  certificate,
  taxBill,
  type,
  bank,
  number
}) => {
  return (
    <tr>
      {/* <td className="ov-h whs-nw tov-e">{`${firstName}(${email})`}</td> */}
      <td className="ov-h whs-nw tov-e">
        <a data-for="email" data-tip={email}>
          {email}
        </a>
        <ReactTooltip id="email" place="top" type="info" effect="solid" />
      </td>
      <td className="ov-h whs-nw tov-e">
        <a
          data-for="requestTime"
          data-tip={moment(requestTime).format('YYYY-MM-DD hh:mm:ss')}
        >
          {moment(requestTime).format('YYYY-MM-DD hh:mm:ss')}
        </a>
        <ReactTooltip id="requestTime" place="top" type="info" effect="solid" />
      </td>
      <td className="text-right">
        <NumberFormat
          value={Math.floor(num)}
          displayType="text"
          thousandSeparator={true}
          prefix="&#8361; "
        />
      </td>
      <td className="ov-h whs-nw tov-e">
        <a
          data-for="bankBook"
          data-tip={bankBook}
          href={`${process.env.MAT_HOST}/bankbook/${bankBook}`}
          target="_blank"
        >
          {bankBook}
        </a>
        <ReactTooltip id="bankBook" place="top" type="info" effect="solid" />
      </td>
      <td className="ov-h whs-nw tov-e">
        <a
          data-for="certificate"
          data-tip={certificate}
          href={`${process.env.MAT_HOST}/certificate/${certificate}`}
          target="_blank"
        >
          {certificate}
        </a>
        <ReactTooltip id="certificate" place="top" type="info" effect="solid" />
      </td>
      <td className="ov-h whs-nw tov-e">
        <a
          href={`${process.env.MAT_HOST}/taxinvoice/${taxBill}`}
          target="_blank"
        >
          {taxBill}
        </a>
      </td>
      <td className="ov-h whs-nw tov-e">{bank}</td>
      <td className="ov-h whs-nw tov-e">{number}</td>
      <td>
        {type === 1 ? (
          <span className="text-danger">입금전</span>
        ) : (
          <span className="text-success">입금완료</span>
        )}
      </td>
    </tr>
  )
}

const RefundPubComList = ({ pagination, list, keyword, handleFilter2 }) => {
  return (
    <Fragment>
      <div className="row justify-content-md-end mB-10">
        <div className="col-md-2">
          <input
            type="search"
            name="keyword"
            className="form-control"
            placeholder="이메일"
            value={keyword}
            onChange={handleFilter2}
          />
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered table-fixed">
          <thead className="thead-light">
            <tr>
              <th>이메일</th>
              <th>환급신청일</th>
              <th>환급신청액</th>
              <th>통장사본</th>
              <th>사업자등록증</th>
              <th>세금계산서</th>
              <th>입금은행</th>
              <th>입금 계좌번호</th>
              <th>진행사항</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((item) => {
                const {
                  FirstName,
                  Email,
                  RequestTime,
                  CompleteTime,
                  PubID,
                  Num,
                  BankBook,
                  Certificate,
                  TaxBill,
                  Type,
                  Bank,
                  Number: number
                } = item.toJS()
                return (
                  <RefundPubComListItem
                    key={PubID}
                    firstName={FirstName}
                    email={Email}
                    requestTime={RequestTime}
                    completeTime={CompleteTime}
                    pubID={PubID}
                    num={Num}
                    bankBook={BankBook}
                    certificate={Certificate}
                    taxBill={TaxBill}
                    type={Type}
                    bank={Bank}
                    number={number}
                  />
                )
              })}
          </tbody>
        </table>
      </div>
      <div className="d-f jc-fe">{pagination}</div>
    </Fragment>
  )
}

export default RefundPubComList
