import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { forEach } from 'lodash'
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import React, { Component, Fragment } from 'react'
import debounce from 'lodash/debounce'

import MessageModal from 'components/common/MessageModal'
import PromotionList from 'components/promotion/PromotionList'

import * as baseActions from 'store/modules/base'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import * as promotionActions from 'store/modules/promotion'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { delay, jsonValueByKey, loadingDelay } from 'libs/common'
import { ekey } from 'libs/enums'
import { proAPI } from 'api'
import { stkeys, storage } from 'libs/storage'

class PromotionListContainer extends Component {
  state = {
    product: '',
    billingType: '',
    proStatus: '',
    productOptions: [],
    billingTypeOptions: [],
    statusOptions: [],
    keyword: '',
    pageNo: 1,
    pageSize: 10,
    timeoutID: 0,
    showBid: false
  }

  async componentDidMount() {
    const { PromotionActions } = this.props

    const { productOptions } = this.state
    const pOptions = []
    forEach(adtier0.PRODUCT, (value, key) => {
      if (adtier0.PRODUCT.FIRST < value && adtier0.PRODUCT.LAST > value) {
        let text = ''
        if (key === 'BANNER') {
          text = '배너'
        } else if (key === 'INTERSTITIAL') {
          text = '전면'
        } else {
          text = '비디오'
        }

        pOptions.push({
          key,
          value,
          text
        })
      }
    })

    this.setState({
      productOptions: productOptions.concat(pOptions)
    })

    const { billingTypeOptions } = this.state
    const bOptions = []
    forEach(adtier0.BILLING_TYPE, (value, key) => {
      if (
        adtier0.BILLING_TYPE.FIRST < value &&
        adtier0.BILLING_TYPE.LAST > value
      ) {
        if (key !== 'CPI' && key !== 'CPA') {
          bOptions.push({
            key,
            value
          })
        }
      }
    })

    this.setState({
      billingTypeOptions: billingTypeOptions.concat(bOptions)
    })

    const { statusOptions } = this.state
    const sOptions = []
    forEach(adtier0.PROMOTION_STATUS, (value, key) => {
      if (
        adtier0.PROMOTION_STATUS.FIRST < value &&
        adtier0.PROMOTION_STATUS.LAST > value
      ) {
        let text = ''
        if (key === 'WAITING') {
          text = '대기'
        } else if (key === 'INPROGRESS') {
          text = '진행'
        } else {
          text = '완료'
        }

        sOptions.push({
          key,
          value,
          text
        })
      }
    })

    this.setState({
      statusOptions: statusOptions.concat(sOptions)
    })

    PromotionActions.init()

    const { pageNo } = this.state
    await this.getPromotionList(pageNo)
    await this.getBidCost()
  }

  componentWillUnmount() {
    const { timeoutID } = this.state
    if (timeoutID !== 0) {
      clearTimeout(timeoutID)
    }
  }

  getPromotionList = async (pageNo) => {
    const { product, billingType, proStatus, keyword, pageSize } = this.state
    const {
      BaseActions,
      MeActions,
      ModalActions,
      PromotionActions
    } = this.props

    try {
      BaseActions.setLoadingActive(true)
      await PromotionActions.getPromotionList({
        product,
        billingType,
        status: proStatus,
        keyword,
        pageNo,
        pageSize
      })
      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      let e = error
      if (!e) {
        const { status } = err.response
        if (status === 401) {
          e = 'NEED_SIGNIN'
        }
      }

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(e)
      })
    }
  }

  handlePageChange = async (pageNo) => {
    await this.getPromotionList(pageNo)
    this.setState({
      pageNo
    })
  }

  handleChangeProduct = async (e) => {
    this.setState(
      {
        product: e.target.value
      },
      async () => {
        await this.getPromotionList(1)
      }
    )
  }

  handleChangeBillingType = async (e) => {
    this.setState(
      {
        billingType: e.target.value
      },
      async () => {
        await this.getPromotionList(1)
      }
    )
  }

  handleChangeStatus = async (e) => {
    this.setState(
      {
        proStatus: e.target.value
      },
      async () => {
        await this.getPromotionList(1)
      }
    )
  }

  debouncedFilter = debounce(async () => {
    await this.getPromotionList(1)
    this.setState({
      pageNo: 1
    })
  }, 500)

  handleFilter = (e) => {
    this.setState(
      {
        keyword: e.target.value
      },
      () => {
        this.debouncedFilter()
      }
    )
  }

  getBidCost = async () => {
    const { PromotionActions, ModalActions, MeActions } = this.props

    try {
      await PromotionActions.getBidCost()

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }

      const timeoutID = setTimeout(this.getBidCost, 5000)
      this.setState({
        timeoutID
      })
    } catch (err) {
      const { error } = this.props

      let e = error
      if (!e) {
        const { status } = err.response
        if (status === 401) {
          e = 'NEED_SIGNIN'
        }
      }

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(e)
      })
    }
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  handleShowBid = () => {
    const { showBid } = this.state
    this.setState({
      showBid: !showBid
    })
  }

  handleChangeHiddenCost = (e) => {
    const { PromotionActions } = this.props
    const proID = e.target.name.split('-')[1]
    const hiddenCost = e.target.value.replace(/[^0-9]/g, '')

    PromotionActions.setHiddenCost({ proID, hiddenCost })
  }

  handleModifyHiddenCost = async (proID, hiddenCost) => {
    const { ModalActions } = this.props

    try {
      const { data } = await proAPI.updatePromotion({ proID, hiddenCost })
      if (data.error === 'SUCCESS') {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '수정되었습니다.'
        })
      }
    } catch (err) {
      const { error } = this.props

      let e = error
      if (!e) {
        const { status } = err.response
        if (status === 401) {
          e = 'NEED_SIGNIN'
        }
      }

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(e)
      })
    }
  }

  render() {
    const {
      product,
      billingType,
      proStatus,
      productOptions,
      billingTypeOptions,
      statusOptions,
      keyword,
      pageNo,
      pageSize,
      showBid
    } = this.state

    const { rowCount, list, bidCost, messageModal } = this.props

    const {
      handlePageChange,
      handleChangeProduct,
      handleChangeBillingType,
      handleChangeStatus,
      handleFilter,
      handleOK,
      handleShowBid,
      handleChangeHiddenCost,
      handleModifyHiddenCost
    } = this

    return (
      <Fragment>
        <PromotionList
          pagination={
            <Pagination
              activePage={pageNo}
              itemsCountPerPage={pageSize}
              totalItemsCount={rowCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          }
          product={product}
          billingType={billingType}
          status={proStatus}
          productOptions={productOptions}
          billingTypeOptions={billingTypeOptions}
          statusOptions={statusOptions}
          keyword={keyword}
          list={list}
          bidCost={bidCost}
          showBid={showBid}
          handleChangeProduct={handleChangeProduct}
          handleChangeBillingType={handleChangeBillingType}
          handleChangeStatus={handleChangeStatus}
          handleFilter={handleFilter}
          handleShowBid={handleShowBid}
          handleChangeHiddenCost={handleChangeHiddenCost}
          handleModifyHiddenCost={handleModifyHiddenCost}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.promotion.get('error'),
    rowCount: state.promotion.get('rowCount'),
    list: state.promotion.get('list'),
    bidCost: state.promotion.get('bidCost'),
    newToken: state.promotion.get('newToken'),
    user: state.me.get('user'),
    messageModal: state.modal.get('message').toJS()
  }),
  (dispatch) => ({
    BaseActions: bindActionCreators(baseActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch),
    PromotionActions: bindActionCreators(promotionActions, dispatch)
  })
)(withRouter(PromotionListContainer))
