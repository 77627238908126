import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React, { Component, Fragment } from 'react'

import MaintenanceForm from 'components/maintenance/MaintenanceForm'
import MessageModal from 'components/common/MessageModal'

import * as baseActions from 'store/modules/base'
import * as maintenanceActions from 'store/modules/maintenance'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { delay, jsonValueByKey } from 'libs/common'
import { ekey } from 'libs/enums'
import { stkeys, storage } from 'libs/storage'

class MaintenanceFormContainer extends Component {
  componentDidMount() {
    const { user, history } = this.props
    if (!user) {
      history.push('/signin')
    }
  }

  handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file && file.name !== 'check.json') {
      const { ModalActions } = this.props
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: '파일명은 check.json 으로 등록해주세요.'
      })

      e.target.value = null

      return
    }

    const { MaintenanceActions } = this.props
    MaintenanceActions.setFile(file)
  }

  handleUpload = async () => {
    const {
      file,
      BaseActions,
      MaintenanceActions,
      MeActions,
      ModalActions
    } = this.props

    if (!file) {
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: '파일을 등록해주세요.'
      })
      return
    }

    try {
      const data = new FormData()
      data.append('uploadFile', file)

      BaseActions.setLoadingActive(true)
      await delay(1000)
      await MaintenanceActions.upload(data)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '등록되었습니다.'
        })

        MaintenanceActions.setType('text')
        MaintenanceActions.setType('file')
        MaintenanceActions.setFile(null)
      } else {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  render() {
    const { type, messageModal, location } = this.props
    const { handleFileChange, handleUpload, handleOK } = this

    return (
      <Fragment>
        <MaintenanceForm
          type={type}
          handleFileChange={handleFileChange}
          handleUpload={handleUpload}
          location={location}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.maintenance.get('error'),
    type: state.maintenance.get('type'),
    file: state.maintenance.get('file'),
    user: state.me.get('user'),
    messageModal: state.modal.get('message').toJS()
  }),
  (dispatch) => ({
    BaseActions: bindActionCreators(baseActions, dispatch),
    MaintenanceActions: bindActionCreators(maintenanceActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(MaintenanceFormContainer))
