import NumberFormat from 'react-number-format'
import React, { Fragment } from 'react'
import cx from 'classnames'

import RevenuePubMediaListItem from 'components/revenue/RevenuePubMediaListItem'

const RevenuePubAppListItem = ({
  appID,
  custID,
  name,
  inventory,
  advertiser,
  revenue,
  impression,
  click,
  ctr,
  install,
  // action,
  pubID,
  pubAppID,
  summaryWithMedia,
  getMediaSummary
}) => {
  return (
    <Fragment>
      <tr
        className={cx(
          { 'bg-success': appID === pubAppID },
          { 'text-white': appID === pubAppID }
        )}
      >
        <td className="pL-30">
          {appID === pubAppID ? (
            <span className="icon-holder">
              <i className="c-white-500 ti-minus" />
            </span>
          ) : (
            <span className="icon-holder">
              <i className="c-blue-500 ti-plus" />
            </span>
          )}{' '}
          <a onClick={() => getMediaSummary(custID, appID)}>{name}</a>
        </td>
        <td className="text-right">
          <NumberFormat
            value={inventory}
            displayType="text"
            thousandSeparator={true}
          />
        </td>
        <td className="text-right">
          <NumberFormat
            value={advertiser}
            displayType="text"
            thousandSeparator={true}
          />
        </td>
        <td className="text-right">
          <NumberFormat
            value={revenue}
            displayType="text"
            thousandSeparator={true}
            prefix={'\\ '}
          />
        </td>
        <td className="text-right">
          <NumberFormat
            value={impression}
            displayType="text"
            thousandSeparator={true}
          />
        </td>
        <td className="text-right">
          <NumberFormat
            value={click}
            displayType="text"
            thousandSeparator={true}
          />
        </td>
        <td className="text-right">{ctr} %</td>
        <td className="text-right">
          <NumberFormat
            value={install}
            displayType="text"
            thousandSeparator={true}
          />
        </td>
        {/* <td className="text-right">
          <NumberFormat
            value={action}
            displayType="text"
            thousandSeparator={true}
          />
        </td> */}
      </tr>
      {custID === pubID &&
        appID === pubAppID &&
        summaryWithMedia &&
        summaryWithMedia.map((item) => {
          const {
            MedID,
            Name,
            inventory: medInventory,
            advertiser: medAdvertiser,
            revenue: medRevenue,
            impression: medImpression,
            click: medClick,
            CTR: medCTR,
            Install: medInstall,
            Action: medAction
          } = item.toJS()
          return (
            <RevenuePubMediaListItem
              key={MedID}
              name={Name}
              inventory={medInventory}
              advertiser={medAdvertiser}
              revenue={medRevenue}
              impression={medImpression}
              click={medClick}
              ctr={medCTR}
              install={medInstall}
              action={medAction}
            />
          )
        })}
    </Fragment>
  )
}

export default RevenuePubAppListItem
