import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import React, { Component } from 'react'

import HorizontalInput from 'components/common/HorizontalInput'

import * as meActions from 'store/modules/me'
import * as metaDataActions from 'store/modules/metadata'
import * as validate from 'libs/validate'
import { stkeys, storage } from 'libs/storage'

class MetaDataEthForm extends Component {
  async componentDidMount() {
    const { MetaDataActions } = this.props
    await MetaDataActions.getEth()

    const user = storage.get(stkeys.user)
    if (user.Group !== 'ADMINISTRATORS') {
      MetaDataActions.setPrivateKey()
    }
  }

  render() {
    const { pristine, submitting, handleSubmit } = this.props

    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="Address"
          label="주소"
          type="text"
          component={HorizontalInput}
          validate={[validate.required, validate.ethaddr]}
        />
        <Field
          name="PrivKey"
          label="개인키"
          type="text"
          component={HorizontalInput}
          validate={validate.required}
        />
        <Field
          name="WithdrawablePoint"
          label="출금가능 이더(wei)"
          type="text"
          component={HorizontalInput}
          validate={[validate.required, validate.number]}
        />
        <div className="text-right mrg-top-30">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={pristine || submitting}
          >
            저장
          </button>
        </div>
      </form>
    )
  }
}

let MetaDataEthReduxForm = reduxForm({
  form: 'metaDataEthForm',
  enableReinitialize: true
})(MetaDataEthForm)

MetaDataEthReduxForm = connect(
  (state) => ({
    user: state.me.get('user'),
    initialValues: state.metadata.get('eth') && state.metadata.get('eth').toJS()
  }),
  (dispatch) => ({
    MeActions: bindActionCreators(meActions, dispatch),
    MetaDataActions: bindActionCreators(metaDataActions, dispatch)
  })
)(MetaDataEthReduxForm)

export default MetaDataEthReduxForm
