import { defaultClient as axios } from 'libs/client'

const home = async (params) => {
  const response = await axios.post('api/v1/home/', params)
  return response
}

export default {
  home
}
