import React, { Fragment } from 'react'

import ApiKeyRequestListItem from 'components/apikeyrequest/ApiKeyRequestListItem'

const ApiKeyRequestList = ({
  pagination,
  keyword,
  list,
  handleFilter,
  handleModify
}) => {
  return (
    <Fragment>
      <div className="row justify-content-md-end mB-10">
        <div className="col-md-2">
          <input
            type="search"
            name="keyword"
            className="form-control"
            placeholder="앱 이름"
            value={keyword}
            onChange={handleFilter}
          />
        </div>
      </div>

      <div className="table-responsive table-scroll-y">
        <table
          className="table table-bordered table-fixed"
          cellSpacing="0"
          width="100%"
        >
          <thead className="thead-light">
            <tr>
              <th className="w-20">ID</th>
              <th className="w-20">AppID</th>
              <th className="w-20">Platform</th>
              <th className="w-20">Name</th>
              <th className="w-20" />
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((item) => {
                const { RequestID, AppID, Platform, Name } = item.toJS()
                return (
                  <ApiKeyRequestListItem
                    key={RequestID}
                    requestID={RequestID}
                    appID={AppID}
                    platform={Platform}
                    name={Name}
                    handleModify={handleModify}
                  />
                )
              })}
          </tbody>
        </table>
      </div>
      <div className="d-f jc-fe mT-10">{pagination}</div>
    </Fragment>
  )
}

export default ApiKeyRequestList
