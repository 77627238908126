import React from 'react'

import EthAskListContainer from 'containers/eth/EthAskListContainer'
import EthAskTemplate from 'components/ethask/EthAskTemplate'
import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'
import TrafficLogContainer from 'containers/eth/TrafficLogContainer'

const EthAsk = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <EthAskTemplate
        ethAskList={<EthAskListContainer />}
        trafficLog={<TrafficLogContainer />}
      />
    </PageTemplateContainer>
  )
}

export default EthAsk
