import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React, { Component, Fragment } from 'react'

import * as modalActions from 'store/modules/modal'
import * as registerActions from 'store/modules/register'
import { adtier0 } from 'enums/adtier0'
import { ekey } from 'libs/enums'
import { jsonValueByKey, tokenExpired } from 'libs/common'
import { stkeys, storage } from 'libs/storage'
import MessageModal from 'components/common/MessageModal'
import SignupForm from 'components/account/signup/SignupForm'

class SignupFormContainer extends Component {
  componentDidMount() {
    this.checkUser()

    const { RegisterActions } = this.props
    RegisterActions.initialize()
  }

  checkUser = () => {
    const user = storage.get(stkeys.user)
    const accessToken = storage.get(stkeys.accessToken)

    if (user && accessToken) {
      const accessTokenExpired = tokenExpired(accessToken)

      if (!accessTokenExpired) {
        const { history } = this.props
        history.push('/')
      }
    }
  }

  handleSubmit = async (values) => {
    const { id, firstName, lastName, email, pw } = values
    const { RegisterActions, ModalActions } = this.props

    const params = {
      id,
      firstName,
      lastName,
      email,
      pw
    }

    try {
      await RegisterActions.signup(params)

      const { error } = this.props
      if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '회원가입 요청이 완료 되었습니다.\n관리자 수락 후 사용가능합니다.'
        })
      } else {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }
    } catch(err) {
      const { error } = this.props
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleOK = () => {
    const { error, ModalActions, history } = this.props
    ModalActions.closeMessageModal()
    if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
      history.push('/signin')
    }
  }

  render() {
    const { id, firstName, lastName, email, pw, pwConfirm, messageModal, pristine, submitting } = this.props
    const { handleSubmit, handleOK } = this
    return (
      <Fragment>
        <SignupForm
          id={id}
          firstName={firstName}
          lastName={lastName}
          email={email}
          pw={pw}
          pwConfirm={pwConfirm}
          pristine={pristine}
          submitting={submitting}
          onSubmit={handleSubmit}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    loading: state.register.get('loading'),  
    error: state.register.get('error'),
    messageModal: state.modal.get('message').toJS()
  }),
  (dispatch) => ({
    RegisterActions: bindActionCreators(registerActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(SignupFormContainer))
