import { Field, reduxForm } from 'redux-form'
import React from 'react'

import HorizontalInput from 'components/common/HorizontalInput'
import HorizontalTextArea from 'components/common/HorizontalTextArea'

import * as validate from 'libs/validate'

const MailForm = ({
  disabled,
  pristine,
  submitting,
  handleTypeChange,
  handleSubmit
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">수신</label>
        <div className="col-sm-10">
          <Field
            name="type"
            component="select"
            className="form-control"
            onChange={handleTypeChange}
          >
            <option value="">전체</option>
            <option value="ADVERTISER">광고주</option>
            <option value="PUBLISHER">퍼블리셔</option>
            <option value="INPUT">직접입력</option>
          </Field>
        </div>
      </div>

      <Field
        name="to"
        label=""
        placeholder="수신"
        type="text"
        disabled={disabled}
        component={HorizontalInput}
        validate={disabled ? null : validate.required}
      />

      <Field
        name="subject"
        label="제목"
        type="text"
        component={HorizontalInput}
        validate={validate.required}
      />

      <Field
        name="content"
        label="내용"
        rows="10"
        component={HorizontalTextArea}
        validate={validate.required}
      />

      <div className="text-right mT-20">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={pristine || submitting}
        >
          전송
        </button>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'mailForm',
  enableReinitialize: true,
  initialValues: {
    type: ''
  }
})(MailForm)
