import React from 'react'

import SectionWithTitle from 'components/common/SectionWithTitle'

const PromotionTemplate = ({ children }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <SectionWithTitle title="프로모션(광고주)">{children}</SectionWithTitle>
      </div>
    </div>
  )
}

export default PromotionTemplate
