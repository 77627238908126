import React from 'react'

import ModalWrapper from 'components/common/ModalWrapper'

const CompleteModal = ({
  open,
  handleFileChange,
  handleUpload,
  handleUploadCancel
}) => {
  return (
    <ModalWrapper open={open}>
      <div className="MessageModal">
        <div className="modal-content">
          {<h4>세금계산서 등록</h4>}
          <p>
            <input
              type="file"
              className="form-control-file"
              onChange={handleFileChange}
            />
          </p>
          <br />
          <p>
            개발사로부터 발급된 세금계산서를 아래 형식에 맞춰 파일명을 수정한 후
            첨부해주세요. <br />
            첨부파일 형식(
            <span className="text-danger">name_YYYYMMDD.html 파일 형식</span>)
          </p>
          <br />
          <br />
          <p>
            세금계산서 확인 후 퍼블리셔에게 수익을 전달 하였다면 아래 입금 완료
            버튼을 눌러 해당 내용을 완료 시키세요.
          </p>
          <div className="button-area">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleUpload}
            >
              파일 첨부
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleUploadCancel}
            >
              취소
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default CompleteModal
