import React from 'react'

import QuillTestContainer from 'containers/quill/QuillTestContainer'

import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import QuillTestTemplate from 'components/quill/QuillTestTemplate'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const QuillTest = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <QuillTestTemplate>
        <QuillTestContainer />
      </QuillTestTemplate>
    </PageTemplateContainer>
  )
}

export default QuillTest
