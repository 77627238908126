import React from 'react'

import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'
import XplaUserTemplate from 'components/xplayzuser/XplayzUserTemplate'
import XplayzUserListContainer from 'containers/xplayz/XplayzUserListContainer'

const XplayzUser = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <XplaUserTemplate>
        <XplayzUserListContainer />
      </XplaUserTemplate>
    </PageTemplateContainer>
  )
}

export default XplayzUser
