import React, { Fragment } from 'react'

import HorizontalPlainText from 'components/common/HorizontalPlainText'

const ProfileForm = ({ adminID, firstName, lastName }) => {
  return (
    <Fragment>
      <HorizontalPlainText label="아이디" value={adminID} />
      <HorizontalPlainText label="이름" value={firstName} />
      <HorizontalPlainText label="성" value={lastName} />
    </Fragment>
  )
}

ProfileForm.defaultProps = {
  adminID: '',
  firstName: '',
  lastName: ''
}

export default ProfileForm
