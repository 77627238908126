import decode from 'jwt-decode'
import json from 'json/error.json'

export const loadingDelay = 500

export const jsonValueByKey = (key) => {
  if (json[key]) {
    return json[key]
  }

  return json.UNKNOWN
}

const tokenExpirationDate = (encodedToken) => {
  const token = decode(encodedToken)
  if (!token.exp) {
    return null
  }
  const date = new Date(0)
  date.setUTCSeconds(token.exp)
  return date
}

export const tokenExpired = (token) => {
  const expirationDate = tokenExpirationDate(token)
  const now = new Date()
  return expirationDate < now
}

export const delay = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}
