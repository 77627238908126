import React from 'react'

import MetaDataEthFormContainer from 'containers/metadata/MetaDataEthFormContainer'
import MetaDataListContainer from 'containers/metadata/MetaDataListContainer'
import MetaDataTemplate from 'components/metadata/MetaDataTemplate'
import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const MetaData = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <MetaDataTemplate form={<MetaDataEthFormContainer />}>
        <MetaDataListContainer />
      </MetaDataTemplate>
    </PageTemplateContainer>
  )
}

export default MetaData
