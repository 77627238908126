import React from 'react'

const SectionWithTitle = ({ title, children }) => {
  return (
    <div className="bgc-white p-20 bd">
      <h6 className="c-grey-900">{title}</h6>
      <div className="mT-30">{children}</div>
    </div>
  )
}

export default SectionWithTitle
