import { Chart } from 'react-google-charts'
import React from 'react'

const LineChart = ({ chartData, options }) => {
  return (
    <Chart
      chartType="LineChart"
      width="100%"
      height="500px"
      loader={<div>Loading Chart</div>}
      data={chartData}
      options={options}
    />
  )
}

export default LineChart
