import React, { Fragment } from 'react'

import SectionWithTitle from 'components/common/SectionWithTitle'

const EthAskTemplate = ({ ethAskList, trafficLog }) => {
  return (
    <Fragment>
      <div className="row mB-20">
        <div className="col-md-12">
          <SectionWithTitle title="암호화폐 문의 내역">
            {ethAskList}
          </SectionWithTitle>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <SectionWithTitle title="부정 로그">{trafficLog}</SectionWithTitle>
        </div>
      </div>
    </Fragment>
    // <div>
    //   <div className="mB-20">{ethAskList}</div>
    //   <div>{trafficLog}</div>
    // </div>
  )
}

export default EthAskTemplate
