import { List, Map, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { xplayzAPI } from 'api'

// action type
const INIT = 'xplayztx/INIT'

const GET_LIST = 'xplayztx/GET_LIST'
const GET_LIST_LOADING = 'xplayztx/GET_LIST_LOADING'
const GET_LIST_SUCCESS = 'xplayztx/GET_LIST_SUCCESS'
const GET_LIST_FAILURE = 'xplayztx/GET_LIST_FAILURE'

// action creator
export const init = createAction(INIT)

export const getList = (params) => ({
  type: GET_LIST,
  payload: xplayzAPI.xplayzTxCountList(params)
})

// initial state
const initialState = Map({
  error: '',
  rowCount: 0,
  list: List(),
  newToken: '',
  loading: false
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [GET_LIST_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_LIST_SUCCESS]: (state, action) => {
      const { error, rows: list, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('list', fromJS(list))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_LIST_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    }
  },
  initialState
)
