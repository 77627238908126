import { Tab, Tabs } from 'react-bootstrap'
import React from 'react'

const RefundPubTab = ({ first, second, handleTabSelect }) => {
  return (
    <Tabs
      defaultActiveKey="first"
      id="uncontrolled-tab-example"
      onSelect={(key) => handleTabSelect(key)}
    >
      <Tab eventKey="first" title="환급 요청 및 진행">
        <div className="mT-20">{first}</div>
      </Tab>
      <Tab eventKey="second" title="환급 완료">
        <div className="mT-20">{second}</div>
      </Tab>
    </Tabs>
  )
}

export default RefundPubTab
