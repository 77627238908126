import React from 'react'

import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import RevenuePubListContainer from 'containers/revenue/RevenuePubListContainer'
import RevenuePubTemplate from 'components/revenue/RevenuePubTemplate'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const RevenuePub = () => {
  return (
    <PageTemplateContainer sidebar={<SidebarContainer />} topbar={<TopbarContainer />}>
      <RevenuePubTemplate>
        <RevenuePubListContainer />
      </RevenuePubTemplate>
    </PageTemplateContainer>
  )
}

export default RevenuePub
