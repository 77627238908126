import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import React from 'react'
import cx from 'classnames'

import * as validate from 'libs/validate'
import BasicInput from 'components/common/BasicInput'

const SigninForm = ({ pristine, submitting, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="id"
        id="id"
        type="text"
        label="ID"
        component={BasicInput}
        validate={validate.required}
      />
      <Field
        name="pw"
        id="pw"
        type="password"
        label="Password"
        component={BasicInput}
        validate={[validate.required]}
      />
      <div className="form-group">
        <div className="peers ai-c jc-sb fxw-nw">
          <div className="peer">
            <Link to="/signup">Sign-Up</Link>
          </div>
          <div className="peer">
            <button
              type="submit"
              className={cx('btn', 'btn-primary')}
              disabled={pristine || submitting}
            >
              Signin
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'signinForm'
})(SigninForm)
