import NumberFormat from 'react-number-format'
import React, { Fragment } from 'react'
import ReactTooltip from 'react-tooltip'
import cx from 'classnames'

import RevenuePubAppListItem from 'components/revenue/RevenuePubAppListItem'

const RevenuePubListItem = ({
  custID,
  // lastName,
  // firstName,
  email,
  inventory,
  advertiser,
  revenue,
  impression,
  click,
  ctr,
  install,
  // action,
  pubID,
  summaryWithApp,
  pubAppID,
  summaryWithMedia,
  getAppSummary,
  getMediaSummary
}) => {
  return (
    <Fragment>
      <tr
        className={cx(
          { 'bg-danger': custID === pubID },
          { 'text-white': custID === pubID }
        )}
      >
        <td className="ov-h whs-nw tov-e">
          {custID === pubID ? (
            <span className="jvectormap-zoomin">
              <i className="c-white-500 ti-minus" />
            </span>
          ) : (
            <span className="icon-holder">
              <i className="c-blue-500 ti-plus" />
            </span>
          )}{' '}
          {/* <a
            data-for="custID"
            data-tip={`${lastName} ${firstName}(${email})`}
            onClick={() => getAppSummary(custID)}
          >
            {`${lastName} ${firstName}(${email})`}
          </a> */}
          <a
            data-for="email"
            data-tip={email}
            onClick={() => getAppSummary(custID)}
          >
            {email}
          </a>
          <ReactTooltip id="email" place="top" type="info" effect="solid" />
        </td>
        <td className="text-right">
          <NumberFormat
            value={inventory}
            displayType="text"
            thousandSeparator={true}
          />
        </td>
        <td className="text-right">
          <NumberFormat
            value={advertiser}
            displayType="text"
            thousandSeparator={true}
          />
        </td>
        <td className="text-right">
          <NumberFormat
            value={Math.floor(revenue)}
            displayType="text"
            thousandSeparator={true}
            prefix={'\\ '}
          />
        </td>
        <td className="text-right">
          <NumberFormat
            value={impression}
            displayType="text"
            thousandSeparator={true}
          />
        </td>
        <td className="text-right">
          <NumberFormat
            value={click}
            displayType="text"
            thousandSeparator={true}
          />
        </td>
        <td className="text-right">{ctr} %</td>
        <td className="text-right">
          <NumberFormat
            value={install}
            displayType="text"
            thousandSeparator={true}
          />
        </td>
        {/* <td className="text-right">
          <NumberFormat
            value={action}
            displayType="text"
            thousandSeparator={true}
          />
        </td> */}
      </tr>
      {custID === pubID &&
        summaryWithApp &&
        summaryWithApp.map((item, index) => {
          const {
            AppID,
            Name,
            inventory: appInventory,
            advertiser: appAdvertiser,
            revenue: appRevenue,
            impression: appImpression,
            click: appClick,
            CTR: appCTR,
            Install: appInstall,
            Action: appAction
          } = item.toJS()
          return (
            <RevenuePubAppListItem
              key={index}
              appID={AppID}
              custID={custID}
              name={Name}
              inventory={appInventory}
              advertiser={appAdvertiser}
              revenue={appRevenue}
              impression={appImpression}
              click={appClick}
              ctr={appCTR}
              install={appInstall}
              action={appAction}
              pubID={pubID}
              pubAppID={pubAppID}
              summaryWithMedia={summaryWithMedia}
              getMediaSummary={getMediaSummary}
            />
          )
        })}
    </Fragment>
  )
}

export default RevenuePubListItem
