import DatePicker from 'react-datepicker'
import React, { Fragment } from 'react'

import KPIListItem from 'components/kpi/KPIListItem'

import 'react-datepicker/dist/react-datepicker.css'

const KPIList = ({
  pagination,
  startDate,
  endDate,
  keyword,
  summary,
  custID,
  summaryWithApp,
  appID,
  summaryWithDaily,
  getAppSummary,
  getDailySummary,
  handleStartDate,
  handleEndDate,
  handleFilter
}) => {
  return (
    <Fragment>
      <div className="row justify-content-md-end mB-10">
        <div className="col-md-2">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            className="form-control"
            placeholderText="시작일"
            selected={startDate}
            name="startDate"
            onChange={handleStartDate}
          />
        </div>
        <div className="col-md-2">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            className="form-control"
            placeholderText="종료일"
            selected={endDate}
            name="endDate"
            onChange={handleEndDate}
          />
        </div>
        <div className="col-md-2">
          <input
            type="search"
            name="keyword"
            className="form-control"
            placeholder="이메일"
            value={keyword}
            onChange={handleFilter}
          />
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered table-fixed">
          <thead className="thead-light">
            <tr>
              <th className="w-30p">이름</th>
              <th>DAU</th>
              <th>NRU</th>
              <th>ARU</th>
            </tr>
          </thead>
          <tbody>
            {summary &&
              summary.map((item) => {
                const {
                  CustID,
                  LastName,
                  FirstName,
                  Email,
                  DAU,
                  NRU,
                  ARU
                } = item.toJS()
                return (
                  <KPIListItem
                    key={CustID}
                    custID={CustID}
                    lastName={LastName}
                    firstName={FirstName}
                    email={Email}
                    dau={DAU}
                    nru={NRU}
                    aru={ARU}
                    pubID={custID}
                    summaryWithApp={summaryWithApp}
                    pubAppID={appID}
                    summaryWithDaily={summaryWithDaily}
                    getAppSummary={getAppSummary}
                    getDailySummary={getDailySummary}
                  />
                )
              })}
          </tbody>
        </table>
      </div>
      <div className="d-f jc-sb">
        <div>
          <button type="button" className="btn btn-info">
            내보내기
          </button>
        </div>
        {pagination}
      </div>
    </Fragment>
  )
}

export default KPIList
