import React from 'react'

import SectionWithTitle from 'components/common/SectionWithTitle'

const NoticeTemplate = ({ children }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <SectionWithTitle title="공지사항 등록">{children}</SectionWithTitle>
      </div>
    </div>
  )
}

export default NoticeTemplate
