import React from 'react'

import HorizontalPlainText from 'components/common/HorizontalPlainText'

const EthAskForm = ({ form, opened, handleResponse, handleUpdateResponse }) => {
  const { id, adid, email, title, content, response } = form.toJS()
  const openClass = opened ? 'd-b' : 'd-n'

  return (
    <div className={openClass}>
      <HorizontalPlainText label="ADID: " value={adid} />
      <HorizontalPlainText label="이메일: " value={email} />
      <HorizontalPlainText label="제목: " value={title} />
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">내용</label>
        <div className="col-sm-10">
          {content.split('\n').map((line, index) => {
            return (
              <span key={index}>
                {line}
                <br />
              </span>
            )
          })}
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">상태</label>
        <div className="col-sm-10">
          <select
            className="form-control"
            onChange={handleResponse}
            value={response}
          >
            {/* <option value="">활성화</option> */}
            <option value="WAITING">대기중</option>
            <option value="COMPLETE">처리됨</option>
            <option value="HOLD">보류됨</option>
          </select>
        </div>
      </div>
      <div className="text-right mT-20">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleUpdateResponse(id)}
        >
          확인
        </button>
      </div>
    </div>
  )
}

export default EthAskForm
