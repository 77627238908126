import React from 'react'

import SectionWithTitle from 'components/common/SectionWithTitle'

const AppTemplate = ({ children }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <SectionWithTitle title="앱 관리(퍼블리셔)">
          {children}
        </SectionWithTitle>
      </div>
    </div>
  )
}

export default AppTemplate
