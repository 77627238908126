import { Field, reduxForm } from 'redux-form'
import React from 'react'

// import Button from 'components/common/Button'
import HorizontalInput from 'components/common/HorizontalInput'

import * as validate from 'libs/validate'

const PasswordForm = ({ pristine, submitting, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="nowpw"
        id="nowpw"
        label="현재 비밀번호"
        type="password"
        component={HorizontalInput}
        validate={[validate.required, validate.password]}
      />
      <Field
        name="pw"
        id="pw"
        label="새 비밀번호"
        type="password"
        helpertext="비밀번호는 하나 이상의 숫자, 특수문자 !@#$%^*+=- 가 포함되어야 합니다."
        component={HorizontalInput}
        validate={[validate.required, validate.password]}
      />
      <Field
        name="pwConfirm"
        id="pwConfirm"
        label="새 비밀번호 확인"
        type="password"
        component={HorizontalInput}
        validate={[validate.required, validate.passwordsMatch]}
      />
      <div className="text-right mT-20">
        <button
          type="submit"
          className="btn btn-danger"
          disabled={pristine || submitting}
        >
          수정
        </button>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'passwordForm',
  enableReinitialize: true
})(PasswordForm)
