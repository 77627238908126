import React, { Fragment } from 'react'
import ReactTooltip from 'react-tooltip'
import cx from 'classnames'
import moment from 'moment'

const AppListItem = ({
  appID,
  email,
  company,
  platform,
  name,
  packageName,
  apiKey,
  apiKeyStatus,
  active,
  registTime,
  selectedID,
  handleShowForm
}) => {
  return (
    <tr
      className={cx(
        'cur-p',
        { 'bg-danger': selectedID === appID },
        { 'text-white': selectedID === appID }
      )}
      onClick={() =>
        handleShowForm(
          appID,
          email,
          company,
          platform,
          name,
          packageName,
          apiKey,
          apiKeyStatus,
          active,
          registTime
        )
      }
    >
      <td className="ov-h whs-nw tov-e">
        <a data-for="email" data-tip={email}>
          {email}
        </a>
        <ReactTooltip id="email" place="top" type="info" effect="solid" />
      </td>
      <td className="ov-h whs-nw tov-e">
        <a data-for="company" data-tip={company}>
          {company}
        </a>
        <ReactTooltip id="company" place="top" type="info" effect="solid" />
      </td>
      <td>{platform}</td>
      <td className="ov-h whs-nw tov-e">
        <a data-for="name" data-tip={company}>
          {name}
        </a>
        <ReactTooltip id="name" place="top" type="info" effect="solid" />
      </td>
      <td className="ov-h whs-nw tov-e">
        <a data-for="packageName" data-tip={company}>
          {packageName}
        </a>
        <ReactTooltip id="packageName" place="top" type="info" effect="solid" />
      </td>
      <td>{Number(active) === 1 ? '예' : '아니오'}</td>
      <td>{moment(registTime).format('YYYY-MM-DD hh:mm:ss')}</td>
    </tr>
  )
}

const AppList = ({
  pagination,
  form,
  keyword,
  list,
  selectedID,
  handleFilter,
  handleShowForm
}) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">앱을 선택하면 승인/비승인 가능합니다.</div>
      </div>
      <div className="row jc-fe mB-10">
        <div className="col-md-2">
          <input
            type="search"
            name="keyword"
            className="form-control"
            placeholder="이메일, 회사, 스토어 주소, 앱"
            value={keyword}
            onChange={handleFilter}
          />
        </div>
      </div>

      <div className="table-responsive table-scroll-y">
        <table
          className="table table-bordered table-fixed"
          cellSpacing="0"
          width="100%"
        >
          <thead className="thead-light">
            <tr>
              <th>이메일</th>
              <th>회사</th>
              <th>플랫폼</th>
              <th>앱</th>
              <th>스토어 주소</th>
              <th>승인</th>
              <th>등록 시간</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((item) => {
                const {
                  AppID,
                  Email,
                  Company,
                  Platform,
                  Name,
                  Package,
                  ApiKey,
                  ApiKeyStatus,
                  Active,
                  RegistTime
                } = item.toJS()
                return (
                  <AppListItem
                    key={AppID}
                    appID={AppID}
                    email={Email}
                    company={Company}
                    platform={Platform}
                    name={Name}
                    packageName={Package}
                    apiKey={ApiKey}
                    apiKeyStatus={ApiKeyStatus}
                    active={Active}
                    registTime={RegistTime}
                    selectedID={selectedID}
                    handleShowForm={handleShowForm}
                  />
                )
              })}
          </tbody>
        </table>
      </div>
      <div className="d-f jc-fe mT-20">{pagination}</div>
      {form}
    </Fragment>
  )
}

export default AppList
