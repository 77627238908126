import React from 'react'

import KPIContainer from 'containers/kpi/KPIContainer'
import KPITemplate from 'components/kpi/KPITemplate'
import PageTemplateContainer from 'containers/layout/PageTemplateContainer'
import SidebarContainer from 'containers/layout/SidebarContainer'
import TopbarContainer from 'containers/layout/TopbarContainer'

const KPI = () => {
  return (
    <PageTemplateContainer
      sidebar={<SidebarContainer />}
      topbar={<TopbarContainer />}
    >
      <KPITemplate>
        <KPIContainer />
      </KPITemplate>
    </PageTemplateContainer>
  )
}

export default KPI
