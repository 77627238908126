import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import React, { Component, Fragment } from 'react'
import debounce from 'lodash/debounce'
import moment from 'moment'

import EthAskForm from 'components/ethask/EthAskForm'
import EthAskList from 'components/ethask/EthAskList'
import EthAskMailForm from 'components/ethask/EthAskMailForm'
import MessageModal from 'components/common/MessageModal'

import * as baseActions from 'store/modules/base'
import * as ethAskActions from 'store/modules/ethask'
import * as mailActions from 'store/modules/mail'
import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { delay, jsonValueByKey, loadingDelay } from 'libs/common'
import { ekey } from 'libs/enums'
import { stkeys, storage } from 'libs/storage'

class EthAskListContainer extends Component {
  state = {
    startDate: moment()
      .subtract(6, 'days')
      .toDate(),
    endDate: moment()
      .add(1, 'days')
      .toDate(),
    response: '',
    keyword: '',
    pageNo: 1,
    pageSize: 10
  }

  async componentDidMount() {
    const { pageNo } = this.state
    const { EthAskActions } = this.props

    EthAskActions.init()

    await this.getAskList(pageNo)
  }

  getAskList = async (pageNo) => {
    const { startDate, endDate, response, keyword, pageSize } = this.state
    const { BaseActions, EthAskActions, MeActions, ModalActions } = this.props

    const start = moment(startDate)
    const end = moment(endDate)
    const duration = moment.duration(end.diff(start))
    const dayCount = duration.asDays()
    if (dayCount > 180) {
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: '검색 기간은 최대 3개월 입니다.'
      })
      return
    }

    try {
      BaseActions.setLoadingActive(true)
      await EthAskActions.getAskList({
        response,
        keyword,
        startDate,
        endDate,
        pageNo,
        pageSize
      })
      await delay(loadingDelay)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error !== ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handlePageChange = async (pageNo) => {
    const { EthAskActions } = this.props
    EthAskActions.setForm({
      id: 0,
      adid: '',
      email: '',
      title: '',
      content: '',
      response: '',
      opened: false
    })

    await this.getAskList(pageNo)
    this.setState({
      pageNo
    })
  }

  handleStartDate = (date) => {
    const { EthAskActions } = this.props
    EthAskActions.setForm({
      id: 0,
      adid: '',
      email: '',
      title: '',
      content: '',
      response: '',
      opened: false
    })

    this.setState(
      {
        startDate: date
      },
      async () => {
        await this.getAskList(1)
      }
    )
  }

  handleEndDate = (date) => {
    const { EthAskActions } = this.props
    EthAskActions.setForm({
      id: 0,
      adid: '',
      email: '',
      title: '',
      content: '',
      response: '',
      opened: false
    })

    this.setState(
      {
        endDate: date
      },
      async () => {
        await this.getAskList(1)
      }
    )
  }

  handleResponse = (e) => {
    const { EthAskActions } = this.props
    EthAskActions.setForm({
      id: 0,
      adid: '',
      email: '',
      title: '',
      content: '',
      response: '',
      opened: false
    })

    this.setState(
      {
        response: e.target.value
      },
      async () => {
        await this.getAskList(1)
      }
    )
  }

  debouncedFilter = debounce(async () => {
    await this.getAskList(1)
    this.setState({
      pageNo: 1
    })
  }, 500)

  handleFilter = (e) => {
    const { EthAskActions } = this.props
    EthAskActions.setForm({
      id: 0,
      adid: '',
      email: '',
      title: '',
      content: '',
      response: '',
      opened: false
    })

    this.setState(
      {
        keyword: e.target.value
      },
      () => {
        this.debouncedFilter()
      }
    )
  }

  handleShowForm = (id, adid, email, title, content, response) => {
    const { form, EthAskActions } = this.props

    if (form.get('id') !== id) {
      EthAskActions.setForm({
        id,
        adid,
        email,
        title,
        content,
        response,
        opened: true
      })
    } else {
      EthAskActions.setForm({
        id: 0,
        adid: '',
        email: '',
        title: '',
        content: '',
        response: '',
        opened: false
      })
    }
  }

  handleResponseForm = (e) => {
    const { EthAskActions } = this.props
    EthAskActions.setFormResponse(e.target.value)
  }

  handleResponseContent = (e) => {
    const { EthAskActions } = this.props
    EthAskActions.setReponseContent(e.target.value)
  }

  handleUpdateResponse = async (id) => {
    const { form, EthAskActions, MeActions, ModalActions } = this.props
    const { response } = form.toJS()

    try {
      await EthAskActions.updateEthAsk({
        id,
        response
      })

      const { error, newToken } = this.props

      if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        EthAskActions.changeResponse({
          id,
          response
        })
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '수정되었습니다.'
        })
      } else {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleSubmit = async (values, dispatch) => {
    const { subject, content } = values
    const {
      form,
      BaseActions,
      MailActions,
      MeActions,
      ModalActions
    } = this.props
    const { content: question, email: to } = form.toJS()

    const params = {
      question,
      to,
      subject,
      content
    }

    try {
      BaseActions.setLoadingActive(true)
      await MailActions.answerSend(params)
      BaseActions.setLoadingActive(false)

      const { error, newToken } = this.props

      if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        dispatch(reset('ethAskMailForm'))
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '전송되었습니다.'
        })
      } else {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }

      if (newToken) {
        MeActions.setAccessToken(newToken)
        storage.set(stkeys.accessToken, newToken)
        axios.defaults.headers.common[config.headers.accessToken] = newToken
      }
    } catch (err) {
      const { error } = this.props

      BaseActions.setLoadingActive(false)

      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  render() {
    const {
      startDate,
      endDate,
      response,
      keyword,
      pageNo,
      pageSize
    } = this.state
    const {
      rowCount,
      askList,
      form,
      opened,
      messageModal,
      pristine,
      submitting
    } = this.props
    const {
      handlePageChange,
      handleStartDate,
      handleEndDate,
      handleResponse,
      handleFilter,
      handleShowForm,
      handleResponseForm,
      handleUpdateResponse,
      handleSubmit,
      handleOK
    } = this

    return (
      <Fragment>
        <EthAskList
          pagination={
            <Pagination
              activePage={pageNo}
              itemsCountPerPage={pageSize}
              totalItemsCount={rowCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          }
          form={
            <EthAskForm
              form={form}
              opened={opened}
              handleResponse={handleResponseForm}
              handleUpdateResponse={handleUpdateResponse}
            />
          }
          mailForm={
            <EthAskMailForm
              opened={opened}
              pristine={pristine}
              submitting={submitting}
              onSubmit={handleSubmit}
            />
          }
          startDate={startDate}
          endDate={endDate}
          response={response}
          keyword={keyword}
          list={askList}
          selectedID={form.get('id')}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
          handleResponse={handleResponse}
          handleFilter={handleFilter}
          handleShowForm={handleShowForm}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.ethask.get('error'),
    rowCount: state.ethask.get('rowCount'),
    askList: state.ethask.get('askList'),
    form: state.ethask.get('form'),
    opened: state.ethask.get('opened'),
    newToken: state.ethask.get('newToken'),
    user: state.me.get('user'),
    messageModal: state.modal.get('message').toJS()
  }),
  (dispatch) => ({
    BaseActions: bindActionCreators(baseActions, dispatch),
    EthAskActions: bindActionCreators(ethAskActions, dispatch),
    MailActions: bindActionCreators(mailActions, dispatch),
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(EthAskListContainer))
