import { Map, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

// action type
const SET_PAGER = 'revenuepub/SET_PAGER'

// action creator
export const setPager = createAction(SET_PAGER)

// initial state
const initialState = Map({
  pageNo: 1,
  pageSize: 10,
  pager: null
})

// reducer
export default handleActions(
  {
    [SET_PAGER]: (state, action) => {
      const { pageNo, pageSize, rowCount } = action.payload
      const totalPages = Math.ceil(rowCount / pageSize)

      let startPage
      let endPage
      if (totalPages <= 10) {
        startPage = 1
        endPage = totalPages
      } else if (pageNo <= 6) {
        startPage = 1
        endPage = 10
      } else if (pageNo + 4 >= totalPages) {
        startPage = totalPages - 9
        endPage = totalPages
      } else {
        startPage = pageNo - 5
        endPage = pageNo + 4
      }

      const startIndex = (pageNo - 1) * pageSize
      const endIndex = Math.min(startIndex + pageSize - 1, rowCount - 1)
      const pages = [...Array(endPage + 1 - startPage).keys()].map(
        (i) => startPage + i
      )

      return state.set('pageNo', pageNo).set(
        'pager',
        Map({
          pageNo,
          pageSize,
          rowCount,
          totalPages,
          startPage,
          endPage,
          startIndex,
          endIndex,
          pages: fromJS(pages)
        })
      )
    }
  },
  initialState
)
