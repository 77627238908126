import { List, Map, fromJS } from 'immutable'
import { createAction, handleActions } from 'redux-actions'

import { revenueAPI } from 'api'

// action type
const INIT = 'refundpub/INIT'
const INIT_LIST = 'refundpub/INIT_LIST'

const GET_REQ_LIST = 'refundpub/GET_LIST'
const GET_REQ_LIST_LOADING = 'refundpub/GET_LIST_LOADING'
const GET_REQ_LIST_SUCCESS = 'refundpub/GET_LIST_SUCCESS'
const GET_REQ_LIST_FAILURE = 'refundpub/GET_LIST_FAILURE'

const GET_COM_LIST = 'refundpub/GET_COM_LIST'
const GET_COM_LIST_LOADING = 'refundpub/GET_COM_LIST_LOADING'
const GET_COM_LIST_SUCCESS = 'refundpub/GET_COM_LIST_SUCCESS'
const GET_COM_LIST_FAILURE = 'refundpub/GET_COM_LIST_FAILURE'

const OPEN_UPLOAD = 'refundpub/OPEN_UPLOAD'
const CLOSE_UPLOAD = 'refundpub/CLOSE_UPLOAD'
const SET_PAYMENT_ID = 'refundpub/SET_PAYMENT_ID'

const UPLOAD_TAXBILL = 'refundpub/UPLOAD_TAXBILL'
const UPLOAD_TAXBILL_LOADING = 'refundpub/UPLOAD_TAXBILL_LOADING'
const UPLOAD_TAXBILL_SUCCESS = 'refundpub/UPLOAD_TAXBILL_SUCCESS'
const UPLOAD_TAXBILL_FAILURE = 'refundpub/UPLOAD_TAXBILL_FAILURE'

const COMPLETE_PAYMENT = 'refundpub/COMPLETE_PAYMENT'
const COMPLETE_PAYMENT_LOADING = 'refundpub/COMPLETE_PAYMENT_LOADING'
const COMPLETE_PAYMENT_SUCCESS = 'refundpub/COMPLETE_PAYMENT_SUCCESS'
const COMPLETE_PAYMENT_FAILURE = 'refundpub/COMPLETE_PAYMENT_FAILURE'

// action creator
export const init = createAction(INIT)
export const initList = createAction(INIT_LIST)

export const getReqList = (params) => ({
  type: GET_REQ_LIST,
  payload: revenueAPI.revenueReportList(params)
})

export const getComList = (params) => ({
  type: GET_COM_LIST,
  payload: revenueAPI.revenueReportList(params)
})

export const openUpload = createAction(OPEN_UPLOAD)
export const closeUpload = createAction(CLOSE_UPLOAD)
export const setPaymentID = createAction(SET_PAYMENT_ID)

export const uploadTaxBill = (params) => ({
  type: UPLOAD_TAXBILL,
  payload: revenueAPI.revenueUploadTaxBill(params)
})

export const completePayment = (params) => ({
  type: COMPLETE_PAYMENT,
  payload: revenueAPI.revenueCompletePayment(params)
})

// initial state
const initialState = Map({
  error: '',
  rowCount: 0,
  list: List(),
  open: false,
  paymentID: 0,
  newToken: '',
  loading: false
})

// reducer
export default handleActions(
  {
    [INIT]: () => initialState,
    [INIT_LIST]: (state) => {
      return state.set('list', List())
    },
    [GET_REQ_LIST_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_REQ_LIST_SUCCESS]: (state, action) => {
      const { error, rowCount, rows: list, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('rowCount', rowCount)
        .set('list', fromJS(list))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_REQ_LIST_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [GET_COM_LIST_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [GET_COM_LIST_SUCCESS]: (state, action) => {
      const { error, rowCount, rows: list, newToken } = action.payload.data
      return state
        .set('error', error)
        .set('rowCount', rowCount)
        .set('list', fromJS(list))
        .set('newToken', newToken)
        .set('loading', false)
    },
    [GET_COM_LIST_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [OPEN_UPLOAD]: (state, action) => {
      return state.set('open', true).set('paymentID', action.payload)
    },
    [CLOSE_UPLOAD]: (state) => {
      return state.set('open', false).set('paymentID', 0)
    },
    [UPLOAD_TAXBILL_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [UPLOAD_TAXBILL_SUCCESS]: (state, action) => {
      const { error, paymentID, filename, newToken } = action.payload.data
      const index = state
        .get('list')
        .findIndex((item) => item.get('PaymentID') === Number(paymentID))

      return state
        .set('error', error)
        .setIn(['list', index, 'TaxBill'], filename)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [UPLOAD_TAXBILL_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    },
    [SET_PAYMENT_ID]: (state, action) => {
      return state.set('paymentID', action.payload)
    },
    [COMPLETE_PAYMENT_LOADING]: (state) => {
      return state.set('loading', true)
    },
    [COMPLETE_PAYMENT_SUCCESS]: (state, action) => {
      const { error, paymentID, newToken } = action.payload.data
      const index = state
        .get('list')
        .findIndex((item) => item.get('PaymentID') === Number(paymentID))

      return state
        .set('error', error)
        .setIn(['list', index, 'Type'], 2)
        .set('newToken', newToken)
        .set('loading', false)
    },
    [COMPLETE_PAYMENT_FAILURE]: (state, action) => {
      const { error } = action.payload.response.data
      return state.set('error', error).set('loading', false)
    }
  },
  initialState
)
