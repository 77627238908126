import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React, { Component, Fragment } from 'react'

import EmailForm from 'components/account/me/EmailForm'
import MessageModal from 'components/common/MessageModal'

import * as meActions from 'store/modules/me'
import * as modalActions from 'store/modules/modal'
import { adtier0 } from 'enums/adtier0'
import { defaultClient as axios } from 'libs/client'
import { config } from 'libs/config'
import { ekey } from 'libs/enums'
import { jsonValueByKey } from 'libs/common'
import { stkeys, storage } from 'libs/storage'

class EmailFormContainer extends Component {
  handleSubmit = async (values) => {
    const { Email: email } = values
    const { MeActions, ModalActions } = this.props

    const params = {
      email
    }

    try {
      await MeActions.updateEmail(params)
      const { error } = this.props
      if (error === ekey(adtier0.ERROR, adtier0.ERROR.SUCCESS)) {
        MeActions.setEmail(email)

        const { user, newToken } = this.props
        storage.set(stkeys.user, user.toJS())

        if (newToken) {
          MeActions.setAccessToken(newToken)
          storage.set(stkeys.accessToken, newToken)
          axios.defaults.headers.common[config.headers.accessToken] = newToken
        }

        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: '수정되었습니다.'
        })
      } else {
        ModalActions.openMessageModal({
          title: 'ZENAAD',
          description: jsonValueByKey(error)
        })
      }
    } catch (err) {
      const { error } = this.props
      ModalActions.openMessageModal({
        title: 'ZENAAD',
        description: jsonValueByKey(error)
      })
    }
  }

  handleOK = () => {
    const { error, ModalActions } = this.props

    ModalActions.closeMessageModal()

    if (error === ekey(adtier0.ERROR, adtier0.ERROR.NEED_SIGNIN)) {
      const { history } = this.props
      storage.clear()
      history.push('/signin')
    }
  }

  render() {
    const { messageModal, pristine, submitting } = this.props
    const { handleSubmit, handleOK } = this

    return (
      <Fragment>
        <EmailForm
          pristine={pristine}
          submitting={submitting}
          onSubmit={handleSubmit}
        />
        <MessageModal
          title={messageModal.title}
          description={messageModal.description}
          open={messageModal.opened}
          onClick={handleOK}
        />
      </Fragment>
    )
  }
}

export default connect(
  (state) => ({
    error: state.me.get('error'),
    user: state.me.get('user'),
    newToken: state.me.get('newToken'),
    messageModal: state.modal.get('message').toJS()
  }),
  (dispatch) => ({
    MeActions: bindActionCreators(meActions, dispatch),
    ModalActions: bindActionCreators(modalActions, dispatch)
  })
)(withRouter(EmailFormContainer))
